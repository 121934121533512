import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import CampaignForm from "../../components/stepper";
import CreateCampaignForm from "../../../../components/stepper";
import { useParams } from "react-router-dom";
// import { fetchCampaignDetailsThunk } from "../../redux/teamSlice/team.thunk";
import { fetchCampaignDetailsThunk } from "../../../../redux/teamSlice/team.thunk";

const EditCampaign = () => {
  const { t } = useTranslation();
  const param = useParams();
  const [isLoaded, setIsLoaded] = useState(false);

  const styles = {
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Take full viewport height
      backgroundColor: '#f0f0f0', // Optional background color
    },
  };

 const [initialValues, setInitialValues] = useState(() => ({
    media: '',
    title: '',
    channel: '',
    description: '',
    startDateTime: null,
    endDateTime: null,
    budget1Day: '',
    impressions1Day: '',
    budget7Days: '',
    impressions7Days: '',
    budget30Days: '',
    impressions30Days: '',
    totalBudget: '',
    templateDetails: '',
    campaignType: '',
    templateViewData:'',
    Parentattributes:[],
    ParentattributesInnerValues:[],
    dynamicAttributes:{}

  }));

  useEffect(() => {
    const fetchData = async () => {
      if (param?.id) {
        const result = await fetchCampaignDetailsThunk(param?.id);
        if (!result) {
          throw new Error('Failed to fetch campaign details');
        }
        let dynamicAttributes = {};
          let parentAttributes = [];

        result?.attributeStatusList?.forEach((attribute) => {
        
          dynamicAttributes[attribute.attributeCode] = attribute.possibleValues || [];
          
          parentAttributes.push({
            id: attribute.attributeId,
            bridgeId: attribute.orgId,
            attributeName: attribute.name,
            attributeCode: attribute.attributeCode,
            attributeType:attribute.attributeMetaType,
            type: "SECONDARY", 
            mandatory: false   
          });
        }
      )

        const convertedData = {
          id: result?.id ?? '',
          campaignName: result?.campaignName ?? '',
          campaignId: result?.campaignId ?? '',
          campaignType: result?.campaignType ?? 'SALES',
          channel: result.channel || '',
          active: result?.active ?? false,
          deleted: result?.deleted ?? false,
          createdOn: result?.createdOn ? new Date(result.createdOn) : null,
          createdBy: result?.createdBy ?? '',
          updatedOn: result?.updatedOn ? new Date(result.updatedOn) : null,
          updatedBy: result?.updatedBy ?? '',
          description: result?.description ?? '',
          goalDescription: result?.goalDescription ?? '',
          startDate: result?.startDate ? new Date(result.startDate) : null,
          endDate: result?.endDate ? new Date(result.endDate) : null,
          startDateEpoch: result?.startDateEpoch ?? null,
          endDateEpoch: result?.endDateEpoch ?? null,
          campaignActiveDays: result?.campaignActiveDays ?? '',
          scheduled: result?.scheduled ?? false,
          scenario: result?.scenario ?? '',
          contentIds: Array.isArray(result?.contentIds) ? result.contentIds : [],
          orgId: result?.orgId ?? '',
          previewImageContentId: result?.previewImageContentId ?? '',
          channel: result?.channel ?? '',
          budget: result?.budget ?? '',
          approxAudience: result?.approxAudience ?? '',
          attributes: result?.attributes ?? null,
          templateId: result?.templateId ?? '',
          userNotification: result?.userNotification ?? null,
          status: result?.status ?? '',
          templateDetails:result?.templateObj?.templateName || result?.templateObj?.name || '',
          templateObj:result?.templateObj,
          Parentattributes:parentAttributes,
          ParentattributesInnerValues:result?.attributeStatusList,
          
          user: {
              userName: result?.user?.userName ?? '',
              userRole: result?.user?.userRole ?? '',
              attributes: result?.user?.attributes ?? null,
              profilePhoto: result?.user?.profilePhoto ?? '',
              userEmail: result?.user?.userEmail ?? ''
          },
      
          budgetSchedule: {
              id: result?.budgetSchedule?.id ?? '',
              totalBudget: result?.budgetSchedule?.totalBudget ?? '',
              campaignId: result?.budgetSchedule?.campaignId ?? '',
              campaignScheduleList: Array.isArray(result?.budgetSchedule?.campaignScheduleList) 
                  ? result.budgetSchedule.campaignScheduleList 
                  : []
          },
          ...dynamicAttributes
      };
      

        setInitialValues(convertedData);
        setIsLoaded(true);
      }
    };

    fetchData(); // Call the async function
  }, [param?.id]);



  const breadcrumb = [
    {
      title: t("home"),
      href: "/dashboard",
    },
    {
      title: t("campaignList.listOfCampaigns"),
      href: "/campaign-list",
    },
    {
      title: t("stepper.editCampaign"),
      href: "",
    },
  ];

  return isLoaded ? <CreateCampaignForm breadcrumb={breadcrumb} initialValues={initialValues}  isEdit={true} prevCampaignId={param?.id} />:   <div style={styles.loaderContainer}><h1>Loading Edit Template...</h1></div>;
};

export default EditCampaign;