import axiosInstance from '../../components/utils/axiosInstance';
import { toast } from 'react-toastify';
import axios from 'axios';

export const fetchPrimarySelectThunk = async () => {
    try {
        // No need for .json() here; axios automatically parses JSON
        const response = await axiosInstance.get('api/dxe/discovery/attributeMapping/PRIMARY/list');
        return response.data; // Return the parsed data directly
    } catch (error) {
        return toast.error(error.response?.data?.message || 'Error fetching primary select data');
    }
};

// export const fetchPrimaryhOptionsThunk = async (attributeCode) => {
//     try {
//         // Again, no .json() needed
//         const response = await axiosInstance.get(`api/dxe/discovery/attribute/${attributeCode}/possibleValues-`);
//         return { attributeCode, data: response.data }; // Return the parsed data
//     } catch (error) {
//         console.error(`Error fetching options for ${attributeCode}:`, error);
//         return toast.error(error.response?.data?.message || `Error fetching options for ${attributeCode}`);
//     }
// };

// export const fetchSecondarySelectThunk = async () => {
//     try {
//         const response = await axiosInstance.get('api/dxe/discovery/attributeMapping/list');
//         // const data = await response.json();
//         return response.data
//     } catch (error) {
//         console.error('Error fetching attributes:', error);
//         return toast.error(error.response.data.message);

//     }

// }

export const fetchSecondarySelectThunk = async () => {
    try {
        const response = await axiosInstance.get('api/attributes/provider-attributes/linked/list');
        // const data = await response.json();
        return response.data
    } catch (error) {
        console.error('Error fetching attributes:', error);
        return toast.error(error.response.data.message);

    }

}


// export const fetchSecondaryOptionsThunk = async (attributeCode) => {
//     try {
//         const response = await axiosInstance.get(`api/dxe/discovery/attribute/${attributeCode}/possibleValues`);
//         // const data = await response.json();
//         // return { attributeCode, data };
//         return { attributeCode, data: response.data }
//     } catch (error) {
//         console.error(`Error fetching options for ${attributeCode}:`, error);
//         return toast.error(error.response.data.message);

//     }

// }


export const fetchSecondaryOptionsThunk = async (attributeCode) => {
    try {
        const response = await axiosInstance.get(`/api/attributes/attributes/possible-value?attributeName=${attributeCode}`);
    
        return { attributeCode, data: response.data }
    } catch (error) {
        console.error(`Error fetching options for ${attributeCode}:`, error);
        return toast.error(error.response.data.message);

    }

}



export const fetchDiscoverIdThunk = async (data) => {
    try {
        const response = await axiosInstance.post(`api/dxe/discovery/discover`, data, {
            headers: {
                'IC': 'IC'
            }
        });

        return response.data
    } catch (error) {

        return toast.error(error.response.data.message);
    }

}

export const fetchContentListThunk = async (page, rowsPerPage) => {

   

    try {
        const token = localStorage.getItem("idToken");

        if (!token) {
            toast.error("Unauthorized access. Please log in.");
        }
        const response = await axiosInstance.get(
            // `api/cm/content/list`,
            `api/cm/content/image/list`,
            {
                params: {
                    pageNumber: page?.page,
                    pageSize: page?.rowsPerPage,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        console.log("error", error.response.data.message);
    }
}


export const fetchCreateCampaignThunk = async (data) => {

    try {
        const token = localStorage.getItem("idToken");

        if (!token) {
            toast.error("Unauthorized access.");
        }

        const response = await axiosInstance.post(`api/cm/campaign/create`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data

    } catch (error) {

        return toast.error(error.response.data.message);
    }
}

export const fetchCreatePostRequestThunk = async (data) => {

    try {
        const token = localStorage.getItem("idToken");

        if (!token) {
            toast.error("Unauthorized access.");
        }

        const response = await axiosInstance.post(`api/dr/dataRequest/create`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data

    } catch (error) {

        return toast.error(error.response.data.message);
    }
}


export const calculateAudienceThunk = async (data) => {

    try {

        const token = localStorage.getItem("idToken");

        if (!token) {
            toast.error("Unauthorized access.");
        }

        const response = await axiosInstance.post(`api/bm/calculateAudience`, data, {});

        return response.data;

    } catch (error) {
        return toast.error(error.response.data.message);
    }
}

export const BudgetManagementThunk = async ({ data, budgetId }) => {

    try {

        const token = localStorage.getItem("idToken");

        if (!token) {
            toast.error("Unauthorized access.");
        }

        const response = await axiosInstance.put(`api/bm/budget-schedules/update?budgetId=${budgetId}`, data, {});

        return response.data;

    } catch (error) {
        return toast.error(error.response.data.message);
    }
}

export const fetchTemplateListDataThunk = async (data) => {

    try {
        const token = localStorage.getItem("idToken");

        if (!token) {
            toast.error("Unauthorized access. Please log in.");
        }
        const response = await axiosInstance.get(
            `api/com/approved`,
            {
                params: {
                    page: data.page || 0,
                    size: data.rowsPerPage || 10,
                    type: data.data,
                },
            }
        );
       
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        console.log("error", error.response.data.message);
    }
}

export const fetchWhatsAppTemplateThunk = async (data) => {

    try {
        const response = await axiosInstance.get(
            `api/template/template`,
            {
                params: {
                    id: data,
                },
            }
        );
       
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        console.log("error", error.response.data.message);
    }
}

export const fetchSMSTemplateThunk = async (data) => {

    try {
        const response = await axiosInstance.get(
            `api/com/${data}`
        );
       
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        console.log("error", error.response.data.message);
    }
}

export const SearchContentListThunk = async ({ page, value }) => {
    try {
        const token = localStorage.getItem("idToken");
        if (!token) {
            toast.error("Unauthorized access. Please log in.");
            return;
        }


        // Define search parameters using the `params` object
        const params = {
            name: value || "",
            page: page?.page || 0,
            size: page?.rowsPerPage || 10,
        };

        // Make the GET request using `params`
        const response = await axiosInstance.get("/api/cm/content/search", {
            params,  // Pass the params directly here
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        toast.error(error?.response?.data?.message || "An error occurred.");
        console.log("Error:", error?.response?.data?.message || error.message);
    }
};


export const FetchBudgetScheduleThunk = async (data) => {

    try {
        const token = localStorage.getItem("idToken");
        if (!token) {
            toast.error("Unauthorized access. Please log in.");
            return;
        }

        const response = await axiosInstance.get(
            `api/bm/budget-schedules/campaign`,
            {
                params: {
                    campaignId: data,
                },
            }
        );
        return response.data;

    } catch (error) {
        toast.error(error?.response?.data?.message || "An error occurred.");
        console.log("Error:", error?.response?.data?.message || error.message);
    }
}

export const CampaignListEditThunk = async ({ data, campaignId }) => {

 
    try {

        const token = localStorage.getItem("idToken");

        if (!token) {
            toast.error("Unauthorized access.");
        }

        const response = await axiosInstance.put(`api/cm/campaign/update?campaignId=${campaignId}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;

    } catch (error) {

        // if (error?.status === 400) {
        //     return toast.error("You Enter something wrong input");
        // }
     
        toast.error(error.response.data.message || "something is wrong ");
        throw error.status
        
    }
}

export const fetchCostPerMilleThunk = async (data) => {

    try {
        const token = localStorage.getItem("idToken");
        if (!token) {
            toast.error("Unauthorized access. Please log in.");
            return;
        }

        const response = await axiosInstance.get(
            `api/bm/cpm`,
            {
                params: {
                    method: data,
                },
            }
        );
        return response.data;

    } catch (error) {
        toast.error(error?.response?.data?.message || "An error occurred.");
        console.log("Error:", error?.response?.data?.message || error.message);
    }
}


