import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import CampaignForm from "../../components/stepper";
import CreateCampaignForm from "../../../../components/stepper";


const CreateCampaign = () => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState(() => ({
    media: '',
    title: '',
    channel: '',
    description: '',
    startDateTime: null,
    endDateTime: null,
    budget1Day: '',
    impressions1Day: '',
    budget7Days: '',
    impressions7Days: '',
    budget30Days: '',
    impressions30Days: '',
    totalBudget: '',
    templateDetails: '',
    campaignType: 'SALES',
    templateViewData:'',
    templateId:'',
    Parentattributes:[],
    ParentattributesInnerValues:[],
  
      // id: '',
      // campaignName: '',
      // campaignId: '',
      // campaignType: 'SALES', // Default value for campaignType
      // channel: '',
      // active: false,
      // deleted: false,
      // createdOn: null,
      // createdBy: '',
      // updatedOn: null,
      // updatedBy: '',
      // description: '',  // added missing key
      // goalDescription: '',
      // startDate: null,
      // endDate: null,
      // startDateEpoch: null,
      // endDateEpoch: null,
      // campaignActiveDays: '',
      // scheduled: false,
      // scenario: '',
      // contentIds: [],
      // orgId: '',
      // previewImageContentId: '',
      // budget: '',
      // approxAudience: '',
      // attributes: null,
      // templateId: '',
      // userNotification: null,
      // status: '',
      // templateDetails: '',  // added missing key
      // templateObj: null,
      // templateViewData:null,
      // Parentattributes: [],  // added missing key from initialData
      // ParentattributesInnerValues: [],  // added missing key from initialData
      // user: {
      //     userName: '',
      //     userRole: '',
      //     attributes: null,
      //     profilePhoto: '',
      //     userEmail: ''
      // },
      // budgetSchedule: {
      //     id: '',
      //     totalBudget: '',
      //     campaignId: '',
      //     campaignScheduleList: []
      // },
  

  
   
  }));

  const breadcrumb = [
    {
      title: t("home"),
      href: "/dashboard",
    },
    {
      title: t("campaignList.listOfCampaigns"),
      href: "/campaign-list",
    },
    {
      title: t("stepper.createCampaign"),
      href: "",
    },
  ];

  return <CreateCampaignForm breadcrumb={breadcrumb} initialValues={initialValues} isEdit={false} prevCampaignId={null} />;
};

export default CreateCampaign;