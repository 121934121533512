import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SearchTeamsDetailsThunk, createTemplateSMSThunk, createTemplateThunk, fetchDataPermissionThunk, fetchDataPermissonUnionThunk, fetchDataRequestDataThunk, fetchIpiFicationGetThunk, fetchIpiFicationPostThunk, fetchIpiFicationRespPostThunk, fetchNotificationPreviewThunk, fetchPastRequestThunk, fetchTeamMemberListThunk, fetchTeamsDetailsThunk, fetchTemplateListThunk, NotificationApproveThunk, TemplateListDeleteThunk, templateListSearchThunk, fetchCreateContentThunk, SearchContentListThunk, SearchCampaignListThunk, fetchContentListMediaThunk, fetchProviderEarningThunk, fetchEarningGraphThunk, fetchCampaignLevelPerformenceThunk, updateAttributeStatusThunk, campaignSnapShotThunk } from "./team.thunk";

export const fetchTeamsDetailsSlice = createAsyncThunk(
  "fetchTeamsDetails",
  fetchTeamsDetailsThunk
);
export const fetchTeamMemberListSlice = createAsyncThunk(
  "fetchTeamMemberListThunk",
  fetchTeamMemberListThunk
);

export const fetchDataRequestDataSlice = createAsyncThunk(
  "fetchDataRequestDataThunk",
  fetchDataRequestDataThunk
);

export const fetchNotificationPreviewSlice = createAsyncThunk(
  "fetchNotificationPreviewThunk",
  fetchNotificationPreviewThunk
)

export const fetchNotificationApproveSlice = createAsyncThunk(
  "NotificationApproveThunk",
  NotificationApproveThunk
)

export const fetchDataPermissionSlice = createAsyncThunk(
  "fetchDataPermissionThunk",
  fetchDataPermissionThunk
)

export const fetchDataPermissonUnionSlice = createAsyncThunk(
  "fetchDataPermissonUnionThunk",
  fetchDataPermissonUnionThunk
)

export const fetchPastRequestSlice = createAsyncThunk(
  "fetchPastRequestThunk",
  fetchPastRequestThunk
)

export const fetchIpiFicationPostSlice = createAsyncThunk(
  "fetchIpiFicationPostThunk",
  fetchIpiFicationPostThunk
)

export const fetchIpiFicationGetSlice = createAsyncThunk(
  "fetchIpiFicationGetThunk",
  fetchIpiFicationGetThunk
)

export const fetchIpiFicationRespPostSlice = createAsyncThunk(
  "fetchIpiFicationRespPostThunk",
  fetchIpiFicationRespPostThunk
)

export const createTemplateSlice = createAsyncThunk(
  "createTemplateThunk",
  createTemplateThunk
)

export const createTemplateSMSSlice = createAsyncThunk(
  "createTemplateSMSThunk",
  createTemplateSMSThunk
);

export const fetchTemplateListSlice = createAsyncThunk('fetchTemplateListThunk', fetchTemplateListThunk);
export const searchTemplateListSlice = createAsyncThunk('SearchTeamsDetailsThunk', SearchTeamsDetailsThunk);


export const templateListSearchSlice = createAsyncThunk('templateListSearchThunk', templateListSearchThunk);

export const templateDeleteSlice = createAsyncThunk('TemplateListDeleteThunk', TemplateListDeleteThunk);

export const fetchCreateContentSlice = createAsyncThunk('fetchCreateContentThunk', fetchCreateContentThunk);

export const SearchCampaignListSlice = createAsyncThunk('SearchCampaignListThunk', SearchCampaignListThunk);

export const fetchContentListMediaSlice = createAsyncThunk('fetchContentListMediaThunk', fetchContentListMediaThunk);

export const fetchProviderEarningSlice = createAsyncThunk('fetchProviderEarningThunk', fetchProviderEarningThunk);

export const fetchEarningGraphSlice = createAsyncThunk('fetchEarningGraphThunk', fetchEarningGraphThunk);

export const fetchCampaignLevelPerformenceSlice = createAsyncThunk('fetchCampaignLevelPerformenceThunk', fetchCampaignLevelPerformenceThunk);

export const updateAttributeStatusSlice = createAsyncThunk('updateAttributeStatusThunk', updateAttributeStatusThunk);

export const campaignSnapShotSlice = createAsyncThunk('campaignSnapShotThunk', campaignSnapShotThunk);


const initialState = {
  teams: [],
  campaigns: [],
  dataRequest: [],
  notificationApprove: '',
  totalItems: 0,
  notificationPreview: {},
  dataPermissionData: '',
  templateList: [],
  dataPermissionUnionData: '',
  pastRequestData: [],
  earningInfo: {},
  earningGraph: [],
  createContent: {},
  campaignLevelPerformence: [],
  attributeStatus:{},
  campaignSnapShot:{},
  contentMedia: '',
  createWhatsAppTemplate: '',
  createSMSTemplate: '',
  demoPost: '',
  demoGet: '',
  demorespData: '',
  tempDelete: '',
  data: null,
  status: "idle",
  loading: false,
  error: null,
};

const teamsSlice = createSlice({
  name: "todo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamsDetailsSlice.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTeamsDetailsSlice.fulfilled, (state, action) => {
        state.campaigns = action.payload?.content;
        state.totalItems = action?.payload?.totalElements;
        state.loading = false;
      })
      .addCase(fetchTeamsDetailsSlice.rejected, (state, action) => {
        state.error = action?.error?.message;
        state.loading = false;
      })

      // Campaign Search

      .addCase(SearchCampaignListSlice.pending, (state) => {
        state.loading = true;
      })
      .addCase(SearchCampaignListSlice.fulfilled, (state, action) => {
        state.campaigns = action.payload?.content;
        state.totalItems = action.payload?.totalElements;
        state.loading = false;
      })
      .addCase(SearchCampaignListSlice.rejected, (state, action) => {
        state.error = action?.error?.message;
        state.loading = false;
      })

      // fetching team member list
      .addCase(fetchTeamMemberListSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTeamMemberListSlice.fulfilled, (state, action) => {
        console.log(action.payload, "slice");
        state.teams = action.payload;
        state.loading = false;
      })
      .addCase(fetchTeamMemberListSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Data Request

      .addCase(fetchDataRequestDataSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataRequestDataSlice.fulfilled, (state, action) => {
        state.dataRequest = action.payload;
        state.loading = false;
      })
      .addCase(fetchDataRequestDataSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Notification Preview Data

      .addCase(fetchNotificationPreviewSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotificationPreviewSlice.fulfilled, (state, action) => {
        state.notificationPreview = action.payload;
        state.loading = false;
      })
      .addCase(fetchNotificationPreviewSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


      .addCase(fetchNotificationApproveSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotificationApproveSlice.fulfilled, (state, action) => {
        state.notificationApprove = action.payload;
        state.loading = false;
      })
      .addCase(fetchNotificationApproveSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Data Permission

      .addCase(fetchDataPermissionSlice.pending, (state) => {
        state.submitBtnLoader = true;
        state.error = null;
      })
      .addCase(fetchDataPermissionSlice.fulfilled, (state, action) => {
        state.dataPermissionData = action.payload;
        state.submitBtnLoader = false;
      })
      .addCase(fetchDataPermissionSlice.rejected, (state, action) => {
        state.submitBtnLoader = false;
        state.error = action.error.message;
      })

      // Create WhatsApp Template

      .addCase(createTemplateSlice.pending, (state) => {
        state.submitBtnLoader = true;
        state.error = null;
      })
      .addCase(createTemplateSlice.fulfilled, (state, action) => {
        state.createWhatsAppTemplate = action.payload;
        state.submitBtnLoader = false;
      })
      .addCase(createTemplateSlice.rejected, (state, action) => {
        state.submitBtnLoader = false;
        state.error = action.error.message;
      })

      // Create SMS Template

      .addCase(createTemplateSMSSlice.pending, (state) => {
        state.submitBtnLoader = true;
        state.error = null;
      })
      .addCase(createTemplateSMSSlice.fulfilled, (state, action) => {
        state.createSMSTemplate = action.payload;
        state.submitBtnLoader = false;
      })
      .addCase(createTemplateSMSSlice.rejected, (state, action) => {
        state.submitBtnLoader = false;
        state.error = action.error.message;
      })

      // Data Permission Union

      .addCase(fetchDataPermissonUnionSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataPermissonUnionSlice.fulfilled, (state, action) => {
        state.dataPermissionUnionData = action.payload;
        state.loading = false;
      })
      .addCase(fetchDataPermissonUnionSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Past Request

      .addCase(fetchPastRequestSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPastRequestSlice.fulfilled, (state, action) => {
        state.pastRequestData = action.payload;
        state.totalItems = action.payload?.totalElements;
        state.loading = false;
      })
      .addCase(fetchPastRequestSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


      // Demo

      .addCase(fetchIpiFicationPostSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIpiFicationPostSlice.fulfilled, (state, action) => {
        state.demoPost = action.payload;
        state.loading = false;
      })
      .addCase(fetchIpiFicationPostSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchIpiFicationGetSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIpiFicationGetSlice.fulfilled, (state, action) => {
        state.demoGet = action.payload;
        state.loading = false;
      })
      .addCase(fetchIpiFicationGetSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchIpiFicationRespPostSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIpiFicationRespPostSlice.fulfilled, (state, action) => {
        state.demorespData = action.payload;
        state.loading = false;
      })
      .addCase(fetchIpiFicationRespPostSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Template List

      .addCase(fetchTemplateListSlice.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTemplateListSlice.fulfilled, (state, action) => {
        console.log("action Template----", action);
        state.templateList = action.payload;
        state.totalItems = action?.payload?.totalElements;
        state.loading = false;
      })
      .addCase(fetchTemplateListSlice.rejected, (state, action) => {
        state.error = action?.error?.message;
        state.loading = false;
      })

      .addCase(searchTemplateListSlice.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchTemplateListSlice.fulfilled, (state, action) => {
        console.log("action Template----", action);
        state.templateList = action.payload;
        state.totalItems = action?.payload?.totalElements;
        state.loading = false;
      })
      .addCase(searchTemplateListSlice.rejected, (state, action) => {
        state.error = action?.error?.message;
        state.loading = false;
      })

      // Template List Search

      // .addCase(templateListSearchSlice.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(templateListSearchSlice.fulfilled, (state, action) => {
      //   console.log("action Template----", action);
      //   state.templateList = action.payload.content;
      //   state.totalItems = action?.payload?.totalElements;
      //   state.loading = false;
      // })
      // .addCase(templateListSearchSlice.rejected, (state, action) => {
      //   state.error = action?.error?.message;
      //   state.loading = false;
      // })

      // Template Delete

      .addCase(templateDeleteSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(templateDeleteSlice.fulfilled, (state, action) => {
        state.tempDelete = action.payload;
        state.loading = false;
      })
      .addCase(templateDeleteSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Create Content

      .addCase(fetchCreateContentSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCreateContentSlice.fulfilled, (state, action) => {
        state.createContent = action.payload;
        state.loading = false;
      })
      .addCase(fetchCreateContentSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Content Media For Create Template

      .addCase(fetchContentListMediaSlice.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchContentListMediaSlice.fulfilled, (state, action) => {
        console.log("action content media----", action);
        state.contentMedia = action.payload;
        state.totalItems = action?.payload?.totalElements;
        state.loading = false;
      })
      .addCase(fetchContentListMediaSlice.rejected, (state, action) => {
        state.error = action?.error?.message;
        state.loading = false;
      })

      // Attribute Status

      .addCase(updateAttributeStatusSlice.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAttributeStatusSlice.fulfilled, (state, action) => {
        state.attributeStatus = action.payload;
        state.loading = false;
      })
      .addCase(updateAttributeStatusSlice.rejected, (state, action) => {
        state.error = action?.error?.message;
        state.loading = false;
      })

      // Provider Earning

      .addCase(fetchProviderEarningSlice.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProviderEarningSlice.fulfilled, (state, action) => {
        state.earningInfo = action.payload;
        state.loading = false;
      })
      .addCase(fetchProviderEarningSlice.rejected, (state, action) => {
        state.error = action?.error?.message;
        state.loading = false;
      })

      // Earning Graph

      .addCase(fetchEarningGraphSlice.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEarningGraphSlice.fulfilled, (state, action) => {
        state.earningGraph = action.payload;
        state.loading = false;
      })
      .addCase(fetchEarningGraphSlice.rejected, (state, action) => {
        state.error = action?.error?.message;
        state.loading = false;
      })

      // Dashboard

      .addCase(fetchCampaignLevelPerformenceSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCampaignLevelPerformenceSlice.fulfilled, (state, action) => {
        state.campaignLevelPerformence = action.payload;
        state.totalItems = action?.payload?.totalElements;
        state.loading = false;
      })
      .addCase(fetchCampaignLevelPerformenceSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Consumer Dashboard Snapshots

      .addCase(campaignSnapShotSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(campaignSnapShotSlice.fulfilled, (state, action) => {
        state.campaignSnapShot = action.payload;
        state.loading = false;
      })
      .addCase(campaignSnapShotSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export default teamsSlice.reducer;
