import { Box, Button, Grid2, Typography, IconButton, CircularProgress, Autocomplete, TextField, InputAdornment, Chip, FormControl, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
import { fetchCampaignPerformenceSlice } from "../../../redux/dataRequestSlice/dataRequest.slice";
// import CustomPagination from "../../components/CustomPagination/CustomPagination";
// import CustomSwitch from "../../components/CustomSwitch/CustomSwitch";

const useStyles = makeStyles((theme) => ({
    searchBar: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
        },
        "& .MuiInputBase-input": {
            color: "#2d2d2d",
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },

    selectField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#FFFFFF", // Background color
            borderRadius: "10px",
        },
        "& .MuiInputBase-input": {
            color: "#2d2d2d",
        },
        "& .MuiInputBase-input::placeholder": {
            color: "#2d2d2d",
        },
    },

    textField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#FFFFFF", // Background color
            borderRadius: "10px",
        },
        "& .MuiInputBase-input": {
            color: "#2d2d2d",
        },
        "& .MuiInputBase-input::placeholder": {
            color: "#2d2d2d",
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "4px",
}));

const TableHeadTitle = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    color: "#455967",
    fontWeight: "500",
    lineHeight: "1.5rem",
}));

const TableDataTitle = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    color: "#455967",
    fontWeight: "500",
}));

const data = [
    {
        campaignName: "C1",
        advertiserName: "A1",
        startDate: "D1",
        endDate: "D1A",
        attributesUsed: "Age, location +2",
        revenue: "$1000",
        status: "Executed",
        actions: "View Details"
    },
    {
        campaignName: "C2",
        advertiserName: "A2",
        startDate: "D2",
        endDate: "D2A",
        attributesUsed: "Affluence level, data usage +4",
        revenue: "$2000",
        status: "Executed",
        actions: "View Details"
    },
    {
        campaignName: "C3",
        advertiserName: "A3",
        startDate: "D3",
        endDate: "D3A",
        attributesUsed: "Age, Affluence level +5",
        revenue: "Available once the campaign is executed",
        status: "Active",
        actions: "View Details"
    }
];

const EarningTable = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [campaign, setCampaign] = useState("");
    const [timePeriod, setTimePeriod] = useState("");
    const { campaignPerformence, loading, totalItems } = useSelector((state) => state.dataRequest);

    const fetchNotificationData = () => {
        dispatch(fetchCampaignPerformenceSlice({ page, rowsPerPage }));
    };

    useEffect(() => {
        fetchNotificationData();
    }, [dispatch, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    return (
        <Box mt={5}>
            <Typography sx={{ fontSize: '26px', color: '#445A68', fontWeight: '500' }} mb={1}>
                Campaign Performence
            </Typography>

            {/* <Box display={'flex'} justifyContent={'flex-end'} gap={2} mb={3}>
                <FormControl size='small'>
                    <Select value={campaign} onChange={(event) => { setCampaign(event.target.value) }} displayEmpty>
                        <MenuItem value="">Select campaign name</MenuItem>
                        <MenuItem value="Campaign 1">Campaign 1</MenuItem>
                        <MenuItem value="Campaign 2">Campaign 2</MenuItem>
                    </Select>
                </FormControl>

                <FormControl size='small'>
                    <Select value={timePeriod} onChange={(event) => { setTimePeriod(event.target.value) }} displayEmpty>
                        <MenuItem value="">Select time period</MenuItem>
                        <MenuItem value="Day">Day</MenuItem>
                        <MenuItem value="Week">Week</MenuItem>
                        <MenuItem value="Month">Month</MenuItem>
                    </Select>
                </FormControl>
            </Box> */}

            <TableContainer
                component={Paper}
                sx={{ mt: 2, border: "1px solid #D4D4D4", borderRadius: "12px" }}
            >
                {
                    loading ?
                        (
                            <Box display="flex" justifyContent="center" my={3}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Table aria-label="campaign table">
                                <TableHead sx={{ background: "#F1F1F1" }}>
                                    <TableRow>
                                        <TableCell>
                                            <BoxContainer>
                                                <TableHeadTitle>Campaign Name</TableHeadTitle>
                                            </BoxContainer>
                                        </TableCell>
                                        <TableCell>
                                            <BoxContainer>
                                                <TableHeadTitle>Advertiser Name</TableHeadTitle>
                                            </BoxContainer>
                                        </TableCell>
                                        <TableCell>
                                            <BoxContainer>
                                                <TableHeadTitle>Start Date</TableHeadTitle>
                                            </BoxContainer>
                                        </TableCell>
                                        <TableCell>
                                            <BoxContainer>
                                                <TableHeadTitle>End Date</TableHeadTitle>
                                            </BoxContainer>
                                        </TableCell>
                                        <TableCell>
                                            <BoxContainer>
                                                <TableHeadTitle>Attributes Used</TableHeadTitle>
                                            </BoxContainer>
                                        </TableCell>
                                        <TableCell>
                                            <BoxContainer>
                                                <TableHeadTitle>Revenue</TableHeadTitle>
                                            </BoxContainer>
                                        </TableCell>
                                        <TableCell>
                                            <BoxContainer>
                                                <TableHeadTitle>Status</TableHeadTitle>
                                            </BoxContainer>
                                        </TableCell>
                                        <TableCell>
                                            <BoxContainer>
                                                <TableHeadTitle>Actions</TableHeadTitle>
                                            </BoxContainer>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        campaignPerformence?.content?.length > 0 ? (
                                            campaignPerformence?.content?.map((item, index) => {

                                                return (
                                                    <StyledTableRow key={index}>
                                                        <TableCell>
                                                            <TableDataTitle>{item?.name}</TableDataTitle>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableDataTitle>{item?.advertiserName}</TableDataTitle>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableDataTitle>{item?.startDate}</TableDataTitle>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableDataTitle>{item?.endDate}</TableDataTitle>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableDataTitle>{item?.attributesUsed[0]}</TableDataTitle>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableDataTitle>{item?.revenue}</TableDataTitle>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableDataTitle>{item?.status}</TableDataTitle>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableDataTitle>
                                                                <Chip label="View Details" onClick={() => { }} />
                                                            </TableDataTitle>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                )
                                            })
                                        ) : (
                                            <>
                                                <TableRow>
                                                    <TableCell colSpan={8} align="center">
                                                        <Typography variant="subtitle1" color="textSecondary">
                                                            No Data Available
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        )
                }
            </TableContainer>
        </Box>
    )
}

export default EarningTable