import { Box, Button, Grid2, Typography, IconButton, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchBridgeSlice } from "../../../redux/superAdmin/superAdmin.slice";
import AddBridgeModal from "./AddBridgeModal";

const useStyles = makeStyles((theme) => ({
    searchBar: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
        },
        "& .MuiInputBase-input": {
            color: "#2d2d2d",
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },

    selectField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#FFFFFF", // Background color
            borderRadius: "10px",
        },
        "& .MuiInputBase-input": {
            color: "#2d2d2d",
        },
        "& .MuiInputBase-input::placeholder": {
            color: "#2d2d2d",
        },
    },

    textField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#FFFFFF", // Background color
            borderRadius: "10px",
        },
        "& .MuiInputBase-input": {
            color: "#2d2d2d",
        },
        "& .MuiInputBase-input::placeholder": {
            color: "#2d2d2d",
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "4px",
}));
const statusStyles = {
    active: {
        backgroundColor: "#e0f7e9",
        color: "#34a853",
    },
    offline: {
        backgroundColor: "#f5f5f5",
        color: "#9e9e9e",
    },
};

const TableHeadTitle = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    color: "#455967",
    fontWeight: "500",
    lineHeight: "1.5rem",
}));

const TableDataTitle = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    color: "#455967",
    fontWeight: "500",
}));

const BridgeTable = ({t}) => {

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const { bridgeDetails, loading } = useSelector(
        (state) => state.superAdmin
    );


    useEffect(() => {
        dispatch(fetchBridgeSlice());
    }, [])

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Box>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'end' }}>
                <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    disabled={bridgeDetails.length !== 0}
                    sx={{
                        borderRadius: "40px",
                        color: "#445A68",
                        fontSize: "18px",
                        fontWeight: "600",
                        border: "1px solid #445A68",
                    }}
                    onClick={() => { setOpen(true) }}
                >
                    {t('editConsumer.addBridge')}
                </Button>
            </Box>

            <TableContainer
                component={Paper}
                sx={{ mt: 2, border: "1px solid #D4D4D4", borderRadius: "12px" }}
            >
                <Table aria-label="campaign table">
                    <TableHead sx={{ background: "#F1F1F1" }}>
                        <TableRow>
                            <TableCell>
                                <BoxContainer>
                                    <TableHeadTitle>{t('editConsumer.name')}</TableHeadTitle>
                                </BoxContainer>
                            </TableCell>
                            <TableCell>
                                <BoxContainer>
                                    <TableHeadTitle>Bridge Url</TableHeadTitle>
                                </BoxContainer>
                            </TableCell>
                            <TableCell>
                                <BoxContainer>
                                    <TableHeadTitle>Decryption Url</TableHeadTitle>
                                </BoxContainer>
                            </TableCell>
                            <TableCell>
                                <BoxContainer>
                                    <TableHeadTitle>{t('editConsumer.description')}</TableHeadTitle>
                                </BoxContainer>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bridgeDetails?.length > 0 ? (
                            bridgeDetails.map((item, index) => {
                                return (
                                    <StyledTableRow key={index} role="checkbox">
                                        <TableCell>
                                            <TableDataTitle>{item?.name}</TableDataTitle>
                                        </TableCell>
                                        <TableCell>
                                            <TableDataTitle>{item?.url}</TableDataTitle>
                                        </TableCell>
                                        <TableCell>
                                            <TableDataTitle>{item?.decryptionUrl}</TableDataTitle>
                                        </TableCell>
                                        <TableCell>
                                            <TableDataTitle>{item?.description}</TableDataTitle>
                                        </TableCell>
                                    </StyledTableRow>
                                )
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    <Typography variant="subtitle1" color="textSecondary">
                                    {t('editConsumer.noDataAvailable')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <AddBridgeModal open={open} handleClose={handleClose} t={t} />
        </Box>
    )
}

export default BridgeTable