import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    adminSignUpThunk, createAttributeMapping, createAttributeThunk, fetchAttributesCategoryListThunk, fetchAttributesCategoryThunk, fetchAttributesListThunk, fetchAttributesMappingThunk,
    fetchAttributeTypeThunk, fetchDataConsumerAndProviderThunk, fetchMetaTypeThunk, updateConsumerThunk, fetchProfileThunk,
    fetchRegisterUsersThunk, resendEmailThunk,
    mobileOtpSendThunk,
    verifyOtpThunk,
    mobileOtpReSendThunk,
    SearchAttributeListThunk,
    fetchPriceListThunk,
    fetchAttributesPriceCategoryThunk,
    fetchAttributesByIdThunk,
    updateAttributesByIdThunk,
    fetchCountryRegionList,
    fetchBusinessCategoryList,
    fetchAudienceLanguageList,
    fetchBridgeThunk,
    createBridgeThunk,
    recommendationThunk
} from "./superAdmin.thunk";


export const updateConsumerSlice = createAsyncThunk("updateConsumerThunk", updateConsumerThunk);
export const adminSignUpSlice = createAsyncThunk("adminSignUpThunk", adminSignUpThunk);
export const resendEmailSlice = createAsyncThunk("resendEmailThunk", resendEmailThunk);


export const fetchDataConsumerAndProviderSlice = createAsyncThunk("fetchDataConsumerAndProviderThunk", fetchDataConsumerAndProviderThunk);

export const fetchAttributesListSlice = createAsyncThunk("fetchAttributesListThunk", fetchAttributesListThunk);
export const fetchPriceListSlice = createAsyncThunk("fetchPriceListThunk", fetchPriceListThunk);


export const fetchAttributesCategoryListSlice = createAsyncThunk("fetchAttributesCategoryListThunk", fetchAttributesCategoryListThunk);

export const fetchAttributesCategorySlice = createAsyncThunk("fetchAttributesCategoryThunk", fetchAttributesCategoryThunk);

export const fetchMetaTypeSlice = createAsyncThunk("fetchMetaTypeThunk", fetchMetaTypeThunk);

export const fetchAttributeTypeSlice = createAsyncThunk("fetchAttributeTypeThunk", fetchAttributeTypeThunk);

export const createAttributeSlice = createAsyncThunk("createAttributeThunk", createAttributeThunk);
export const fetchAttributesPriceCategorySlice = createAsyncThunk("fetchAttributesPriceCategoryThunk", fetchAttributesPriceCategoryThunk);
export const fetchAttributesByIdSlice = createAsyncThunk("fetchAttributesByIdThunk", fetchAttributesByIdThunk);
export const updateAttributesByIdSlice = createAsyncThunk("updateAttributesByIdThunk", updateAttributesByIdThunk);



export const fetchAttributesMappingSlice = createAsyncThunk("fetchAttributesMappingThunk", fetchAttributesMappingThunk);

export const createAttributeMappingSlice = createAsyncThunk("createAttributeMapping", createAttributeMapping);

export const fetchRegisterUsersSlice = createAsyncThunk("fetchRegisterUsersThunk", fetchRegisterUsersThunk);

export const fetchProfileSlice = createAsyncThunk("fetchProfileThunk", fetchProfileThunk);

export const mobileOtpSendSlice = createAsyncThunk("mobileOtpSendThunk", mobileOtpSendThunk);

export const verifyOtpSlice = createAsyncThunk("verifyOtpThunk", verifyOtpThunk);

export const mobileOtpReSendSlice = createAsyncThunk("mobileOtpReSendThunk", mobileOtpReSendThunk);

export const SearchAttributeListSlice = createAsyncThunk("SearchAttributeListThunk", SearchAttributeListThunk);

export const fetchCountryRegionSlice = createAsyncThunk("fetchCountryRegionList", fetchCountryRegionList);

export const fetchBusinessCategorySlice = createAsyncThunk("fetchBusinessCategoryList", fetchBusinessCategoryList);

export const fetchAudienceLanguageSlice = createAsyncThunk("fetchAudienceLanguageList", fetchAudienceLanguageList);

export const fetchBridgeSlice = createAsyncThunk("fetchBridgeThunk", fetchBridgeThunk);

export const createBridgeSlice = createAsyncThunk("createBridgeThunk", createBridgeThunk);

export const recommendationSlice = createAsyncThunk("recommendationThunk", recommendationThunk);


const initialState = {
    signUpData: {},
    formData: {},
    profileData: {},
    dataConsumerAndProvider: [],
    attributesList: [],
    priceList:[],
    attributeCategoryOptions: [],
    attributeCategoryList: [],
    metaTypeList: [],
    attributeType: [],
    createAttributeResp: {},
    attributeMappingData: [],
    registerUserData: [],
    createAttributeMappingData: {},
    mobileOtpData:{},
    verifyOtp:{},
    countryList:[],
    businessCategoryList:[],
    audienceLanguageList:[],
    bridgeDetails:[],
    createBridgeResp:{},
    addPricing:{},
    editDcpResp:{},
    totalItems: 0,
    status: "idle",
    dropdownLoader: false,
    submitBtnLoader:false,
    mobileOTPLoader:false,
    loading: false,
    isLoading: false,
    error: null,
};

const superAdminSlice = createSlice({
    name: 'superAdmin',
    initialState,
    reducers: {
        setFormData: (state, action) => {
            state.formData = action.payload;
        },
        clearFormData: (state) => {
            state.formData = null;
        },
        clearSignUpState: (state) => {
            state.isLoading = false;
            state.data = null;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(adminSignUpSlice.pending, (state) => {
                state.submitBtnLoader = true;
                state.error = null;
            })
            .addCase(adminSignUpSlice.fulfilled, (state, action) => {
                state.signUpData = action.payload;
                state.submitBtnLoader = false;
            })
            .addCase(adminSignUpSlice.rejected, (state, action) => {
                state.submitBtnLoader = false;
                state.error = action.error.message;
            })

            .addCase(fetchProfileSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProfileSlice.fulfilled, (state, action) => {
                state.profileData = action.payload;
                state.loading = false;
            })
            .addCase(fetchProfileSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchDataConsumerAndProviderSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDataConsumerAndProviderSlice.fulfilled, (state, action) => {
                state.dataConsumerAndProvider = action.payload;
                state.totalItems = action.payload?.totalElements;
                state.loading = false;
            })
            .addCase(fetchDataConsumerAndProviderSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(SearchAttributeListSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(SearchAttributeListSlice.fulfilled, (state, action) => {
                console.log("action Template DCP----", action);
                state.attributesList = action.payload;
                state.totalItems = action.payload?.totalElements;
                state.loading = false;
            })
            .addCase(SearchAttributeListSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchAttributesListSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAttributesListSlice.fulfilled, (state, action) => {
                state.attributesList = action.payload;
                state.totalItems = action.payload?.totalElements;
                state.loading = false;
            })
            .addCase(fetchAttributesListSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })


            .addCase(fetchPriceListSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPriceListSlice.fulfilled, (state, action) => {
                state.priceList = action.payload;
                state.totalItems = action.payload?.totalElements;
                state.loading = false;
            })
            .addCase(fetchPriceListSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchAttributesCategoryListSlice.pending, (state) => {
                state.dropdownLoader = true;
                state.error = null;
            })
            .addCase(fetchAttributesCategoryListSlice.fulfilled, (state, action) => {
                state.attributeCategoryOptions = action.payload;
                state.dropdownLoader = false;
            })
            .addCase(fetchAttributesCategoryListSlice.rejected, (state, action) => {
                state.dropdownLoader = false;
                state.error = action.error.message;
            })

            .addCase(fetchAttributesCategorySlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAttributesCategorySlice.fulfilled, (state, action) => {
                state.attributeCategoryList = action.payload;
                state.loading = false;
            })
            .addCase(fetchAttributesCategorySlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchMetaTypeSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMetaTypeSlice.fulfilled, (state, action) => {
                state.metaTypeList = action.payload;
                state.loading = false;
            })
            .addCase(fetchMetaTypeSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchAttributeTypeSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAttributeTypeSlice.fulfilled, (state, action) => {
                state.attributeType = action.payload;
                state.loading = false;
            })
            .addCase(fetchAttributeTypeSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(createAttributeSlice.pending, (state) => {
                state.submitBtnLoader = true;
                state.error = null;
            })
            .addCase(createAttributeSlice.fulfilled, (state, action) => {
                console.log("createAction",action)
                state.createAttributeResp = action.payload;
                state.submitBtnLoader = false;
            })
            .addCase(createAttributeSlice.rejected, (state, action) => {
                state.submitBtnLoader = false;
                state.error = action.error.message;
            })

            .addCase(fetchAttributesMappingSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAttributesMappingSlice.fulfilled, (state, action) => {
                state.attributeMappingData = action.payload;
                state.totalItems = action.payload?.totalElements;
                state.loading = false;
            })
            .addCase(fetchAttributesMappingSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(createAttributeMappingSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createAttributeMappingSlice.fulfilled, (state, action) => {
                state.createAttributeMappingData = action.payload;
                state.loading = false;
            })
            .addCase(createAttributeMappingSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchRegisterUsersSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRegisterUsersSlice.fulfilled, (state, action) => {
                state.registerUserData = action.payload;
                state.totalItems = action.payload?.totalElements;
                state.loading = false;
            })
            .addCase(fetchRegisterUsersSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(mobileOtpSendSlice.pending, (state) => {
                state.mobileOTPLoader = true;
                state.error = null;
            })
            .addCase(mobileOtpSendSlice.fulfilled, (state, action) => {
                state.mobileOtpData = action.payload;
                state.mobileOTPLoader = false;
            })
            .addCase(mobileOtpSendSlice.rejected, (state, action) => {
                state.mobileOTPLoader = false;
                state.error = action.error.message;
            })

            .addCase(verifyOtpSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(verifyOtpSlice.fulfilled, (state, action) => {
                state.verifyOtp = action.payload;
                state.loading = false;
            })
            .addCase(verifyOtpSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchCountryRegionSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCountryRegionSlice.fulfilled, (state, action) => {
                state.countryList = action.payload;
                state.loading = false;
            })
            .addCase(fetchCountryRegionSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchBusinessCategorySlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchBusinessCategorySlice.fulfilled, (state, action) => {
                state.businessCategoryList = action.payload;
                state.loading = false;
            })
            .addCase(fetchBusinessCategorySlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchAudienceLanguageSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAudienceLanguageSlice.fulfilled, (state, action) => {
                state.audienceLanguageList = action.payload;
                state.loading = false;
            })
            .addCase(fetchAudienceLanguageSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchBridgeSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchBridgeSlice.fulfilled, (state, action) => {
                state.bridgeDetails = action.payload;
                state.loading = false;
            })
            .addCase(fetchBridgeSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(createBridgeSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createBridgeSlice.fulfilled, (state, action) => {
                state.createBridgeResp = action.payload;
                state.loading = false;
            })
            .addCase(createBridgeSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchAttributesPriceCategorySlice.pending, (state) => {
                state.submitBtnLoader = true;
                state.error = null;
            })
            .addCase(fetchAttributesPriceCategorySlice.fulfilled, (state, action) => {
                state.addPricing = action.payload;
                state.submitBtnLoader = false;
            })
            .addCase(fetchAttributesPriceCategorySlice.rejected, (state, action) => {
                state.submitBtnLoader = false;
                state.error = action.error.message;
            })

            .addCase(updateConsumerSlice.pending, (state) => {
                state.submitBtnLoader = true;
                state.error = null;
            })
            .addCase(updateConsumerSlice.fulfilled, (state, action) => {
                state.editDcpResp = action.payload;
                state.submitBtnLoader = false;
            })
            .addCase(updateConsumerSlice.rejected, (state, action) => {
                state.submitBtnLoader = false;
                state.error = action.error.message;
            })

    },
});

export const { setFormData, clearFormData, clearSignUpState } = superAdminSlice.actions;
export default superAdminSlice.reducer;
