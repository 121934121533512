import { Box, Button, Card, CardContent, CircularProgress, FormControl, Grid2, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import CustomBreadcrumb from '../components/CustomBreadcrumb/CustomBreadcrumb';
import GroupsIcon from "@mui/icons-material/Groups";
import BarChartIcon from "@mui/icons-material/BarChart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SmsIcon from "@mui/icons-material/Sms";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from 'react-redux';
import { campaignSnapShotSlice, fetchCampaignLevelPerformenceSlice } from '../redux/teamSlice/team.slice';


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "4px",
}));

const TableHeadTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#455967",
  fontWeight: "500",
  lineHeight: "1.5rem",
}));

const TableDataTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#455967",
  fontWeight: "500",
}));


const PerformanceCard = ({ title, data }) => (
  <Card sx={{ p: 3, boxShadow: 2, borderRadius: 3 }}>
    <Typography sx={{ fontSize: "20px", color: "#445A68", fontWeight: "500" }} mb={2}>
      {title}
    </Typography>
    <BarChart
      xAxis={[{ scaleType: "band", data: data.xAxis }]}
      series={data.series}
      width={550}
      height={250}
    />
  </Card>
);

const Dashboard = () => {

  const { t } = useTranslation();

  const breadcrumb = [
    {
      title: t('home'),
      href: '/dashboard',
    },
    {
      title: t('dashboard'),
      href: '',
    }
  ];

  const dispatch = useDispatch();
  const [campaign, setCampaign] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { campaignLevelPerformence, campaignSnapShot, loading, totalItems } = useSelector((state) => state.teamsDetails);

  const fetchNotificationData = () => {
    dispatch(fetchCampaignLevelPerformenceSlice({ page, rowsPerPage }));
  };

  useEffect(() => {
    fetchNotificationData();
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {

    dispatch(campaignSnapShotSlice())

  }, [])


  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };


  const stats = [
    { icon: <GroupsIcon sx={{ color: "#3F51B5" }} />, label: "Total Audience", value: campaignSnapShot?.totalAudience },
    { icon: <BarChartIcon sx={{ color: "#4CAF50" }} />, label: "Active Campaigns", value: campaignSnapShot?.activeCampaigns },
    { icon: <AttachMoneyIcon sx={{ color: "#9C27B0" }} />, label: "Total Budget", value: campaignSnapShot?.totalBudget },
    { icon: <NotificationsActiveIcon sx={{ color: "#FFC107" }} />, label: "Pending Approvals", value: campaignSnapShot?.pendingApprovals },
  ];

  const performanceData = [
    {
      icon: <SmsIcon sx={{ color: "#3F51B5" }} />,
      title: "SMS Performance",
      stats: ["Sent", "Delivered", "Clicked"],
      values: ["55,000", "53,000", "2,600"],
    },
    {
      icon: <WhatsAppIcon sx={{ color: "#4CAF50" }} />,
      title: "WhatsApp Performance",
      stats: ["Sent", "Delivered", "Clicked"],
      values: ["32,000", "31,000", "1,900"],
    },
    {
      icon: <FacebookIcon sx={{ color: "#4267B2" }} />,
      title: "Meta Performance",
      stats: ["Audience Uploaded", "Impressions"],
      values: ["110,000", "85,000"],
    },
    {
      icon: <EmailIcon sx={{ color: "#9C27B0" }} />,
      title: "Google Performance",
      stats: ["Audience Uploaded", "Impressions"],
      values: ["95,000", "78,000"],
    },
  ];

  const metaGoogleData = {
    xAxis: ["Meta", "Google"],
    series: [
      { data: [12000, 15000], label: "Impressions", color: "#FFC107" },
      { data: [800, 900], label: "Clicked", color: "#FF5722" },
    ],
  };

  const smsWhatsAppData = {
    xAxis: ["SMS", "WhatsApp"],
    series: [
      { data: [5500, 7000], label: "Delivered", color: "#7E57C2" },
      { data: [600, 900], label: "Clicks", color: "#81C784" },
    ],
  };

  const data = [
    { month: "Jan", spend: 10000, impressions: 150 },
    { month: "Feb", spend: 15000, impressions: 220 }
  ];

  console.log("campaignLevelPerformence", campaignLevelPerformence)

  return (
    <Box>
      <CustomBreadcrumb breadcrumb={breadcrumb} />

      <Box mt={2}>
        <Typography sx={{ color: '#445A68', fontSize: '29px', fontWeight: '600' }}>{t('dashboard')}</Typography>
      </Box>

      <Card sx={{ mt: 5, p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)' }}>
        <Box display={'flex'} justifyContent={'space-between'} gap={2} flexWrap={'wrap'} sx={{ width: '100%' }}>
          {
            stats?.map((stat, index) => {
              return (
                <Card
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    p: 3,
                    borderRadius: 4,
                    boxShadow: 3,
                    transition: "0.3s",
                    width: { xs: "100%", sm: "48%", md: "19%" }, // Responsive width
                    "&:hover": { boxShadow: 6, transform: "scale(1.05)" },
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: stat.color + "20",
                      color: stat.color,
                      p: 1.5,
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mr: 2,
                    }}
                  >
                    {stat?.icon}
                  </Box>
                  <Box>
                    <Typography variant="body1" color="#7C7C7C">
                      {stat?.label}
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" color="#445A68">
                      {stat?.value ? stat?.value : 'No Data Avaliable'}
                    </Typography>
                  </Box>
                </Card>
              )
            })
          }
        </Box>

        {/* <Box mt={5} p={3}>
          <Typography sx={{ fontSize: '20px', color: '#445A68', fontWeight: '500' }} mb={2}>
            Channel Performance
          </Typography>

          <Box display={'flex'} justifyContent={'flex-end'} gap={2} mb={3}>
            <FormControl size='small'>
              <Select value={campaign} onChange={(event) => { setCampaign(event.target.value) }} displayEmpty>
                <MenuItem value="">Select campaign name</MenuItem>
                <MenuItem value="Campaign 1">Campaign 1</MenuItem>
                <MenuItem value="Campaign 2">Campaign 2</MenuItem>
              </Select>
            </FormControl>

            <FormControl size='small'>
              <Select value={timePeriod} onChange={(event) => { setTimePeriod(event.target.value) }} displayEmpty>
                <MenuItem value="">Select time period</MenuItem>
                <MenuItem value="Day">Day</MenuItem>
                <MenuItem value="Week">Week</MenuItem>
                <MenuItem value="Month">Month</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box display={'grid'} gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }} gap={3}>
            {
              performanceData.map((item, index) => {
                return (
                  <Card key={index}
                    // sx={{ p: 3, boxShadow: 2, borderRadius: 3 }}
                    sx={{ p: 3, bgcolor: '#F9F9F9', borderRadius: 3, boxShadow: 1 }}
                  >
                    <Box display={'flex'} alignItems={'center'} gap={1} marginBottom={2}>
                      {item?.icon}
                      <Typography variant="subtitle1" fontWeight="bold">{item?.title}</Typography>
                    </Box>
                    {
                      item.stats.map((stat, index) => {
                        return (
                          <Box display={'flex'} justifyContent={'space-between'} key={index} mb={0.5}>
                            <Typography color='#445A68'>
                              {stat}
                            </Typography>
                            <Typography color='#445A68' fontWeight={'bold'}>
                              {item?.values[index]}
                            </Typography>
                          </Box>
                        )
                      })
                    }
                  </Card>
                )
              })
            }
          </Box>
        </Box> */}

        {/* <Box mt={3} p={3}>
          <Grid2 container spacing={3}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <PerformanceCard title="Meta & Google Performance" data={metaGoogleData} />
            </Grid2>

            <Grid2 size={{ xs: 12, md: 6 }}>
              <PerformanceCard title="SMS & WhatsApp Performance" data={smsWhatsAppData} />
            </Grid2>
          </Grid2>
        </Box> */}

        {/* <Box mt={5} p={3}>

          <Typography sx={{ fontSize: '20px', color: '#445A68', fontWeight: '500' }} mb={2}>
            Spend vs Impressions
          </Typography>

          <Box display={'flex'} justifyContent={'flex-end'} gap={2} mb={3}>
            <FormControl size='small'>
              <Select value={campaign} onChange={(event) => { setCampaign(event.target.value) }} displayEmpty>
                <MenuItem value="">Select campaign name</MenuItem>
                <MenuItem value="Campaign 1">Campaign 1</MenuItem>
                <MenuItem value="Campaign 2">Campaign 2</MenuItem>
              </Select>
            </FormControl>

            <FormControl size='small'>
              <Select value={timePeriod} onChange={(event) => { setTimePeriod(event.target.value) }} displayEmpty>
                <MenuItem value="">Select time period</MenuItem>
                <MenuItem value="Day">Day</MenuItem>
                <MenuItem value="Week">Week</MenuItem>
                <MenuItem value="Month">Month</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis yAxisId="left" orientation="left" label={{ value: "Total Spend ($)", angle: -90, position: "insideLeft" }} />
              <YAxis yAxisId="right" orientation="right" label={{ value: "Impressions (K)", angle: -90, position: "insideRight" }} />
              <Tooltip />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey="spend" stroke="#8A2BE2" dot={{ r: 4 }} name="Total Spend ($)" />
              <Line yAxisId="right" type="monotone" dataKey="impressions" stroke="#FFD700" dot={{ r: 4 }} name="Impressions (K)" />
            </LineChart>
          </ResponsiveContainer>
        </Box> */}

        <Box mt={5} p={3}>
          <Typography sx={{ fontSize: '20px', color: '#445A68', fontWeight: '500' }} mb={2}>
            Campaign-Level Performence
          </Typography>

          {/* <Box display={'flex'} justifyContent={'flex-end'} gap={2} mb={3}>
            <FormControl size='small'>
              <Select value={campaign} onChange={(event) => { setCampaign(event.target.value) }} displayEmpty>
                <MenuItem value="">Select campaign name</MenuItem>
                <MenuItem value="Campaign 1">Campaign 1</MenuItem>
                <MenuItem value="Campaign 2">Campaign 2</MenuItem>
              </Select>
            </FormControl>

            <FormControl size='small'>
              <Select value={timePeriod} onChange={(event) => { setTimePeriod(event.target.value) }} displayEmpty>
                <MenuItem value="">Select time period</MenuItem>
                <MenuItem value="Day">Day</MenuItem>
                <MenuItem value="Week">Week</MenuItem>
                <MenuItem value="Month">Month</MenuItem>
              </Select>
            </FormControl>
          </Box> */}

          <TableContainer
            component={Paper}
            sx={{ mt: 2, border: "1px solid #D4D4D4", borderRadius: "12px" }}
          >
            {
              loading ?
                (
                  <Box display="flex" justifyContent="center" my={3}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Table aria-label="campaign table">
                    <TableHead sx={{ background: "#F1F1F1" }}>
                      <TableRow>
                        <TableCell>
                          <BoxContainer>
                            <TableHeadTitle>Campaign Name</TableHeadTitle>
                          </BoxContainer>
                        </TableCell>
                        <TableCell>
                          <BoxContainer>
                            <TableHeadTitle>Channel</TableHeadTitle>
                          </BoxContainer>
                        </TableCell>
                        <TableCell>
                          <BoxContainer>
                            <TableHeadTitle>Delivered/Impressions</TableHeadTitle>
                          </BoxContainer>
                        </TableCell>
                        <TableCell>
                          <BoxContainer>
                            <TableHeadTitle>Clicks/Clicked</TableHeadTitle>
                          </BoxContainer>
                        </TableCell>
                        <TableCell>
                          <BoxContainer>
                            <TableHeadTitle>Budget</TableHeadTitle>
                          </BoxContainer>
                        </TableCell>
                        <TableCell>
                          <BoxContainer>
                            <TableHeadTitle>Status</TableHeadTitle>
                          </BoxContainer>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        campaignLevelPerformence?.content?.length > 0 ? (
                          campaignLevelPerformence?.content.map((item, index) => {

                            return (
                              <StyledTableRow key={index}>
                                <TableCell>
                                  <TableDataTitle>{item?.campaign}</TableDataTitle>
                                </TableCell>
                                <TableCell>
                                  <TableDataTitle>{item?.channel}</TableDataTitle>
                                </TableCell>
                                <TableCell>
                                  <TableDataTitle>{item?.delivered}</TableDataTitle>
                                </TableCell>
                                <TableCell>
                                  <TableDataTitle>{item?.clicks}</TableDataTitle>
                                </TableCell>
                                <TableCell>
                                  <TableDataTitle>{item?.budget}</TableDataTitle>
                                </TableCell>
                                <TableCell>
                                  <TableDataTitle>{item?.campaignStatus}</TableDataTitle>
                                </TableCell>
                              </StyledTableRow>
                            )
                          })
                        ) : (
                          <>
                            <TableRow>
                              <TableCell colSpan={6} align="center">
                                <Typography variant="subtitle1" color="textSecondary">
                                  No Data Available
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </>
                        )
                      }
                    </TableBody>
                  </Table>
                )
            }
          </TableContainer>

        </Box>
      </Card>
    </Box>
  )
}

export default Dashboard