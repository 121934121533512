import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { MuiOtpInput } from "mui-one-time-password-input";
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { mobileOtpReSendSlice, verifyOtpSlice } from '../../../redux/superAdmin/superAdmin.slice';
import ErrorImage from '../../../assets/verified-240.png'


const MobileOTP = ({ open, handleClose, otp, setOtp, mobileNumber, setOtpVerified, t }) => {
    const [otpInput, setOtpInput] = useState("");
    const [timer, setTimer] = useState(90);
    const [resendVisible, setResendVisible] = useState(false);
    const [error, setError] = useState("");
    const dispatch = useDispatch();

    const handleOtpChange = (value) => {
        setOtpInput(value);
        setError("");
    };

    useEffect(() => {
        let interval;
        if (open && timer > 0) {
            interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
        } else if (!open) {
            setTimer(90);
            setResendVisible(false);
        }

        if (timer === 0) {
            setResendVisible(true);
        }

        return () => clearInterval(interval);
    }, [open, timer]);

    const handleResend = async () => {
        const data = {
            txnId: otp?.txnId,
            mobileNumber: mobileNumber,
        };

        try {
            const result = await dispatch(mobileOtpReSendSlice(data)).unwrap();

            if (result) {
                setOtp(result);
                setResendVisible(false);
                setTimer(90);
                setError("");
                setOtpInput("");
                toast.success("OTP resent successfully!");
            }
        } catch (error) {
            toast.error("Failed to resend OTP. Please try again.");
        }
    };

    const handleSubmit = async () => {
        if (!otpInput || otpInput.length < 6) {
            setError(t('validate.invalidOTP'));
            return;
        }

        const payload = {
            txnId: otp?.txnId,
            otp: otpInput,
            mobileNumber: mobileNumber,
        };

        try {
            const result = await dispatch(verifyOtpSlice(payload)).unwrap();

            if (result?.status === 200 || result?.status === 201) {
                toast.success(result?.message || "OTP verified successfully!");
                setOtpInput("");
                setResendVisible(false);
                setTimer(90);
                setError("");
                setOtpVerified(true);
                handleClose();
            } else {
                throw new Error(result?.message || "Enter the correct OTP!");
            }
        } catch (error) {
            toast.error(error?.message || "Failed to verify OTP. Please try again.");
        }
    };

    return (
        <CustomModal
            open={open}
            onClose={() => {
                setError("");
                handleClose();
            }}
            button={true}
            customWidth={"524px"}
        >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={ErrorImage} width={'150px'} height={'150x'}></img>
            </Box>

            <Typography
                sx={{ textAlign: 'center', paddingTop: '14px', color: '#7C7C7C', fontSize: '18px', fontWeight: '500' }}
            >
                {t('validate.verifyYourCode')}
            </Typography>

            <Box>
                <Typography variant="body1" sx={{ paddingY: '12px', textAlign: 'center', color: '#565656' }}>{otp?.message}</Typography>
                <MuiOtpInput
                    value={otpInput}
                    length={6}
                    onChange={handleOtpChange}
                    inputStyle={{
                        width: "2rem",
                        height: "2rem",
                        margin: "0.5rem",
                        fontSize: "1.2rem",
                        textAlign: "center",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                    }}
                    sx={{ gap: '10px !important' }}
                />
                {error && (
                    <Typography
                        variant="body2"
                        sx={{ color: "red", marginTop: "8px", textAlign: "center" }}
                    >
                        {error}
                    </Typography>
                )}
                {!resendVisible ? (
                    <Typography variant="body2" sx={{ marginTop: 2, color: "#565656" }}>
                        {t('editConsumer.resendOtp')} {t('editConsumer.in')} {timer} {t('editConsumer.seconds')}
                    </Typography>
                ) : (
                    <Typography
                        variant="body2"
                        onClick={handleResend}
                        sx={{
                            marginTop: 2,
                            color: "#445A68",
                            cursor: "pointer",
                            textDecoration: "underline",
                            '&:hover': {
                                color: "#2C3E50",
                            },
                        }}
                    >
                       {t('editConsumer.resendOtp')}
                    </Typography>
                )}
                <Box
                    sx={{ display: 'flex', gap: '10px', paddingTop: '20px' }}
                >
                    <Button
                        sx={{
                            borderRadius: "8px",
                            width: '100%',
                            padding: "8px 12px",
                            background: '#445A68',
                            fontSize: "16px",
                            color: "#FFF",
                            fontWeight: "500",
                            border: "1px solid #445A68",
                            transition: "all 0.3s ease",
                        }}
                        onClick={() => {
                            setOtpInput("");
                            setResendVisible(false);
                            setTimer(90);
                            setError("");
                            handleClose();
                        }}
                    >
                        {t('cancel')}
                    </Button>

                    <Button
                        sx={{
                            color: "#FFF",
                            width: '100%',
                            background: "linear-gradient(0deg, #13BECF 0%, #435D6B 100%)",
                            borderRadius: "8px",
                            padding: "8px 12px",
                            fontSize: "16px",
                            fontWeight: "500",
                            transition: "all 0.3s ease",
                        }}
                        onClick={handleSubmit}
                    >
                        {t('submit')}
                    </Button>
                </Box>
            </Box>
        </CustomModal>
    );
};

export default MobileOTP;

