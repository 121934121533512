import { Box, Button, CircularProgress, Grid2, InputAdornment, Paper, TextField, Typography, IconButton, Chip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/CustomBreadcrumb';
import { useTranslation } from 'react-i18next';
import { Search } from '@mui/icons-material';
import SortIcon from "@mui/icons-material/Sort";
import AddIcon from "@mui/icons-material/Add";
import { fetchPriceListSlice, SearchAttributeListSlice } from '../../../redux/superAdmin/superAdmin.slice';
import CustomPagination from '../../../components/CustomPagination/CustomPagination';
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles((theme) => ({
    searchBar: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
        },
        "& .MuiInputBase-input": {
            color: "#2d2d2d",
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },

    selectField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#FFFFFF", // Background color
            borderRadius: "10px",
        },
        "& .MuiInputBase-input": {
            color: "#2d2d2d",
        },
        "& .MuiInputBase-input::placeholder": {
            color: "#2d2d2d",
        },
    },

    textField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#FFFFFF", // Background color
            borderRadius: "10px",
        },
        "& .MuiInputBase-input": {
            color: "#2d2d2d",
        },
        "& .MuiInputBase-input::placeholder": {
            color: "#2d2d2d",
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "4px",
}));
const statusStyles = {
    active: {
        backgroundColor: "#e0f7e9",
        color: "#34a853",
    },
    offline: {
        backgroundColor: "#f5f5f5",
        color: "#9e9e9e",
    },
};

const TableHeadTitle = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    color: "#455967",
    fontWeight: "500",
    lineHeight: "1.5rem",
}));

const TableDataTitle = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    color: "#455967",
    fontWeight: "500",
}));

const Pricing = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const { priceList, totalItems, loading } = useSelector(
        (state) => state.superAdmin
    );

    const breadcrumb = [
        {
            title: t("pricing.title"),
            href: '',
        },
    ];

    useEffect(() => {
        dispatch(fetchPriceListSlice({ page, rowsPerPage }));

    }, [dispatch, page, rowsPerPage]);


    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    return (
        <Box>
            <CustomBreadcrumb breadcrumb={breadcrumb} />

            <Grid2 container sx={{ justifyContent: "end" }} mt={3}>

                <Grid2 size={{ xs: 12, md: 8 }}>
                    <Typography
                        sx={{ color: "#445A68", fontSize: "29px", fontWeight: "500" }}
                    >
                        {t("pricing.title")}
                    </Typography>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>

                </Grid2>
            </Grid2>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>

                <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    sx={{
                        borderRadius: "40px",
                        // padding: "15px 30px",
                        color: "#445A68",
                        fontSize: "18px",
                        fontWeight: "600",
                        border: "1px solid #445A68",
                    }}
                    onClick={() => { navigate('/add-pricing') }}
                >
                    {t("pricing.addPricing")}
                </Button>
                {/* 
                <Button
                    disabled
                    variant="contained"
                    endIcon={<SortIcon />}
                    sx={{
                        color: "#fff",
                        backgroundColor: "#13BECF",
                        borderRadius: "10px",
                        fontSize: "16px",
                        fontWeight: "500",
                        borderRadius: "6px",
                        // border: "1px solid #13BECF",
                        cursor: "not-allowed ",
                    }}
                >
                    {t('sort')}
                </Button> */}

            </Box>

            <TableContainer
                component={Paper}
                sx={{ mt: 2, border: "1px solid #D4D4D4", borderRadius: "12px" }}
            >
                {loading ? (
                    <Box display="flex" justifyContent="center" my={3}>
                        <CircularProgress />
                    </Box>
                ) :
                    (<>
                        <Table aria-label="campaign table">
                            <TableHead sx={{ background: "#F1F1F1" }}>
                                <TableRow>
                                    <TableCell sx={{ minWidth: "130px" }}>
                                        <BoxContainer>
                                            <TableHeadTitle>
                                                {t("pricing.attributeLabel")}
                                            </TableHeadTitle>
                                        </BoxContainer>
                                    </TableCell>
                                    {/* <TableCell sx={{ minWidth: "130px" }}>
                                        <BoxContainer>
                                            <TableHeadTitle>Price Value</TableHeadTitle>
                                        </BoxContainer>
                                    </TableCell> */}
                                    <TableCell sx={{ minWidth: "130px" }}>
                                        <BoxContainer>
                                            <TableHeadTitle>
                                                {t("pricing.percentageValue")}
                                            </TableHeadTitle>
                                        </BoxContainer>
                                    </TableCell>
                                    <TableCell sx={{ minWidth: "130px" }}>
                                        <BoxContainer>
                                            <TableHeadTitle>
                                                {t("pricing.fixedBy")}
                                            </TableHeadTitle>
                                        </BoxContainer>
                                    </TableCell>

                                    <TableCell sx={{ minWidth: "130px" }}>
                                        <BoxContainer>
                                            <TableHeadTitle>
                                                {t("pricing.status")}
                                            </TableHeadTitle>
                                        </BoxContainer>

                                    </TableCell>

                                    <TableCell sx={{ minWidth: "130px" }}>
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {priceList?.content?.length > 0 ? (
                                    <>
                                        {
                                            priceList?.content?.map((item, index) => {

                                                return (
                                                    <StyledTableRow role="checkbox">

                                                        <TableCell>
                                                            <TableDataTitle>{item?.label ? item?.label : "not given"}</TableDataTitle>
                                                        </TableCell>
                                                        {/* <TableCell>
                                                            <TableDataTitle>{item?.value ? item?.value : 'not given'}</TableDataTitle>
                                                        </TableCell> */}
                                                        <TableCell>
                                                            <TableDataTitle>{item?.percentageValue ? item?.percentageValue : "not given"}</TableDataTitle>
                                                        </TableCell>

                                                        <TableCell>
                                                            <TableDataTitle >{item?.fixedValue ? item?.fixedValue : "not given"}</TableDataTitle>
                                                        </TableCell>

                                                        <TableCell>
                                                            <Chip
                                                                label={`• ${item?.active ? "Active" : "Inactive"}`} // Adds the dot before the text
                                                                sx={{
                                                                    ...(statusStyles[item?.active ? "active" : "offline"]),
                                                                    fontWeight: "bold",
                                                                    fontSize: "14px",
                                                                    padding: "2px 6px",
                                                                    borderRadius: "2px",
                                                                }}
                                                            />
                                                        </TableCell>

                                                        <TableCell>

                                                            <IconButton
                                                                color="#455967"
                                                                onClick={e => navigate(`/pricing/update/${item?.id}`)} // Open dialog on delete click
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </TableCell>

                                                        {/* navigate(`/pricing/edit/${id}`);  */}

                                                    </StyledTableRow>
                                                )
                                            })
                                        }
                                    </>
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {t('attribute.noData')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </>
                    )}
            </TableContainer>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                {/* Center CustomPagination */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flex: 1,
                    }}
                >
                    <CustomPagination
                        rowsPerPageOptions={[5, 10, 25]}
                        count={totalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, newPage) => handlePageChange(newPage)}
                        onRowsPerPageChange={(e) =>
                            handleRowsPerPageChange(parseInt(e.target.value, 10))
                        }
                    />
                </Box>

                {/* Typography aligned to the end */}
                <Box
                    sx={{
                        flex: "0 0 auto",
                    }}
                >
                    <Typography sx={{
                        fontSize: '19px',
                        fontWeight: '600',
                        background: 'linear-gradient(180deg, #13BECF 0%, #455967 100%)',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                    }}>
                        {page * rowsPerPage + 1}-{Math.min((page + 1) * rowsPerPage, totalItems)} <span style={{ color: '#455967' }}>of {totalItems}</span>
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default Pricing