import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import {
  Box,
  TextField,
  Typography,
  Card,
  Button,
  Autocomplete,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChatInterface from "./ChatUi";
import SMSChatInterface from "./SmsChat";
import { createTemplateSMSSlice } from "../../redux/teamSlice/team.slice";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
      borderRadius: "10px",
      border: "0px solid #C5C5C5",
      height: "60px", // Updated height to 60px
      background: "#FFF",
    },
    "& .MuiInputBase-input::placeholder": {
      backgroundColor: "#FFF", // Ensuring placeholder background is white
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  selectField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
      borderRadius: "10px",
      // border: "1px solid #C5C5C5",
      height: "60px", // Updated height to 60px
      background: "#FFF",
    },
    "& .MuiInputBase-input::placeholder": {
      backgroundColor: "#FFF", // Ensuring placeholder background is white
    },
  },
  label: {
    color: "#7C7C7C",
    fontWeight: 500,
    marginBottom: "2px",
    fontSize: "18px !important",
  },
}));

const CreateSmsCommunication = ({ t }) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(0);
  const classes = useStyles();
  const navigate = useNavigate()
  const { loading, submitBtnLoader } = useSelector((state) => state.teamsDetails)

  const validationSchema = Yup.object().shape({
    templateName: Yup.string().required(t('validate.templateNameReq')),
    templateId: Yup.string().required(t('validate.templateIdReq')),
    templateText: Yup.string()
      .required(t('validate.templateContentReq'))
      // .max(150, 'Maximum 150 words allowed'),
      .test(
        "maxWords",
        'Maximum 150 words allowed',
        (value) => {
          if (!value) return true;
          const wordCount = value.trim().split(/\s+/).length;
          return wordCount <= 150;
        }
      ),
    templateCategory: Yup.string().required(t('validate.templateCategoryReq')),
  });


  const handleFormChange = (e, fieldName, value) => {
    console.log("values", e, fieldName, value);

    setFormValues(value)
  };


  const mainHandleSubmit = async (values, { resetForm }) => {
    // e.preventDefault();

    const result = await dispatch(createTemplateSMSSlice(values)).unwrap();

    if (result) {
      resetForm();
      setFormValues(0);
      navigate('/template-list')
    }
    else {
      console.log("error")
    }
  };



  return (
    <Box sx={{ width: "100%" }}>
      <Grid container mt={4} spacing={2}>
        <Grid size={{ md: 7 }}>
          <Card
            sx={{
              p: 3,
              borderRadius: "15px",
              boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Formik
              initialValues={{
                templateName: "",
                templateId: "",
                templateText: "",
                templateCategory: "",
                templateType: 'SMS',
              }}
              validationSchema={validationSchema}
              onSubmit={mainHandleSubmit}
            >
              {({ values, handleChange, handleBlur, handleSubmit, onChange, setFieldValue, errors, touched }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid size={{ md: 12 }}>
                      <Typography className={classes.label}>
                        {t('templateList.templateName')}{" "}
                        <span style={{ color: "#FF0000" }}>*</span>
                      </Typography>
                      <Field
                        as={TextField}
                        name="templateName"
                        value={values.templateName}
                        onChange={(e) => {
                          handleChange(e); // Update Formik's internal state
                          handleFormChange(e); // Update your custom formValues state
                          handleFormChange(e, `templateName`, { ...values, templateName: e.target.value });

                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.templateName && errors.templateName)}
                        helperText={touched.templateName && errors.templateName}
                        className={classes.textField}
                      />
                    </Grid>

                    <Grid size={{ md: 12 }}>
                      <Typography className={classes.label}>
                        {t('templateList.templateId')} <span style={{ color: "#FF0000" }}>*</span>
                      </Typography>
                      <Field
                        as={TextField}
                        name="templateId"
                        placeholder={t('templateList.enterID')}
                        type="text"
                        onChange={(e) => {
                          handleChange(e);
                          handleFormChange(e);
                          handleFormChange(e, `templateId`, { ...values, templateId: e.target.value });

                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.templateId && errors.templateId)}
                        helperText={touched.templateId && errors.templateId}
                        className={classes.textField}
                      />
                    </Grid>
                    <Grid size={{ md: 12 }}>
                      <Typography className={classes.label}>
                        {t('templateList.templateContent')}{" "}
                        <span style={{ color: "#FF0000" }}>*</span>
                      </Typography>
                      <Field
                        as={TextField}
                        name="templateText"
                        id="content"
                        placeholder={t('templateList.enterContent')}
                        type="text"
                        onChange={(e) => {
                          handleChange(e);
                          handleFormChange(e);
                          handleFormChange(e, `templateText`, { ...values, templateText: e.target.value });

                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.templateText && errors.templateText)}
                        helperText={touched.templateText && errors.templateText}
                        className={classes.textField}
                      />
                    </Grid>

                    <Grid size={{ md: 12 }}>
                      <Typography className={classes.label}>{t('templateList.templateCategory')} <span style={{ color: '#FF0000' }}>*</span></Typography>
                      <Field
                        name="templateCategory"
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={['MARKETING', 'UTILITY', 'AUTHENTICATION']} // Replace with actual categories
                            value={values.templateCategory}
                            // onChange={(e, value) => setFieldValue("templateCategory", value)}
                            className={classes.selectField}
                            onChange={(e, value) => {
                              setFieldValue("templateCategory", value);
                              // handleFormChange({ target: { name: 'templateCategory', value } });
                              handleFormChange(e, `templateCategory`, { ...values, templateCategory: value });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t('templateList.selectTemplateCategory')}
                                error={Boolean(touched.templateCategory && errors.templateCategory)}
                                helperText={touched.templateCategory && errors.templateCategory}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                      paddingY: "10px",
                      marginTop: "30px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      startIcon={<ArrowBackIcon />}
                      onClick={() => { navigate('/template-list') }}
                      sx={{
                        borderRadius: "40px",
                        padding: "15px 30px",
                        backgroundColor: "#445A68",
                        color: "#FFFFFF",
                        fontSize: "18px",
                        fontWeight: "600",
                        border: "1px solid rgba(68, 90, 104, 1)",
                      }}
                    >
                      {t("templateList.backToList")}
                    </Button>
                    <Button
                      variant="outlined"
                      endIcon={<ArrowForwardIcon />}
                      sx={{
                        borderRadius: "40px",
                        padding: "15px 30px",
                        color: "#FFFFFF",
                        background:
                          "linear-gradient(0deg, #13BECF 0%, #435D6B 100%)",
                        fontSize: "18px",
                        fontWeight: "600",
                        border: "1px solid #13BECF",
                      }}
                      type="submit"
                    >
                      {t('submit')}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Card>
        </Grid>

        <Grid
          size={{ md: 5 }}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SMSChatInterface formValues={formValues} />
        </Grid>
      </Grid>
      <CustomLoader loading={submitBtnLoader} />
    </Box>
  );
};

export default CreateSmsCommunication;
