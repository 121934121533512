import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Divider,
  Modal,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useDispatch, useSelector } from "react-redux";
import { fetchCreateDataRequestSlice } from "../../../redux/stepperSlice/stepper.slice";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Grid2";
import PropTypes from "prop-types";
import { jwtDecode } from "jwt-decode";
import videoImg from "../../../assets/DemoVideo.jpg"
import TemplateDetailsModal from "../Components/TemplateDetailsModal";
import TemplateViewScreen from "../Components/TemplateViewScreen";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import CustomLoader from "../../../components/CustomLoader/CustomLoader";

const dataToShow = [
  {
    "type": "HEADER",
    "format": "IMAGE"
  },
  {
    "type": "BODY",
    "text": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
  },
  {
    "type": "FOOTER",
    "text": "Testing New"
  },
  {
    "type": "BUTTONS",
    "buttons": [
      {
        "type": "URL",
        "text": "Check",
        "url": "http://www.url.com/"
      }
    ]
  }
];

const smsData = {
  "id": "67441c89ff0d4f7233c331b3",
  "orgId": "66ffacd73030fd2b301f0c07",
  "templateId": "template-act-12",
  "templateCategory": "MARKETING",
  "templateText": "This is a sample template text-act-12.",
  "templateName": "my-temp-act-12",
  "status": "APPROVED",
  "media": null,
  "templateType": "SMS",
  "categoryId": "id-act-12",
  "createdOn": null,
  "createdBy": "Mukti",
  "updatedOn": null,
  "updatedBy": null,
  "deleted": false
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AdditionalInfo = ({
  handleChange,
  formValues,
  classes,
  prevStep,
  audienceData,
  campaignId,
  t
}) => {
  const { createCampaignResponse } = useSelector((state) => state.stepper);
  const orgId = createCampaignResponse.orgId;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { discoverFields, submitBtnLoader } = useSelector((state) => state.stepper);
  const [value, setValue] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const characterLimit = 150;
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [newOrgId, setNewOrId] = useState(null);

  // const budgetData = [
  //   {
  //     impressions: formValues?.impressions1Day,
  //     budget: formValues?.budget1Day,
  //     budgetDays: `${createCampaignResponse.oneDay} Day`,
  //     day: createCampaignResponse.oneDay
  //   },
  //   {
  //     impressions: formValues?.impressions7Days,
  //     budget: formValues?.budget7Days,
  //     budgetDays: `${createCampaignResponse.sevenDay} Days`,
  //     day: createCampaignResponse.sevenDay
  //   },
  //   {
  //     impressions: formValues?.impressions30Days,
  //     budget: formValues?.budget30Days,
  //     budgetDays: `${createCampaignResponse.thirtyDay} Days`,
  //     day: createCampaignResponse.thirtyDay
  //   }
  // ].filter(tableData => tableData.day > 0)


  const renderText = () => {
    const text = dataToShow[1].text || ''; // Safely access textBody
    // If expanded, return the full text
    if (isExpanded) {
      return text;
    }
    // If collapsed, return truncated text
    return dataToShow[1].text.length > characterLimit ? `${text.substring(0, characterLimit)}...` : text;
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const token = localStorage.getItem("idToken");
    const tokenData = jwtDecode(token);
    const cognitoGroups = tokenData["custom:orgId"];
    setNewOrId(cognitoGroups);
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };


  // function formatDateRange(startDateStr, endDateStr) {
  //   // Check if the date strings are valid
  //   const startDate = new Date(startDateStr);
  //   const endDate = new Date(endDateStr);

  //   if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
  //     // If either date is invalid, return an appropriate message or handle the error
  //     return "Invalid date range";
  //   }

  //   // Function to format date to DD-MM-YYYY
  //   function formatDate(date) {
  //     const day = String(date.getDate()).padStart(2, "0"); // Ensures two digits for the day
  //     const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
  //     const year = date.getFullYear();

  //     return `${day}-${month}-${year}`;
  //   }

  //   const formattedStartDate = formatDate(startDate);
  //   const formattedEndDate = formatDate(endDate);

  //   return `${formattedStartDate} to ${formattedEndDate}`;
  // }

  function formatDateRange(startDateStr, endDateStr) {
    // Check if the date strings are valid
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return "Invalid date range";
    }

    // Function to format date & time as DD-MM-YYYY HH:MM AM/PM
    function formatDateTime(date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const year = date.getFullYear();

      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const amPm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12; // Convert 24-hour time to 12-hour format

      return `${day}-${month}-${year} ${hours}:${minutes} ${amPm}`;
    }

    const formattedStartDate = formatDateTime(startDate);
    const formattedEndDate = formatDateTime(endDate);

    return `${formattedStartDate} to ${formattedEndDate}`;
  }

  const { Parentattributes } = formValues;
  function transformData(input) {
    const result = [];

    // Check if Parentattributes exists and is an array
    if (Array.isArray(input.Parentattributes)) {
      // Loop through Parentattributes
      for (const attribute of input.Parentattributes) {
        const code = attribute.attributeCode;
        const name = attribute.attributeName;
        const type = attribute.type;

        // Get the corresponding values from the input object
        if (input[code]) {
          for (const value of input[code]) {
            result.push({
              code: code,
              value: value,
              type: type,
              name: name,
            });
          }
        }
      }
    } else {
      console.error(
        "Parentattributes is not defined or not an array:",
        input.Parentattributes
      );
    }

    return result;
  }

  function transformAttributes(data) {
    const result = {};
    data.forEach((attribute) => {
      const { name, value, type, code } = attribute;

      if (!result[name]) {
        result[name] = {
          name: name,
          possibleValues: [],
          type: type,
          code: code,
        };
      }

      if (!result[name].possibleValues.includes(value)) {
        result[name].possibleValues.push(value);
      }
    });

    return Object.values(result);
  }

  const newValues = transformData(formValues);

  const transformedAttributes = transformAttributes(newValues);

  const handleApproval = async (orgId) => {
    
    const payload = {
      description: createCampaignResponse.description,
      owner: newOrgId,
      attributes: transformedAttributes,
      campaignId: campaignId,
    };
    setOpen(false)
    const result = await dispatch(
      fetchCreateDataRequestSlice(payload)
    ).unwrap();

    if (result) {
      navigate("/campaign-list");
    } else {
      console.log("Error");
    }
  };


  const campaignDetails = [
    {
      // title: t('stepper.campaignDetails.campaignDetails'),
      title: 'Campaign Name',

      value: formValues?.campaignName,
    },

    // { title: t('campaignList.websiteUrl'), value: formValues?.description },

    {
      title: t('date'),
      value: formatDateRange(formValues?.startDate, formValues?.endDate),
    },
    {
      title: t('campaignList.channel'),
      value: formValues.channel,
    },

    // {
    //   title: t('stepper.campaignDetails.media'),

    //   value: [formValues?.mediaImage],
    //   type: formValues?.mediaType,
    // },
  ];


  return (
    <Box>
      <Grid container spacing={2}>
        <Box sx={{ marginTop: "30px", width: "100%", marginX: "30px" }}>
          {/* <Accordion
            sx={{
              border: "1px solid #ccc",
              borderRadius: "10px !important",
              boxShadow: "none",
              overflow: "hidden",
              boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
              marginBottom: "25px !important",
              pointerEvents: "none",
              cursor: "default",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                minHeight: "65px",
                color: "#333333",
                fontWeight: "500",
                fontSize: "19px",
                borderTopLeftRadius: "15px !important",
                borderTopRightRadius: "15px !important",
                borderRadius: "10px",
              }}
            >
              <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <CheckCircleIcon
                  sx={{
                    fill: "#13BECF",
                    borderRadius: "50%",
                    width: "25px",
                    height: "25px",
                  }}
                />
                {t('stepper.audienceManager.chooseCampaignType')}
              </Box>
            </AccordionSummary>
          </Accordion> */}

          <Accordion
            sx={{
              border: "1px solid #ccc",
              borderRadius: "10px !important",
              boxShadow: "none",
              overflow: "hidden",
              boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
              marginBottom: "25px !important",
              pointerEvents: "none",
              cursor: "default",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                minHeight: "65px",
                color: "#333333",
                fontWeight: "500",
                fontSize: "19px",
                borderTopLeftRadius: "15px !important",
                borderTopRightRadius: "15px !important",
                borderRadius: "10px",
              }}
            >
              <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <CheckCircleIcon
                  sx={{
                    fill: "#13BECF",
                    borderRadius: "50%",
                    width: "25px",
                    height: "25px",
                  }}
                />
                {t('stepper.audienceManager.campaignDetails')}
              </Box>
            </AccordionSummary>
          </Accordion>

          <Accordion
            sx={{
              border: "1px solid #ccc",
              borderRadius: "10px !important",
              boxShadow: "none",
              overflow: "hidden",
              boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
              marginBottom: "25px !important",
              pointerEvents: "none",
              cursor: "default",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                minHeight: "65px",
                color: "#333333",
                fontWeight: "500",
                fontSize: "19px",
                borderTopLeftRadius: "15px !important",
                borderTopRightRadius: "15px !important",
                borderRadius: "10px",
              }}
            >
              <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <CheckCircleIcon
                  sx={{
                    fill: "#13BECF",
                    borderRadius: "50%",
                    width: "25px",
                    height: "25px",
                  }}
                />
                {t('stepper.audienceManager.audienceManager')}
              </Box>
            </AccordionSummary>
          </Accordion>



          <Accordion
            defaultExpanded
            sx={{
              border: "1px solid #ccc",
              borderRadius: "10px !important",
              boxShadow: "none",
              overflow: "hidden",
              boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
              marginBottom: "25px !important",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                minHeight: "65px ",
                color: "#333333",
                fontWeight: "500",
                fontSize: "19px",
                borderTopLeftRadius: "15px !important",
                borderTopRightRadius: "15px !important",
                borderRadius: "10px",
              }}
            >
              <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <RadioButtonUncheckedIcon
                  sx={{
                    fill: "#13BECF",
                    borderRadius: "50%",
                    width: "25px",
                    height: "25px",
                  }}
                />
                {t('stepper.preview.preview')}
              </Box>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                {/* Left section - Campaign details */}
                <Grid size={{ xs: 12, md: 7 }}>
                  <Box sx={{ padding: 4, borderBottom: "1px solid #3333" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 500,
                        fontSize: "20px",
                        marginLeft: "-20px",
                        marginBottom: "20px", // Adds space to the right
                      }}
                    >
                      {t('stepper.audienceManager.campaignDetails')}
                    </Typography>
                    {campaignDetails.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          py: 1,
                        }}
                      >
                        {/* Title */}
                        <Typography
                          className={classes.additionInfo}
                          sx={{ width: "40%" }}
                        >
                          {item.title}
                        </Typography>

                        {/* Value - Display text, images, or chips based on content */}
                        <Box sx={{ width: "60%" }}>
                          {Array.isArray(item.value) ? (
                            item.title === "Channel:" ? (
                              /* Render Chip components for channels */
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 1,
                                }}
                              >
                                {item.value.map((val, idx) => (
                                  <Chip
                                    key={idx}
                                    label={val}
                                    sx={{
                                      borderRadius: "18px",
                                      background: "#445A68E5",
                                      color: "#fff",
                                      "& .MuiChip-deleteIcon": {
                                        color: "white",
                                        fontSize: "16px",
                                      },
                                      "& .MuiChip-deleteIcon:hover": {
                                        backgroundColor: "transparent",
                                        color: "white",
                                      },
                                    }}
                                  />
                                ))}
                              </Box>
                            ) : (
                              /* If value is an array of images */

                              <Box sx={{ display: "flex", gap: 1 }}>

                                {item.value.map((imageUrl, idx) => (
                                  <img
                                    key={idx}
                                    src={item.type === 'video/mp4' ? videoImg : imageUrl}
                                    alt={`${item.title} ${idx + 1}`}
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                ))}
                              </Box>
                            )
                          ) : typeof item.value === "string" &&
                            item.value.startsWith("http") &&
                            /\.(jpg|jpeg|png|gif)$/i.test(item.value) ? (
                            <img
                              src={item.value}
                              alt={item.title}
                              style={{
                                width: "100px",
                                height: "auto",
                                borderRadius: "10px",
                              }}
                            />
                          ) : (
                            <Typography className={classes.additionInfoValue}>
                              {item.value ? item.value : "Data not present"}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    ))}
                  </Box>


                  <Box sx={{ padding: 4 }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 500,
                        fontSize: "20px",
                        marginLeft: "-20px",
                        marginBottom: "20px", // Adds space to the right
                      }}
                    >
                      {t('stepper.audienceManager.audienceManager')}
                    </Typography>
                    {Parentattributes?.map((item, index) => {
                      // Get the value corresponding to the attributeName in formValues
                      const value = formValues[item?.attributeCode];

                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            py: 1,
                          }}
                        >
                          {/* Title */}
                          <Typography
                            className={classes.additionInfo}
                            sx={{ width: "40%" }}
                          >
                            {item?.attributeName}
                          </Typography>

                          {/* Value - Check if the corresponding formValues key exists */}
                          <Box sx={{ width: "60%" }}>
                            {Array.isArray(value) ? (
                              /* If value is an array, display it as chips */
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  flexWrap: "wrap",
                                }}
                              >
                                {value.map((val, idx) => (
                                  <Chip
                                    key={idx}
                                    label={val}
                                    sx={{
                                      borderRadius: "18px",
                                      background: "#445A68E5",
                                      color: "#fff",
                                      "& .MuiChip-deleteIcon": {
                                        color: "white",
                                        fontSize: "16px",
                                      },
                                      "& .MuiChip-deleteIcon:hover": {
                                        backgroundColor: "transparent",
                                        color: "white",
                                      },
                                    }}
                                  />
                                ))}
                              </Box>
                            ) : (
                              /* If value is not an array, show the text or a default message */
                              <Typography className={classes.additionInfoValue}>
                                {value ? value : "Data not present"}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>

                <Grid size={5} sx={{ display: 'flex', justifyContent: 'center' }}>
                  {(formValues?.channel === 'SMS' || formValues?.channel === 'WHATSAPP') && (

                    <TemplateViewScreen
                      isExpanded={isExpanded}
                      setIsExpanded={setIsExpanded}
                      handleToggleExpand={handleToggleExpand}
                      tempType={formValues.channel}
                      templateData={formValues?.templateViewData || formValues?.templateObj}
                      dataToShow={dataToShow}
                      smsData={smsData}
                      renderText={renderText}
                      characterLimit={characterLimit}
                    />
                  )}
                </Grid>
              </Grid>

              <Divider />
              {/* <Box sx={{ padding: 4 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 500,
                    fontSize: "20px",
                    marginLeft: "-20px",
                    marginBottom: "20px", // Adds space to the right
                  }}
                >
                  {t('stepper.audienceManager.budgetManagement')}
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    marginTop: "12px",
                    border: "1px solid #D4D4D4",
                    borderRadius: "12px",
                  }}>
                  <Table aria-label="campaign table">
                    <TableHead sx={{ background: '#13BECF1C' }}>
                      <TableRow>
                        <TableCell sx={{ width: '33%', textAlign: 'center' }}>
                          {t('stepper.preview.timeline')}
                        </TableCell>
                        <TableCell sx={{ width: '33%', textAlign: 'center' }}>
                          {t('stepper.audienceManager.budget')}
                        </TableCell>
                        <TableCell sx={{ width: '33%', textAlign: 'center' }}>
                          {t('stepper.audienceManager.estimatedImpressions')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {budgetData?.map((item, index) => {
                        return (
                          <TableRow>
                            <TableCell sx={{ textAlign: 'center' }}>{item?.budgetDays}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>{item?.budget}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>{item?.impressions}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box> */}

            </AccordionDetails>
          </Accordion>
        </Box>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "15px",
          paddingY: "10px",
        }}
      >
        <Button
          variant="outlined"
          onClick={prevStep}
          startIcon={<ArrowBackIcon />}
          sx={{
            borderRadius: "40px",
            backgroundColor: "#445A68",
            color: "#FFFFFF",
            padding: "16px 24px",
            fontSize: "18px",
            fontWeight: "600",
            border: "1px solid rgba(68, 90, 104, 1)",
          }}
        >
          {t('stepper.saveForDraft')}
        </Button>
        <Button
          variant="outlined"
          endIcon={<ArrowForwardIcon />}
          sx={{
            color: "#13BECF",
            borderRadius: "40px",
            color: "#FFFFFF",
            background: "linear-gradient(0deg, #13BECF 0%, #435D6B 100%)",
            padding: "16px 24px",
            fontSize: "18px",
            fontWeight: "600",
            border: "1px solid #13BECF",
          }}
          onClick={handleOpen}
        // onClick={() => {
        //   handleApproval(orgId);
        // }}
        >
          {t('stepper.preview.sendForApproval')}
        </Button>
      </Box>

      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        confirmationMessage={t('sendCampaignForApproval')}
        handleSubmit={handleApproval}
      />

      <CustomLoader loading={submitBtnLoader} />
    </Box>
  );
};

export default AdditionalInfo;
