

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchProfileSlice } from "./redux/superAdmin/superAdmin.slice";
import { Box, IconButton, AppBar, Toolbar, Typography, Avatar, Switch } from "@mui/material";
import { Snackbar, Alert, AlertTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import LeftSidebar from "./layout/LeftSidebar";
import RightSidebar from "./layout/RightSidebar";
import { Outlet } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LOGO from './assets/Vector.png';
import MainHeader from "./components/CustomBreadcrumb/MainHeader";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { styled } from "@mui/material/styles";
import EmailAlertModal from "./components/EmailAlertModal/EmailAlertModal";
import { resendEmailSlice } from "./redux/superAdmin/superAdmin.slice";
import { useTranslation } from "react-i18next";





const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 49,
  height: 24,
  padding: 0,
  borderRadius: '12px',
  backgroundColor: '#13BECF',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 16,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translate(9px,2px);',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    transform: 'translate(3px,2px);',
    '&.Mui-checked': {
      transform: 'translate(27px,2px);',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#13BECF',
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 8,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)',
    }),
  },
}));



const MainLayout = () => {
  const { t } = useTranslation();
  const [leftOpen, setLeftOpen] = useState(true);
  const [rightOpen, setRightOpen] = useState(false);
  const [dataGroup, setDataGroup] = useState("")
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const navigate = useNavigate()
  const dispatch = useDispatch('')
  var loginData = useSelector(state => state.login.token)
  var profileData =  useSelector(state=>state.superAdmin.profileData)
  const cognitoGroups = loginData["groups"];


  const [showAlert, setShowAlert] = useState(!profileData?.user?.emailVerified);
  const [isFirstLogin, setIsFirstLogin] = useState(true); 
  

 
  const handleCloseAlert = () => {
    setShowAlert(false);
  };




  useEffect(() => {
  
    if (!cognitoGroups || cognitoGroups.length === 0) {
      console.warn("cognitoGroups is empty or undefined");
      return;
    }

    if (cognitoGroups.length === 1) {
      const group = cognitoGroups[0];
      setDataGroup(group);
      if(profileData?.isProfileCompleted){
      navigate(group === "DataUser" ? "/dashboard" : group === "PlatformOwner" ? "user-management" : "provider-dashboard");
    }
    else if(group === "PlatformOwner" ){

      navigate('/user-management')
    }
    else{
      navigate('/edit-profile')
    }
    } else {
      setDataGroup(isSwitchOn ? "DataUser" : "DataProvider");
    }
    
 
  }, [cognitoGroups, isSwitchOn]);

 


  const handleSwitchChange = () => {
    setIsSwitchOn((prevState) => {
      const newState = !prevState;
      setDataGroup(newState ? "DataUser" : "DataProvider");
      navigate(newState ? "/dashboard" : "/provider-dashboard");
      return newState;
    });
  };


  const toggleLeftDrawer = () => {
    setLeftOpen(!leftOpen);
  };

  const toggleRightDrawer = () => {
    setRightOpen(!rightOpen);
  };

 

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {/* Left Sidebar */}
      <LeftSidebar open={leftOpen} dataGroup={dataGroup} toggleDrawer={toggleLeftDrawer} />

      {/* Main Dashboard Content */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          transition: "margin-left 0.3s ease-out, margin-right 0.3s ease-out",
          backgroundColor: "#f8f8f8",
          overflowX: "hidden",
        }}
      >
        {/* Header */}

        <MainHeader rightOpen={rightOpen} />

        {cognitoGroups.length > 1 &&
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'end', marginRight: '30px', marginTop: '20px' }}>

            <Typography sx={{ fontSize: '22px', color: '#13BECF' }}>
              {/* {t('dataRequest.dataContributor')} */}
              {dataGroup === "DataUser" ? t('dataConsumer') : t('dataProvider')}
            </Typography>
            <AntSwitch
              checked={isSwitchOn}
              onChange={handleSwitchChange}
              inputProps={{ 'aria-label': 'ant design' }} />
          </Box>
        }

        {/* Dashboard Content */}
        <Box sx={{ flexGrow: 1, padding: '12px 24px' }}>
          <Outlet context={{ dataGroup }} />

        </Box>
      </Box>

      {/* Right Sidebar */}
      <RightSidebar open={rightOpen} toggleDrawer={toggleRightDrawer} />

      {/* Toggle Button for Right Drawer */}
      {!rightOpen && (
        <IconButton
          onClick={toggleRightDrawer}
          sx={{
            position: "absolute",
            right: 10,
            top: "10px",
            zIndex: 1300,
            bgcolor: "#fff",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            "&:hover": { bgcolor: "#f0f0f0" },
          }}
        >
          <NotificationsIcon sx={{
            color: "#13BECF"
          }} />
        </IconButton>
      )}

     
     {  cognitoGroups[0] === "PlatformOwner" ? '' : <EmailAlertModal
          open={showAlert}
          handleClose={handleCloseAlert}
        /> }

    </Box>
  );
};

export default MainLayout;
