import React, { useEffect, useState } from 'react'
import CustomModal from '../CustomModal/CustomModal'
import { Box, Button, Typography } from '@mui/material';
import ErrorImage from '../../assets/emai_popup_image.jpg'
import { useDispatch } from 'react-redux';
import { fetchProfileSlice, resendEmailSlice } from '../../redux/superAdmin/superAdmin.slice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const EmailAlertModal = ({ open, handleClose }) => {
    const { t } = useTranslation();
    var loginData = useSelector(state => state.login.token)
    const dispatch =  useDispatch()
 

const handleSubmit = ()=>{
    dispatch(resendEmailSlice(loginData?.email))
    dispatch(fetchProfileSlice())
    handleClose();

}
    return (
        <CustomModal
            open={open}
            onClose={() => {
                handleClose();
            }}
            button={true}
            customWidth={"510px"}
            customColor= {"#EC0000"}
        >

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={ErrorImage} width={'250px'} height={'200px'}></img>
            </Box>

            <Typography
                sx={{ textAlign: 'center', paddingTop: '16px', color:'#7C7C7C', fontSize:'16px', fontWeight:'500'}}
            >
                {t('emailVerify')}
            </Typography>

            <Box
                sx={{ display: 'flex', gap: '10px', paddingTop: '20px' }}
            >
                <Button
                    sx={{
                        borderRadius: "8px",
                        width: '100%',
                        padding: "8px 12px",
                        background:'#445A68',
                        fontSize: "16px",
                        color: "#FFF",
                        fontWeight: "500",
                        border: "1px solid #445A68",
                        transition: "all 0.3s ease",
                    }}
                    onClick={() => {
                        handleClose();
                    }}
                >
                    {t('cancel')}
                </Button>

                <Button
                    sx={{
                        color: "#FFF",
                        width: '100%',
                        background:"linear-gradient(0deg, #13BECF 0%, #435D6B 100%)",
                        borderRadius: "8px",
                        padding: "8px 12px",
                        fontSize: "16px",
                        fontWeight: "500",
                        transition: "all 0.3s ease",
                    }}
                    onClick={handleSubmit}
                >
                    {t('resend')}
                </Button>
            </Box>

        </CustomModal>
    )
}

export default EmailAlertModal