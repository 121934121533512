import { Box, CircularProgress } from "@mui/material";

const CustomLoader = ({ loading }) => {
    if (!loading) return null;

    return (
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 9999,
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default CustomLoader;
