import { Box, Button, Grid2, Typography, IconButton, CircularProgress, Avatar, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/CustomBreadcrumb';
import { useNavigate } from 'react-router-dom';
import AddIcon from "@mui/icons-material/Add";
import SortIcon from "@mui/icons-material/Sort";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from 'react-redux';
import { fetchRegisterUsersSlice } from '../../../redux/superAdmin/superAdmin.slice';
import CustomPagination from '../../../components/CustomPagination/CustomPagination';



const useStyles = makeStyles((theme) => ({
  searchBar: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      color: "#2d2d2d",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },

  selectField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF", // Background color
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      color: "#2d2d2d",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#2d2d2d",
    },
  },


  textField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF", // Background color
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      color: "#2d2d2d",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#2d2d2d",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "4px",
}));
const statusStyles = {
  active: {
    backgroundColor: "#e0f7e9",
    color: "#34a853",
  },
  offline: {
    backgroundColor: "#f5f5f5",
    color: "#9e9e9e",
  },
};

const TableHeadTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#455967",
  fontWeight: "500",
  lineHeight: "1.5rem",
}));

const TableDataTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#455967",
  fontWeight: "500",
}));

const UserManagement = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { registerUserData, totalItems, loading } = useSelector(
    (state) => state.superAdmin
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const breadcrumb = [{ title: t('userManagement.title'), href: '' }];


  useEffect(() => {
    dispatch(fetchRegisterUsersSlice({ page, rowsPerPage }));
  }, [dispatch, page, rowsPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };



  return (
    <Box>
      <CustomBreadcrumb breadcrumb={breadcrumb} />

      <Grid2 container mt={3}>

        <Grid2 size={{ xs: 12, md: 8 }}>
          <Typography
            sx={{ color: "#445A68", fontSize: "29px", fontWeight: "500" }}
          >
            {t('userManagement.title')}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }}>
        </Grid2>
      </Grid2>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{
            borderRadius: "40px",
            // padding: "15px 30px",
            color: "#445A68",
            fontSize: "18px",
            fontWeight: "600",
            border: "1px solid #445A68",
          }}
          onClick={() => { navigate('/register') }}
        >
          {t('userManagement.registerButton')}

        </Button>
      </Box>

      <TableContainer
        component={Paper}
        sx={{ mt: 3, border: "1px solid #D4D4D4", borderRadius: "12px" }}
      >
        {
          loading ? (
            <Box display="flex" justifyContent="center" my={3}>
              <CircularProgress />
            </Box>
          ) : (
            <Table aria-label="campaign table">
              <TableHead sx={{ background: '#F1F1F1' }}>
                <TableRow>
                  <TableCell sx={{ minWidth: '30px' }}>
                    <BoxContainer>
                      <TableHeadTitle>
                        {t('userManagement.image')}
                      </TableHeadTitle>
                    </BoxContainer>
                  </TableCell>
                  <TableCell sx={{ minWidth: '170px' }}>
                    <BoxContainer>
                      <TableHeadTitle>
                        {t('userManagement.fullName')}
                      </TableHeadTitle>
                    </BoxContainer>
                  </TableCell>
                  <TableCell sx={{ minWidth: '170px' }}>
                    <BoxContainer>
                      <TableHeadTitle>{t('userManagement.email')}</TableHeadTitle>
                    </BoxContainer>
                  </TableCell>
                  <TableCell sx={{ minWidth: '170px' }}>
                    <BoxContainer>
                      <TableHeadTitle>{t('userManagement.registerAs')}</TableHeadTitle>
                    </BoxContainer>
                    </TableCell>
                  <TableCell sx={{ minWidth: '170px' }}>
                    <BoxContainer>
                      <TableHeadTitle>{t('userManagement.displayName')}</TableHeadTitle>
                    </BoxContainer>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {registerUserData?.content?.map((item, index) => {

                  return (
                    <StyledTableRow key={index} role="checkbox">
                      <TableCell>
                        <TableDataTitle>
                          <Avatar alt="Remy Sharp" src={item?.imageUrl} />
                        </TableDataTitle>
                      </TableCell>
                      <TableCell>
                        <TableDataTitle>{item?.firstName}{" "}{item?.lastName}</TableDataTitle>
                      </TableCell>
                      <TableCell>
                        <TableDataTitle>{item?.email}</TableDataTitle>
                      </TableCell>
                      <TableCell>
                        {/* <TableDataTitle>{item?.groups[0]}</TableDataTitle> */}
                        <TableDataTitle>
                          {item?.groups?.length > 1 ? 'Data Consumer and Data Provider (Both)' : item?.groups[0] === 'DataUser' ? 'Data Consumer' : item?.groups[0] === 'DataProvider' ? 'Data Provider' : '' }
                        </TableDataTitle>
                      </TableCell>
                      <TableCell>
                        <TableDataTitle>{item?.displayName}</TableDataTitle>
                      </TableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          )
        }
      </TableContainer>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* Center CustomPagination */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <CustomPagination
            rowsPerPageOptions={[5, 10, 25]}
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => handlePageChange(newPage)}
            onRowsPerPageChange={(e) =>
              handleRowsPerPageChange(parseInt(e.target.value, 10))
            }
          />
        </Box>

        {/* Typography aligned to the end */}
        <Box
          sx={{
            flex: "0 0 auto",
          }}
        >
          <Typography sx={{
            fontSize: '19px',
            fontWeight: '600',
            background: 'linear-gradient(180deg, #13BECF 0%, #455967 100%)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
          }}>
            {page * rowsPerPage + 1}-{Math.min((page + 1) * rowsPerPage, totalItems)} <span style={{ color: '#455967' }}>of {totalItems}</span>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default UserManagement