import React, { useEffect, useState, useCallback } from "react";
import { useRef } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import {
  fetchPrimarySelectSlice,
  fetchPrimaryOptionsSlice,
  fetchSecondarySelectSlice,
  fetchSecondaryOptionsSlice,
  fetchDiscoverIdSlice,
  fetchBudgetScheduleSlice,
  CampaignListEditSlice,
  // BudgetManagementSlice,
} from "../../../redux/stepperSlice/stepper.slice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import {
  setDiscoverFields,
  socketCounts,
} from "../../../redux/stepperSlice/stepper.slice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
// import BudgetManagement from "./BudgetManagement";
import { useFormik, validateYupSchema, yupToFormErrors } from "formik";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const AudienceManager = ({
  handleChange,
  formValues,
  classes,
  prevStep,
  handleNext,
  setAudienceData,
  setFormValues,
  campaignId,
  t,
  prevSocketId,
  setPrevSocketId
}) => {
  const dispatch = useDispatch();
  const audienceData = useSelector((state) => state.stepper);
  const [attributes, setAttributes] = useState([]);
  const [primaryOptions, setPrimaryOptions] = useState({});
  const [secondaryOptions, setSecondaryOptions] = useState({});
  const [secondaryAttributes, setSecondaryAttributes] = useState([]);
  const [loadingPrimary, setLoadingPrimary] = useState(false);
  const [loadingSecondary, setLoadingSecondary] = useState(false);
  const [socket, setSocket] = useState(null);
  // const [prevSocketId, setPrevSocketId] = useState(null);
  const [allAttributes, setAllAttributes] = useState([]);
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [loadingState, setLoadingState] = useState({});
  const { discoverFields, socketCountsData } = useSelector(
    (state) => state.stepper
  );
  const ws_url = process.env.REACT_APP_WS_QA_IP;
  const [exceeded, setExceeded] = useState(false);
  const wsRef = useRef(new Map()); // Use a Map to store WebSocket connections

  const setupWebSocket = useCallback((socketId, attributeCode) => {
    if (wsRef.current.has(socketId)) {
      console.warn(`WebSocket for ${socketId} already exists.`);
      return; // Prevent creating multiple connections for the same socketId
    }

    const ws = new WebSocket(`${ws_url}${socketId}`);
    wsRef.current.set(socketId, ws);
    ws.onmessage = (event) => {
      const newValue = event.data;

      // setLoadingState((prevState) => ({
      //   ...prevState,
      //   [attributeCode]: false,
      // }));

      dispatch(
        socketCounts({
          [attributeCode]: newValue,
        })
      );

      setTimeout(() => {
        setLoadingState((prevState) => ({
          ...prevState,
          [attributeCode]: false,
        }));
      }, 100); // Small delay to ensure state update order

    };



    ws.onopen = () =>
      console.log(`WebSocket connection opened for first ${socketId}.`);
    ws.onclose = () => {
      console.log(`WebSocket connection closed for second ${socketId}.`);
      wsRef.current.delete(socketId); // Remove closed WebSocket from Map
    };


    return ws;
  }, []);

  // Cleanup WebSockets on unmount
  useEffect(() => {
    return () => {
      wsRef.current.forEach((ws) => ws?.close());
      wsRef.current.clear();
    };
  }, []);

  // Example usage of setupWebSocket
  useEffect(() => {
    const socketId = prevSocketId; // Replace with actual socketId
    const attributeCode = "your_attribute_code"; // Replace with actual attributeCode
    const ws = setupWebSocket(socketId, attributeCode);

    return () => {
      ws?.close(); // Close the specific WebSocket on unmount
      wsRef.current.delete(socketId); // Ensure it's removed from the Map
    };
  }, [setupWebSocket]);


  useEffect(() => {
    const fetchAttributes = async () => {
      setLoadingSecondary(true);
      try {
        await dispatch(fetchSecondarySelectSlice()).unwrap();
      } catch (error) {
        console.error("Failed to fetch secondary attributes:", error);
      } finally {
        setLoadingSecondary(false);
      }
    };

    fetchAttributes();
  }, [dispatch]);

  // Update attributes and options once fetched
  useEffect(() => {
    if (audienceData?.primary) {
      setAttributes(audienceData.primary);
    }
    if (audienceData?.secondary) {
      setSecondaryAttributes(audienceData.secondary || []);
    }
  }, [audienceData]);

  const fetchSecondaryOptions = useCallback(
    async (attributeCode) => {
      if (secondaryOptions[attributeCode]) return;
      try {
        await dispatch(fetchSecondaryOptionsSlice(attributeCode)).unwrap();
        const fetchedOptions = audienceData.secondaryOptions[attributeCode];
        setSecondaryOptions((prev) => ({
          ...prev,
          [attributeCode]: fetchedOptions,
        }));
      } catch (error) {
        console.error("Failed to fetch secondary options:", error);
      }
    },
    [audienceData.secondaryOptions, dispatch, secondaryOptions]
  );

  const handleAutocompleteChange = useCallback(
    (attributeCode, type, attributeName) => async (event, newValue) => {
      handleChange({
        target: {
          name: attributeCode,
          value: newValue,
        },
      });

      const updatedFormValues = {
        ...formValues,
        [attributeCode]: newValue,
      };

      setFormValues(updatedFormValues);
      const newAttributes = newValue.map((value) => ({
        code: attributeCode,
        value: value,
        type: type,
        name: attributeName,
      }));

      const updateData = {
        name: attributeCode,
        value: newValue,
        type: type,
      };
      const updatedAttributes = [
        ...allAttributes.filter((attr) => attr.name !== attributeCode),
        ...newAttributes, // Add the new values
      ];

      setAllAttributes(updatedAttributes);

      const data = { attributes: [updateData] };

      const response = await dispatch(fetchDiscoverIdSlice(data)).unwrap();
      setPrevSocketId(response.requestId);
      const newSocketId = response.requestId;
      const ws = setupWebSocket(newSocketId, attributeCode);

      const updatedDiscoverFields = [
        ...discoverFields.filter((attr) => attr.name !== attributeCode),
        ...newAttributes,
      ];

      setDiscoverFields(updatedDiscoverFields);
      dispatch(setDiscoverFields(updatedDiscoverFields));
      setAudienceData(data);
    },
    [
      handleChange,
      allAttributes,
      formValues,
      dispatch,
      setPrevSocketId,
      setSocket,
      discoverFields,
      setupWebSocket,
    ]
  );

  useEffect(() => {
    const fetchAllSecondaryOptions = async () => {
      try {
        const fetchPromises = secondaryAttributes.map((attribute) =>
          fetchSecondaryOptions(attribute.attributeName)
        );
        await Promise.all(fetchPromises);
      } catch (error) {
        console.error("Error fetching secondary options:", error);
      }
    };

    if (secondaryAttributes.length > 0) {
      fetchAllSecondaryOptions();
    }
  }, [secondaryAttributes]);

  useEffect(() => {
    if (formValues && formValues.Parentattributes) {
      setFilteredAttributes(formValues?.Parentattributes);
    }
  }, [formValues]);


  // const handleDeleteChip = (attributeCode, optionToDelete) => {

  //   setFormValues((prevValues) => {
  //     const updatedValues = { ...prevValues };

  //     // Check if the attribute is of type Range
  //     const isRangeAttribute = filteredAttributes.some(
  //       (attr) =>
  //         attr.attributeCode === attributeCode && attr.attributeType === "Range"
  //     );

  //     if (isRangeAttribute) {
  //       // Reset the entire array for Range attributes
  //       updatedValues[attributeCode] = ["", ""];
  //     } else {
  //       // Reset the value for non-Range attributes
  //       updatedValues[attributeCode] = prevValues[attributeCode].filter(
  //         (option) => option !== optionToDelete
  //       );

  //       const updateData = {
  //         name: attributeCode,
  //         value: updatedValues[attributeCode], // Pass the updated value without deleted option
  //      };

  //      const data = { attributes: [updateData] };

  //       const response =  dispatch(fetchDiscoverIdSlice(data)).unwrap();
  //       setPrevSocketId(response.requestId);
  //       const newSocketId = response.requestId;

  //       if (newSocketId) {
  //          setupWebSocket(newSocketId, attributeCode);
  //       }
  //     }
  //   console.log('delete--------------chip',updatedValues[attributeCode])

  //     return updatedValues;
  //   });


  //   // Handle socket counts if applicable
  //   if (optionToDelete && optionToDelete.attributeCode) {
  //     const attributeCodeToCheck = optionToDelete.attributeCode;
  //     if (socketCountsData.hasOwnProperty(attributeCodeToCheck)) {
  //       const updatedSocketCounts = { ...socketCountsData };
  //       updatedSocketCounts[attributeCodeToCheck] = 0;
  //       dispatch(socketCounts(updatedSocketCounts));
  //     }
  //   }
  // };

  const handleDeleteChip = async (attributeCode, optionToDelete) => {
 var updateAttrbutesValue
    setLoadingState((prev) => ({
      ...prev,
      [attributeCode]: true,
   }));
    setFormValues((prevValues) => {
       const updatedValues = { ...prevValues };

       // Check if the attribute is of type Range
       const isRangeAttribute = filteredAttributes.some(
          (attr) => attr.attributeCode === attributeCode && attr.attributeType === "Range"
       );

       if (isRangeAttribute) {
          // Reset the entire array for Range attributes
          updatedValues[attributeCode] = ["", ""];
       } else {
          // Remove the selected option
          updatedValues[attributeCode] = prevValues[attributeCode]?.filter(
             (option) => option !== optionToDelete
          ) || [];

          updateAttrbutesValue = updatedValues[attributeCode]
       }



       return updatedValues;
    });

    // Wait for state update before calling API
    setTimeout(async () => {
       const updateData = {
          name: attributeCode,
          value: updateAttrbutesValue, // Use updated formValues
       };

       const data = { attributes: [updateData] };

       try {
          const response = await dispatch(fetchDiscoverIdSlice(data)).unwrap();
          setPrevSocketId(response.requestId);
          const newSocketId = response.requestId;

          if (newSocketId) {
             setupWebSocket(newSocketId, attributeCode);
          }

       } catch (error) {
          console.error("Error in fetchDiscoverIdSlice:", error);
       }
    }, 0);

    // Handle socket counts if applicable
    if (optionToDelete && optionToDelete.attributeCode) {
       const attributeCodeToCheck = optionToDelete.attributeCode;
       if (socketCountsData.hasOwnProperty(attributeCodeToCheck)) {
          const updatedSocketCounts = { ...socketCountsData };
          updatedSocketCounts[attributeCodeToCheck] = 0;
          dispatch(socketCounts(updatedSocketCounts));
       }
    }
 };

  useEffect(() => {
    if (filteredAttributes.length === 0) {
      dispatch(setDiscoverFields([]));
      dispatch(socketCounts({}));
      setLoadingState({});
    }
  }, [filteredAttributes]);

  const handleAccordionChange = () => {
    setExpanded((prev) => !prev);
  };

  // const createValidationSchema = (values) => {
  //   const baseSchema = {
  //     Parentattributes: Yup.array()
  //       .min(1, t("validate.atLeastOneAttributeReq"))
  //       .required(t("validate.attributeRequired")),
  //   };

  //   // Add dynamic validations for fields based on current values
  //   (values.Parentattributes || []).forEach((attr) => {
  //     if (attr.attributeCode) {
  //       baseSchema[attr.attributeCode] = Yup.array()
  //         .min(1, `${attr.attributeName} should not be empty`)
  //         .required(`${attr.attributeName} is required`);
  //     }
  //   });

  //   return Yup.object(baseSchema);
  // };

  const createValidationSchema = (values) => {

    const baseSchema = {
      Parentattributes: Yup.array()
        .min(1, "At least one attribute is required")
        .required("Attributes are required"),
    };

    (values.Parentattributes || []).forEach((attr) => {
      if (attr.attributeCode) {
        if (attr.attributeType === "Range") {
          baseSchema[attr.attributeCode] = Yup.array()
            .of(
              Yup.number()
                .typeError("Must be a number")
                .required("This field is required")
            )
            .test(
              "min-less-than-max",
              "Max must be greater than Min",
              function (value) {
                if (!value || value.length < 2) return true;
                const [min, max] = value;

                if (Number(min) >= Number(max)) {
                  return (
                    this.createError({
                      path: `${this.path}[0]`,
                      message: "Min value must be less than Max",
                    }) ||
                    this.createError({
                      path: `${this.path}[1]`,
                      message: "Max value must be greater than Min",
                    })
                  );
                }

                return true;
              }
            )
            .test(
              "valid-min",
              "Min value is required",
              (value) => value?.[0] !== undefined && value?.[0] !== ""
            )
            .test(
              "valid-max",
              "Max value is required",
              (value) => value?.[1] !== undefined && value?.[1] !== ""
            );
        } else {
          baseSchema[attr.attributeCode] = Yup.array()
            .min(1, `${attr.attributeName} should not be empty`)
            .required(`${attr.attributeName} is required`);
        }
      }
    });

    return Yup.object().shape(baseSchema);
  };

  const validate = (values) => {
    const errors = {};

    try {
      const schema = createValidationSchema(values);
      validateYupSchema(values, schema, true, values);
    } catch (err) {
      Object.assign(errors, yupToFormErrors(err));
    }

    return errors;
  };

  const handleSubmit = (values) => {
    handleNext();
  };

  const audienceDraftHandler = async () => {
    const attributeStatusList = [];

    // Iterate through Parentattributes and create matching attributeStatusList objects
    formValues.Parentattributes.forEach((attribute) => {
      const possibleValuesKey = attribute.attributeCode;

      // Check if the corresponding key exists in the main object
      if (formValues[possibleValuesKey]) {
        // Extract the possible values directly and assign them to the list
        attributeStatusList.push({
          name: attribute.attributeName,
          orgId: attribute.bridgeId,
          status: "pending", // Assuming status is pending by default
          attributeId: attribute.id,
          attributeCode: attribute.attributeCode,
          possibleValues: formValues[possibleValuesKey], // Directly using the possible values array
        });
      }
    });
    const updateData = { ...formValues, attributeStatusList };

    const result = await dispatch(
      CampaignListEditSlice({ data: updateData, campaignId })
    ).unwrap();
    if (result !== 400) {
      toast.success("Attributes save as draft");
      prevStep();
    } else {
      toast.error("You Enter something wrong input");
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Box sx={{ marginTop: "30px", width: "100%", marginX: "30px" }}>
          <Formik
            initialValues={{
              ...formValues,
              Parentattributes: filteredAttributes,
            }}
            validate={validate}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values, errors, touched, setFieldTouched }) => (
              <Form>
                <Accordion
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "10px !important",
                    boxShadow: "none",
                    overflow: "hidden",
                    boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
                    marginBottom: "25px !important",
                    pointerEvents: "none",
                    cursor: "default",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      minHeight: "65px",
                      color: "#333333",
                      fontWeight: "500",
                      fontSize: "19px",
                      borderTopLeftRadius: "15px !important",
                      borderTopRightRadius: "15px !important",
                      borderRadius: "10px",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                    >
                      <CheckCircleIcon
                        sx={{
                          fill: "#13BECF",
                          borderRadius: "50%",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                      {t("stepper.audienceManager.campaignDetails")}
                    </Box>
                  </AccordionSummary>
                </Accordion>

                <Accordion
                  defaultExpanded
                  onChange={handleAccordionChange}
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "10px !important",
                    boxShadow: "none",
                    overflow: "hidden",
                    boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
                    marginBottom: "25px !important",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      minHeight: "65px ",
                      color: "#333333",
                      fontWeight: "500",
                      fontSize: "19px",
                      borderTopLeftRadius: "15px !important",
                      borderTopRightRadius: "15px !important",
                      borderRadius: "10px",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                    >
                      {expanded ? (
                        <CheckCircleIcon
                          sx={{
                            fill: "#13BECF",
                            borderRadius: "50%",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon
                          sx={{
                            fill: "#13BECF",
                            borderRadius: "50%",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                      )}
                      {t("stepper.audienceManager.audienceManager")}
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      sx={{ marginLeft: 1 }}
                      className={classes.additionInfo}
                    >
                      {t("stepper.audienceManager.addParamters")}
                    </Typography>

                    <Autocomplete
                      multiple
                      options={secondaryAttributes}
                      getOptionLabel={(option) => option.attributeName}
                      value={filteredAttributes}
                      onChange={(event, newValue) => {
                        // Find attributes that were removed
                        const removedAttributes = filteredAttributes.filter(
                          (attr) => !newValue.includes(attr)
                        );

                        const addedAttributes = newValue.filter(
                          (attr) => !filteredAttributes.includes(attr)
                        );

                        // Handle removed attributes
                        removedAttributes.forEach((attr) => {
                          setFormValues((prevValues) => {
                            const updatedValues = { ...prevValues };

                            if (attr.attributeType === "Range") {
                              // Remove the entire array for Range attributes
                              delete updatedValues[attr.attributeCode];
                            } else {
                              // Reset the value for non-Range attributes
                              updatedValues[attr.attributeCode] = [];
                            }

                            return updatedValues;
                          });
                        });

                        // Handle added attributes
                        addedAttributes.forEach((attr) => {
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            [attr.attributeCode]:
                              attr.attributeType === "Range" ? ["", ""] : [],
                          }));
                        });

                        // Update filteredAttributes and Parentattributes
                        setFilteredAttributes(newValue);
                        setFieldValue("Parentattributes", newValue);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          Parentattributes: newValue,
                        }));
                      }}
                      inputValue={searchInputValue}
                      onInputChange={(event, newInputValue) =>
                        setSearchInputValue(newInputValue)
                      } // Update search input without clearing selected values
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={t(
                            "stepper.audienceManager.searchSelectAttributes"
                          )}
                          placeholder={t(
                            "stepper.audienceManager.typeAttributes"
                          )}
                          sx={{ marginTop: "15px" }}
                        />
                      )}
                      renderTags={() => null} // Prevent default rendering of tags
                    />

                    {touched.Parentattributes && errors.Parentattributes && (
                      <div style={{ color: "red", marginTop: "10px" }}>
                        {errors.Parentattributes}
                      </div>
                    )}

                    {/* Display Selected Chips */}
                    <Box mt={1} display="flex" flexWrap="wrap" gap={1}>
                      {filteredAttributes.map((option, index) => (
                        <Chip
                          key={index}
                          label={option.attributeName} // Use attributeName for display
                          onDelete={() => {
                            handleDeleteChip("Parentattributes", option);
                            // setFieldValue('Parentattributes', filteredAttributes.length > 0 ? filteredAttributes : [] )
                          }}
                          sx={{
                            borderRadius: "18px",
                            marginBottom: "15px",
                            background: "#445A68E5",
                            color: "#fff",
                            "& .MuiChip-deleteIcon": {
                              color: "white",
                              fontSize: "16px",
                            },
                            "& .MuiChip-deleteIcon:hover": {
                              backgroundColor: "transparent",
                              color: "white",
                            },
                          }}
                        />
                      ))}
                    </Box>

                    {loadingSecondary ? (
                      <Typography variant="body1" color="textSecondary">
                        {t("stepper.audienceManager.loadingSecAttributes")}
                      </Typography>
                    ) : (
                      filteredAttributes.map((attribute) => (
                        <Grid item xs={12} key={attribute.id} mt={2}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography className={classes.label}>
                              {attribute.attributeName}
                            </Typography>

                            {/* Audience count on the right */}
                            <Typography
                              variant="h5"
                              sx={{
                                fontWeight: 400,
                                fontSize: "18px",
                                fontStyle: "italic",
                                color: "#00ADEB",
                                marginRight: "10px", // Adds space to the right
                              }}
                            >
                              {attribute.attributeName} Count:{" "}
                              {loadingState[attribute.attributeCode] ? (
                                <CircularProgress size={15} />
                              ) : socketCountsData[attribute?.attributeCode] ? (
                                socketCountsData[attribute?.attributeCode]
                              ) : (
                                "0"
                              )}
                            </Typography>
                          </Box>

                          {attribute.attributeType === "Range" ? (
                            <>
                              <Box display="flex" gap={2} mt={1}>
                                <TextField
                                  name={`${attribute.attributeCode}[0]`} // Min value
                                  value={
                                    formValues[attribute.attributeCode]?.[0] ||
                                    ""
                                  }
                                  placeholder="Min Value"
                                  onChange={(e) => {
                                    const newMinValue = e.target.value;

                                    setFormValues((prevValues) => ({
                                      ...prevValues,
                                      [attribute.attributeCode]: [
                                        newMinValue, // Update min value
                                        prevValues[
                                          attribute.attributeCode
                                        ]?.[1] || "", // Keep max value unchanged
                                      ],
                                    }));
                                    setFieldValue(attribute.attributeCode, [
                                      newMinValue,
                                      formValues[
                                        attribute.attributeCode
                                      ]?.[1] || "",
                                    ]);
                                    setFieldTouched(
                                      attribute.attributeCode,
                                      true,
                                      false
                                    );
                                  }}
                                  className={classes.textField}
                                  error={
                                    touched[attribute.attributeCode] &&
                                    Boolean(
                                      errors[attribute.attributeCode]?.[0]
                                    )
                                  }
                                  helperText={
                                    touched[attribute.attributeCode] &&
                                    errors[attribute.attributeCode]?.[0]
                                  }
                                />

                                <TextField
                                  name={`${attribute.attributeCode}[1]`} // Max value
                                  value={
                                    formValues[attribute.attributeCode]?.[1] ||
                                    ""
                                  }
                                  placeholder="Max Value"
                                  onChange={(e) => {
                                    const newMaxValue = e.target.value;

                                    setFormValues((prevValues) => ({
                                      ...prevValues,
                                      [attribute.attributeCode]: [
                                        prevValues[
                                          attribute.attributeCode
                                        ]?.[0] || "", // Keep min value unchanged
                                        newMaxValue, // Update max value
                                      ],
                                    }));
                                    setFieldValue(attribute.attributeCode, [
                                      formValues[
                                        attribute.attributeCode
                                      ]?.[0] || "",
                                      newMaxValue,
                                    ]);
                                    setFieldTouched(
                                      attribute.attributeCode,
                                      true,
                                      false
                                    );
                                  }}
                                  className={classes.textField}
                                  error={
                                    touched[attribute.attributeCode] &&
                                    Boolean(
                                      errors[attribute.attributeCode]?.[1]
                                    )
                                  }
                                  helperText={
                                    touched[attribute.attributeCode] &&
                                    errors[attribute.attributeCode]?.[1]
                                  }
                                />
                              </Box>
                            </>
                          ) : (
                            <>
                              <Autocomplete
                                multiple
                                options={
                                  secondaryOptions[attribute.attributeCode] ||
                                  []
                                } // Options specific to the attribute
                                getOptionLabel={(option) =>
                                  option.name || option
                                }
                                value={
                                  formValues[attribute.attributeCode] || []
                                } // Controlled value
                                onChange={handleAutocompleteChange(
                                  attribute.attributeCode,
                                  attribute.type,
                                  attribute.attributeName
                                )} // Update form values
                                onOpen={() => {
                                  setLoadingState((prevState) => ({
                                    ...prevState,
                                    [attribute.attributeCode]: true,
                                  }));
                                  fetchSecondaryOptions(
                                    attribute.attributeName
                                  );
                                  setFieldValue(
                                    `${attribute.attributeCode}`,
                                    attribute.attributeName
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    className={classes.textField}
                                    placeholder={`${t(
                                      "stepper.audienceManager.selectOptionsfor"
                                    )} ${attribute.attributeName}`}
                                  />
                                )}
                                renderTags={() => null}
                              />

                              {touched[attribute?.attributeCode] &&
                                errors[attribute?.attributeCode] && (
                                  <div
                                    style={{ color: "red", marginTop: "10px" }}
                                  >
                                    {errors[attribute?.attributeCode]}
                                  </div>
                                )}

                              <Box
                                mt={1}
                                display="flex"
                                flexWrap="wrap"
                                gap={1}
                              >
                                {formValues[attribute.attributeCode]?.map(
                                  (option, index) => (
                                    <Chip
                                      key={index}
                                      label={option.name || option}
                                      onDelete={() =>
                                        handleDeleteChip(
                                          attribute.attributeCode,
                                          option
                                        )
                                      }
                                      sx={{
                                        borderRadius: "18px",
                                        marginBottom: "15px",
                                        background: "#445A68E5",
                                        color: "#fff",
                                        color: "#fff",
                                        "& .MuiChip-deleteIcon": {
                                          color: "white",
                                          fontSize: "16px",
                                        },
                                        "& .MuiChip-deleteIcon:hover": {
                                          backgroundColor: "transparent",
                                          color: "white",
                                        },
                                      }}
                                    />
                                  )
                                )}
                              </Box>
                            </>
                          )}
                        </Grid>
                      ))
                    )}
                  </AccordionDetails>
                </Accordion>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "15px",
                    paddingY: "10px",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={audienceDraftHandler}
                    // startIcon={<ArrowBackIcon />}
                    sx={{
                      borderRadius: "40px",
                      backgroundColor: "#445A68",
                      color: "#FFFFFF",
                      padding: "16px 24px",
                      fontSize: "18px",
                      fontWeight: "600",
                      border: "1px solid rgba(68, 90, 104, 1)",
                    }}
                  >
                    {t("stepper.saveForDraft")}
                  </Button>
                  <Button
                    variant="outlined"
                    type="submit"
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                      borderRadius: "40px",
                      padding: "16px 24px",
                      color: "#FFFFFF",
                      background:
                        "linear-gradient(0deg, #13BECF 0%, #435D6B 100%)",
                      fontSize: "18px",
                      fontWeight: "600",
                      border: "1px solid #13BECF",
                    }}
                  >
                    {t("stepper.saveAndNext")}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Grid>
    </>
  );
};

export default AudienceManager;
