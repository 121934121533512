import React, { useState } from "react";
import WhatsAppDemoImage from "../../../assets/Vector_1.svg";
import Attach from "../../../assets/Attach.svg";
import Media from "../../../assets/media.svg";
import Menu from "../../../assets/menu.svg";
import MicroPhone from "../../../assets/Microphone.svg";
import Phone from "../../../assets/Phone.svg";
import Profile from "../../../assets/Profile.svg";
import Smiley from "../../../assets/smiley.svg";
import Video from "../../../assets/Video.svg";
import Back from "../../../assets/Back Button.svg";
import ReplyIcon from "@mui/icons-material/Reply";
import { FaArrowLeft, FaUserCircle, FaPaperPlane } from "react-icons/fa";
import "../../CreateTemplate/SMSChatInterface.css";

const TemplateViewScreen = ({ tempType, templateData, centered, t }) => {
    const characterLimit = 150;
    const whatsAppData = templateData?.components || []; // Fallback to an empty array if undefined
    const [isExpanded, setIsExpanded] = useState(false);

    const renderText = () => {
        const text = whatsAppData[1]?.text || ""; // Fallback to an empty string if undefined
        if (isExpanded) {
            return text;
        }
        return text.length > characterLimit ? `${text.substring(0, characterLimit)}...` : text;
    };

    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            {tempType === "WHATSAPP" || tempType === "Whatsapp" ? (
                <div className="chat-container" style={{ margin: centered ? "auto" : "" }}>
                    {/* Chat Header */}
                    <div className="chat-header">
                        <div className="header-left">
                            <button className="back-button">
                                <img src={Back} alt="Back" />
                            </button>
                            <img src={Profile} alt="profile" className="profile-image" />
                            <div className="profile-info">
                                <div className="profile-name">AIonos DXE Team</div>
                                <div className="status">Online</div>
                            </div>
                        </div>
                        <div className="header-icons">
                            <button className="icon-button">
                                <img src={Video} alt="Video" />
                            </button>
                            <button className="icon-button">
                                <img src={Phone} alt="Phone" />
                            </button>
                            <button className="icon-button">
                                <img src={Menu} alt="Menu" />
                            </button>
                        </div>
                    </div>

                    {/* Chat Content */}
                    <div className="chat-content">
                        <div className="message-bubble">
                            {/* Placeholder Image */}
                            {/* <div className="message-image-placeholder">
                                <img src={WhatsAppDemoImage} alt="Attachment" className="message-image" />
                            </div> */}

                            {whatsAppData[0]?.text && (
                                <p>
                                    {whatsAppData[0]?.text}
                                </p>
                            )}

                            {/* Text Message */}
                            {whatsAppData[1]?.text && (
                                // <p className={`message-text ${isExpanded ? "expanded" : "collapsed"}`}>
                                //     {renderText()}
                                // </p>
                                <p className={`message-text`}>
                                    {whatsAppData[1]?.text}
                                </p>
                            )}
                            {/* {whatsAppData[1]?.text?.length > characterLimit && (
                                <button className="read-more-button" onClick={handleToggleExpand}>
                                    {isExpanded ? "Read Less" : "Read More"}
                                </button>
                            )} */}

                            {/* Footer Text */}
                            {whatsAppData[2]?.text && <p className="footer-text">{whatsAppData[2].text}</p>}

                            {/* Buttons */}
                            {whatsAppData[3]?.buttons?.length > 0 && (
                                <div className="message-links">
                                    {whatsAppData[3].buttons.map((item, index) => (
                                        <a
                                            key={index}
                                            href={item?.url || "#"}
                                            className="learn-more-link"
                                            style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "2px" }}
                                        >
                                            <ReplyIcon />
                                            {item?.text || "Learn More"}
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Message Input */}
                    <div className="message-input-container">
                        <div className="input">
                            <span className="prefix">
                                <img src={Smiley} alt="Smiley" />
                            </span>
                            <input placeholder="Type a message" />
                            <span className="suffix">
                                <img src={Attach} alt="Attach" />
                            </span>
                            <span className="suffix">
                                <img src={Media} alt="Media" />
                            </span>
                        </div>
                        <button className="btn">
                            <img src={MicroPhone} alt="Microphone" />
                        </button>
                    </div>
                </div>
            ) : (
                /* SMS Chat Interface */
                <div className="sms-chat-container" style={{ margin: "auto" }}>
                    {/* Header */}
                    <div className="sms-chat-header">
                        <button className="back-button">
                            <FaArrowLeft size={18} color="#fff" />
                        </button>
                        <FaUserCircle size={40} color="#fff" className="profile-icon" />
                        <div className="header-info">
                            <div className="contact-name">AIonos DXE Team</div>
                            <div className="status">Online</div>
                        </div>
                    </div>

                    {/* Chat Content */}
                    <div className="sms-chat-content">
                        {templateData?.templateName && (
                            <div className="message-bubble incoming">
                                <p className="message-text">{templateData.templateName}</p>
                            </div>
                        )}
                        {templateData?.templateId && (
                            <div className="message-bubble incoming">
                                <p className="message-text">{templateData.templateId}</p>
                            </div>
                        )}
                        {templateData?.templateText && (
                            <div className="message-bubble incoming">
                                <p className="message-text">{templateData.templateText}</p>
                            </div>
                        )}
                        {templateData?.templateCategory && (
                            <div className="message-bubble incoming">
                                <p className="message-text">{templateData.templateCategory}</p>
                            </div>
                        )}
                    </div>

                    {/* Message Input */}
                    <div className="sms-message-input">
                        <input type="text" placeholder="Type a message" />
                        <button className="send-button">
                            <FaPaperPlane size={18} color="#007AFF" />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default TemplateViewScreen