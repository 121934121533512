// import React from "react";
// import { Provider } from "react-redux";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Updated import
// import store from "./root/store";
// import Login from "./pages/login";
// import MainLayout from "./MainLayout";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import './Global.css'
// import DashboardCard from "./pages/dashboard";
// import NewCampaign from "./pages/NewCampaign/Sections/NewCampaign";
// import CreateCampaign from "./components/stepper";
// import AnalyticsPage from "./pages/analytics";
// import CampaignList from "./pages/campaignList";

// const App = () => {
//   return (
//     <Provider store={store}>
//       <Router>
//         <ToastContainer />
//         <Routes> {/* Updated usage */}
//           <Route path="/login" element={<Login />} />
//           <Route path="/" element={<Login />} />

//           <Route element={<MainLayout />}>
//           <Route path="/dashboard" element={<DashboardCard />} />
//           <Route path="/analytics" element={<AnalyticsPage />} />
//           <Route path="/campaign-list" element={<CampaignList />} />
//           <Route path="/create-campaign" element={<CreateCampaign />} />
//           </Route>
//         </Routes>
//       </Router>
//     </Provider>
//   );
// };

// export default App;

import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import store, { persistor } from "./root/store"; // Import persistor
import Login from "./pages/login";
import MainLayout from "./MainLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Global.css";
import Dashboard from "./pages/dashboard";
// import CreateCampaign from "./components/stepper";
import AnalyticsPage from "./pages/analytics";
import CampaignList from "./pages/campaignList";
import ContentLibrary from "./pages/ContentLibrary/ContentLibrary";
import Templatelist from "./pages/CreateTemplate/TemplateList";
import DataRequest from "./pages/DataRequest";
import NotificationPreview from "./pages/NotificationPreview/NotificationPreview";
import NotFound from "./pages/notFound";
import ViewDataRequest from "./pages/DataRequest/Components/ViewDataRequest";
import DataPermission from "./pages/DataPermission/DataPermission";
import DataRequestTest from "./pages/DataRequest/Components/DataRequestTest";
import ForgotPassword from "./pages/forgotPassword";
import Demopage from "./pages/DemoPage/Demopage";
import CreateTemplate from "./pages/CreateTemplate/CreateTemplate";
import CreateTemplateFinal from "./pages/CreateTemplate/CreateTemplateFinal";
import CreateTemplateOld from "./pages/CreateTemplate/CreateTemplateOld";
import ChooseTemplate from "./pages/CreateTemplate/ChooseTemplate";
import CreateContent from "./pages/ContentLibrary/Components/CreateContent";
import IpificationLogin from "./pages/DemoPage/Component/IpificationLogin";
import LoginSuccessPage from "./pages/DemoPage/Component/LoginSuccessPage";
import Demos from "./pages/Demos";
import DataProvider from "./pages/SuperAdmin/DataProvider/DataProvider";
import DataConsumer from "./pages/SuperAdmin/DataConsumer/DataConsumer";
import Attributes from "./pages/SuperAdmin/Attributes/Attributes";
import Pricing from "./pages/SuperAdmin/Pricing/Pricing";
import UserManagement from "./pages/SuperAdmin/UserManagement/UserManagement";
import RegisterPage from "./pages/SuperAdmin/Register/registerPage";
import EditConsumer from "./pages/EditConsumer/EditConsumer";
import AddAttributes from "./pages/SuperAdmin/Attributes/AddAttributes";
import { useLocation } from "react-router-dom";
import RegisterDetails from "./pages/SuperAdmin/Register/Components/RegisterDetails";
import AddPricing from "./pages/SuperAdmin/Pricing/AddPricing";
import UpdatePricing from "./pages/SuperAdmin/Pricing/UpdatePricing";
import EditCampaign from "./pages/NewCampaign/Sections/EditCampaign/EditCampaign";
import CreateCampaign from "./pages/NewCampaign/Sections/CreateCampaign/CreateCampaign";
import PriceTrends from "./pages/PriceTrends/PriceTrends";

// Create a function to check if the user is authenticated
const isAuthenticated = () => {
  const token = localStorage.getItem("idToken"); // Check for token or your method of authentication
  return !!token; // Returns true if token exists
};

// Create PrivateRoute component
const PrivateRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/login" replace />; // Redirect to login if not authenticated
};

const App = () => {
  return (
    <Provider store={store}>
      {/* PersistGate delays rendering until the persisted state has been rehydrated */}
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ToastContainer />
          <Routes>
            {" "}
            {/* Updated usage */}
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
            <Route path="/login-ipification" element={<IpificationLogin />} />
            <Route path="/ipification" element={<Demopage />} />
            <Route path="/login-success-page" element={<LoginSuccessPage />} />
            {/* Protect the MainLayout routes */}
            <Route element={<PrivateRoute element={<MainLayout />} />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/analytics" element={<AnalyticsPage />} />
              <Route path="/campaign-list" element={<CampaignList />} />
              {/* <Route path="/create-campaign" element={<CreateCampaign />} /> */}
              <Route path="/template-list" element={<Templatelist />} />
              <Route path="/create-campaign" element={<CreateCampaign />} />
              <Route path="/create-campaign/:id" element={<EditCampaign/>} />
              {/* <Route path="/content-library" element={<ContentLibrary />} /> */}
              {/* <Route path="/create-content" element={<CreateContent />} /> */}
              <Route path="/demos" element={<Demos />} />
              {/* <Route path="/data-request" element={<DataRequest />} /> */}
              <Route path="/notification-preview" element={<NotificationPreview />} />
              <Route path="/viewDataRequest" element={<ViewDataRequest/>}/>
              <Route path="/data-permission" element={<DataPermission/>}/>
              <Route path="/data-request" element={<DataRequestTest/>}/>
              <Route path="/create-template" element={<ChooseTemplate/>}/>
              <Route path="/create-template-final" element={<CreateTemplateFinal/>}/>
              <Route path="/create-template-old" element={<CreateTemplateOld/>}/>
              <Route path="/data-provider" element={<DataProvider/>}/>
              <Route path="/data-consumer" element={<DataConsumer/>}/>
              <Route path="/attributes" element={<Attributes/>}/>
              <Route path="/add-attributes" element={<AddAttributes/>}/>
              <Route path="/add-pricing" element={<AddPricing/>}/>
              <Route path="/pricing" element={<Pricing/>}/>
              <Route path="/pricing/update/:id" element={<UpdatePricing />} />
              <Route path="/user-management" element={<UserManagement/>}/>
              <Route path="/register" element={<RegisterPage/>}/>
              <Route path="/edit-profile" element={<EditConsumer/>}/>
              <Route path="/register-details" element={<RegisterDetails/>}/>
              <Route path="/provider-dashboard" element={<PriceTrends/>}/>
              <Route path="/ipification1" element={<Demopage />} />
            </Route>
            {/* Add NotFound route for any undefined paths */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
