import { Chip, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  Paper,
  Card,
  Box,
  Typography,
  MenuItem,
  Breadcrumbs,
  Avatar,
  TablePagination,
  CircularProgress,
  IconButton,
  Collapse,
  Grid2,
  TextField,
  Button
} from "@mui/material";

import { toast } from "react-toastify";
import TemplateView from "../pages/NewCampaign/Components/TemplateView";
import CampaignTemplateModal from "./CampaignTemplateModal/CampaignTemplateModal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { CampaignListEditSlice } from "../redux/stepperSlice/stepper.slice";
import { fetchTeamsDetailsSlice } from "../redux/teamSlice/team.slice";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";


const styles = {
  container: (isSmallScreen) => ({
    padding: 2,
    display: "flex",
    justifyContent: "",
    flexWrap: isSmallScreen ? "wrap" : "nowrap",
    flexDirection: isSmallScreen ? "column" : "row",
  }),
  boxWidth: { width: "27%" },
  attributeBox: { width: "50%" },
  labelText: { color: "#455967", fontWeight: "600", fontSize: "16px" },
  valueText: { fontSize: "14px", fontWeight: "400", color: "#455967" },
  chip: {
    borderRadius: "18px",
    background: "#FF8D5D",
    color: "#fff",
    "& .MuiChip-deleteIcon": { color: "white", fontSize: "16px" },
    "& .MuiChip-deleteIcon:hover": {
      backgroundColor: "transparent",
      color: "white",
    },
    margin: "2px",
  },
};





function formatDateNew(epoch) {
  const date = new Date(epoch);

  // Add the IST offset (UTC +5:30) to the date
  // const istOffset = 5.5 * 60 * 60 * 1000; // 5 hours 30 minutes in milliseconds
  // const istDate = new Date(date.getTime() + istOffset);
  const istDate = new Date(date.getTime());

  // Format options for the date and time
  const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  return istDate.toLocaleString('en-US', options);
}

const capitalizeStatus = (status) => {
  if (!status) return ''; // Return empty string if no status
  return status.charAt(0).toUpperCase() + status.slice(1);
};

const ResponsiveTableView = ({ openRowIndex, index, viewCampaignDetails, handleExecute, page, rowsPerPage, t, showSpinner }) => {


  // console.log('costPerImpression',viewCampaignDetails)
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const userNotificationData = viewCampaignDetails?.userNotification;
  const [openTemplate, setOpenTemplate] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()


  const handleTemplateView = () => {
    setOpenTemplate(false);

  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpenModal = () => {
    setOpen(true);
  };

  const getAttributeStatus = (attributeName) => {
    const statusItem = viewCampaignDetails?.attributeStatusList?.find(status => status.name === attributeName);
    return statusItem ? statusItem.status : 'Unknown'; // Default to 'Unknown' if no status found
  };

  const totalCost =
    viewCampaignDetails?.costPerImpression && userNotificationData
      ? viewCampaignDetails.costPerImpression * userNotificationData.count
      : 'Data not present';
  return (
    openRowIndex === index && (
      <>

        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={11}
            sx={{ backgroundColor: "#D5F3F666" }}
          >
            {showSpinner ? <Box sx={{ height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress size={40} /></Box> :
              <Collapse in={openRowIndex === index} timeout="auto" unmountOnExit>


                <Box sx={styles.container(isSmallScreen)}>
                  {/* Campaign Information */}
                  <Box sx={styles.boxWidth}>

                    <Box>
                      <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: 'center' }}>
                        <Typography sx={styles.labelText}>{t('campaignList.campaignName')}:</Typography>
                        <Typography sx={styles.valueText}>
                          {viewCampaignDetails?.campaignName}
                        </Typography>
                      </Box>
                    </Box>

                  </Box>

                  <Box sx={styles.boxWidth}>
                    <Typography sx={styles.labelText}>
                      {t('campaignList.channel')}:{" "}
                      <span
                        style={
                          {
                            ...styles.valueText,
                            cursor: viewCampaignDetails?.channel ? 'pointer' : 'default',
                            textDecoration: viewCampaignDetails?.channel ? 'underline' : 'none',
                          }}
                        onClick={() => {
                          if (viewCampaignDetails?.channel) {
                            setOpenTemplate(true)
                          }
                        }
                        }
                      >
                        {viewCampaignDetails?.channel ? viewCampaignDetails?.channel === 'WHATSAPP' ? 'Whatsapp' : viewCampaignDetails?.channel : 'Data not persent'}
                      </span>
                    </Typography>
                  </Box>


                  <Box sx={styles.boxWidth}>


                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center" }}>
                      <Typography sx={styles.labelText}>{t('campaignList.startDate')}:</Typography>
                      <Typography sx={styles.valueText}>
                        {viewCampaignDetails?.startDateEpoch
                          ? formatDateNew(viewCampaignDetails?.startDateEpoch)
                          : "Data not present"}
                      </Typography>
                    </Box>

                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center" }}>
                    <Typography sx={styles.labelText}>{t('campaignList.endDate')}:</Typography>
                    <Typography sx={styles.valueText}>
                      {viewCampaignDetails?.endDateEpoch
                        ? formatDateNew(viewCampaignDetails.endDateEpoch)
                        : "Data not present"}
                    </Typography>
                  </Box>
                </Box>


                <Box sx={styles.container(isSmallScreen)}>

                  <Box sx={styles.boxWidth}>
                    {viewCampaignDetails?.status !== "DRAFT" &&
                      <Box>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: 'center' }}>
                          <Typography sx={styles.labelText}>Cost Per Impression:</Typography>
                          <Typography sx={styles.valueText}>
                            {viewCampaignDetails?.costPerImpression === 'NaN' ? '0' : viewCampaignDetails?.costPerImpression}
                          </Typography>
                        </Box>
                      </Box>
                    }
                  </Box>

                  {(viewCampaignDetails?.status === "Approved" ||
                    viewCampaignDetails?.status === "PartiallyApproved" || viewCampaignDetails?.status === 'Eexecuted') &&
                    viewCampaignDetails?.userNotification && (
                      <Box sx={styles.boxWidth}>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: 'center' }}>
                          <Typography sx={styles.labelText}> Total Cost :</Typography>
                          <Typography sx={styles.valueText}>
                            {viewCampaignDetails?.costPerImpression
                              ? parseFloat(viewCampaignDetails?.costPerImpression * viewCampaignDetails.userNotification.count).toFixed(2)
                              : 'Data not present'}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                </Box>

                <Box sx={styles.container(isSmallScreen)}>

                  {
                    viewCampaignDetails?.attributes?.map((item, index) => {

                      const status = getAttributeStatus(item.name);
                      return (
                        <Box key={index} style={{ marginBottom: "8px" }} sx={styles.attributeBox}>
                          <Typography sx={styles.labelText} display="flex" alignItems="center">
                            {item.name}:
                            {/* Displaying chip values next to the name */}
                            {item?.possibleValues?.map((val, idx) => (
                              <Chip
                                key={idx}
                                label={val.trim()}  // Trim leading spaces
                                size="small"
                                style={{ margin: "2px" }}
                                sx={{
                                  borderRadius: "18px",
                                  marginBottom: "4px",
                                  background: "#445A68E5",
                                  color: "#fff",
                                  "& .MuiChip-deleteIcon": {
                                    color: "white",
                                    fontSize: "16px",
                                  },
                                  "& .MuiChip-deleteIcon:hover": {
                                    backgroundColor: "transparent",
                                    color: "white",
                                  },
                                }}
                              />
                            ))}
                          </Typography>

                          {/* Displaying status at the bottom */}
                          <Typography sx={{ display: 'flex', alignItems: 'center' }} >
                            Status:<Box sx={{ marginLeft: "4px", color: status === 'approved' ? 'green' : 'orange' }}> {capitalizeStatus(status)}</Box>
                          </Typography>
                        </Box>

                      )
                    })
                  }
                </Box>


                {
                  viewCampaignDetails?.userNotification !== null && (
                    <>

                      {
                        userNotificationData?.status !== 'REJECTED' && (
                          <>
                            <Box sx={styles.container(isSmallScreen)}>
                              <Typography sx={styles.labelText}>
                                {userNotificationData?.message}
                              </Typography>
                            </Box>

                            {
                              viewCampaignDetails?.channelDetailsCompleted && (
                                <Box sx={styles.container(isSmallScreen)}>
                                  <Chip
                                    // label={
                                    //   userNotificationData?.status === "DRAFT" ? "Execute" : "Executed"
                                    // }
                                    label={userNotificationData?.status === "APPROVED" ? "Execute" : userNotificationData?.status === "EXECUTED" ? "Executed" : userNotificationData?.status === "PARTIALLYAPPROVED" ? "Execute" : userNotificationData?.status}
                                    style={{
                                      backgroundColor:
                                        userNotificationData?.status === "EXECUTED"
                                          ? "#1976d2"
                                          : "transparent", // Primary color for Executed
                                      color: userNotificationData?.status === "EXECUTED" ? "#fff" : "inherit", // White text for Executed
                                      borderColor:
                                        userNotificationData?.status === "EXECUTED" ? "#1976d2" : "#1976d2", // Primary border color for both
                                    }}
                                    variant="outlined"
                                    onClick={(event) => {
                                      event.stopPropagation();

                                      if (userNotificationData?.status === "EXECUTED") {
                                        // If the status is already Executed, show a toast
                                        toast.info("Already Executed");
                                      } else {
                                        handleOpenModal();
                                      }
                                    }}
                                  />
                                </Box>
                              )
                            }
                          </>
                        )
                      }
                    </>
                  )
                }

                {viewCampaignDetails?.status === 'DRAFT' && (
                  <Box sx={{ display: "flex", justifyContent: "flex-start", padding: 2 }}>
                    <Button variant="contained" color="primary" onClick={() => navigate(`/create-campaign/${viewCampaignDetails?.campaignId}`)}>
                      {t('campaignList.edit')}
                    </Button>


                  </Box>
                )}

              </Collapse>
            }
          </TableCell>
        </TableRow>

        <TemplateView open={openTemplate} handleClose={handleTemplateView} tempType={viewCampaignDetails?.channel} t={t} templateData={viewCampaignDetails?.templateObj} />

        <ConfirmationModal
          open={open}
          handleClose={handleClose}
          confirmationMessage={t('executeNotification')}
          handleSubmit={handleExecute}
        />
      </>
    )
  );
};


export default ResponsiveTableView