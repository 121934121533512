import { Autocomplete, Box, Button, Card, CircularProgress, Grid2, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/CustomBreadcrumb'
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Delete, Add } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAttributesPriceCategorySlice, fetchAttributesCategorySlice, fetchAttributeTypeSlice, fetchMetaTypeSlice } from '../../../redux/superAdmin/superAdmin.slice';
import { useNavigate } from 'react-router-dom';
import CustomSwitch from '../../../components/CustomSwitch/CustomSwitch';
import CustomLoader from '../../../components/CustomLoader/CustomLoader';

const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
    textarea: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
    selectField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            // border: "1px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
    },
    label: {
        color: "#7C7C7C",
        fontWeight: 500,
        marginBottom: "2px",
        fontSize: "18px !important",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "4px",
}));
const statusStyles = {
    active: {
        backgroundColor: "#e0f7e9",
        color: "#34a853",
    },
    offline: {
        backgroundColor: "#f5f5f5",
        color: "#9e9e9e",
    },
};

const TableHeadTitle = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    color: "#455967",
    fontWeight: "500",
    lineHeight: "1.5rem",
}));


const AddPricing = () => {

    const { t } = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { submitBtnLoader } = useSelector((state) => state.superAdmin);

    const breadcrumb = [

        {
            title: t("pricing.title"),
            href: '/pricing',
        },
        {
            title: t("pricing.addPricing"),
            href: '',
        },
    ];

    const initialValues = {
        value: '',
        fixedValue: '',
        percentageValue: '',
        active: false

    }


    const validationSchema = Yup.object().shape({
        value: Yup.string()
            .required(t('validate.attributeCategoryValueRequired')),
        fixedValue: Yup.number()
            .typeError(t('validate.fixedValueMustBeNumber')) // Error if it's not a number
            .required(t('validate.fixedValueRequired')), // Mark it as required
        percentageValue: Yup.number()
            .typeError(t('validate.percentageValueMustBeNumber')) // Error if it's not a number
            .min(1, t('validate.percentageValueMin')) // Minimum value validation
            .max(100,t('validate.percentageValueMax')) // Maximum value validation
            .required(t('validate.percentageValueRequired')), // Mark it as required
    });


    const handleSubmit = async (values) => {

        const result = await dispatch(fetchAttributesPriceCategorySlice(values)).unwrap();
        console.log('result', result)
        if (result) {
            navigate('/pricing')
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                <CustomBreadcrumb breadcrumb={breadcrumb} />

                <Grid2 container mt={3}>
                    <Grid2 size={{ xs: 12, md: 8 }}>
                        <Typography
                            sx={{ color: "#445A68", fontSize: "29px", fontWeight: "500" }}
                        >
                                {t("pricing.addPricing")}
                        </Typography>
                    </Grid2>
                </Grid2>


                <Card sx={{ mt: 5, p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)' }}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12 }}>
                            <Typography
                                sx={{ color: "#445A68", fontSize: "24px", fontWeight: "500" }}
                            >
                                {t("pricing.companyInformation")}
                                </Typography>
                        </Grid2>

                        <Grid2 size={{ xs: 12, md: 6 }}>
                            <Typography className={classes.label}>
                            {t("pricing.attributeCategoryValue")}
                            <span style={{ color: "#FF0000" }}> *</span>
                            </Typography>

                            <TextField
                                name="value"
                                value={formik.values.value}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.value && Boolean(formik.errors.value)}
                                helperText={formik.touched.value && formik.errors.value}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                            <Typography className={classes.label}>
                            {t("pricing.fixedValue")}
                            <span style={{ color: "#FF0000" }}> *</span>
                            </Typography>

                            <TextField
                                name="fixedValue"
                                value={formik.values.fixedValue}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.fixedValue && Boolean(formik.errors.fixedValue)}
                                helperText={formik.touched.fixedValue && formik.errors.fixedValue}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                            <Typography className={classes.label}>
                            {t("pricing.percentageValue")}<span style={{ color: "#FF0000" }}> *</span>
                            </Typography>

                            <TextField
                                name="percentageValue"
                                value={formik.values.percentageValue}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classes.textField}
                                error={formik.touched.percentageValue && Boolean(formik.errors.percentageValue)}
                                helperText={formik.touched.percentageValue && formik.errors.percentageValue}
                            />
                        </Grid2>

                        <Grid2 size={{ xs: 12, md: 6 }}>
                            <Typography className={classes.label} sx={{ marginBottom: '10px !important' }}>
                            {t("pricing.switchButton")}
                            </Typography>
                            <CustomSwitch
                                checked={formik.values.active}
                                onChange={(event) => formik.setFieldValue("active", event.target.checked)}
                            />
                        </Grid2>

                    </Grid2>
                </Card>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Button
                    sx={{
                        borderRadius: '10px',
                        color: '#FFFFFF !important',
                        fontSize: '18px !important',
                        background: 'linear-gradient(180deg, #13BECF 0%, #455869 100%)',
                        padding: '8px 16px !important',
                        textTransform: 'capitalize'
                    }}
                    type='submit'
                >
                    {t("submit")}
                </Button>
            </Box>

            <CustomLoader loading={submitBtnLoader} />

        </form>
    )
}

export default AddPricing