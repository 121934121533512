import { Box, Button, Grid2, Typography, IconButton, CircularProgress, Autocomplete, TextField, InputAdornment, Chip, FormControl, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
// import CustomPagination from "../../components/CustomPagination/CustomPagination";
// import CustomSwitch from "../../components/CustomSwitch/CustomSwitch";

const useStyles = makeStyles((theme) => ({
    searchBar: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
        },
        "& .MuiInputBase-input": {
            color: "#2d2d2d",
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },

    selectField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#FFFFFF", // Background color
            borderRadius: "10px",
        },
        "& .MuiInputBase-input": {
            color: "#2d2d2d",
        },
        "& .MuiInputBase-input::placeholder": {
            color: "#2d2d2d",
        },
    },

    textField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#FFFFFF", // Background color
            borderRadius: "10px",
        },
        "& .MuiInputBase-input": {
            color: "#2d2d2d",
        },
        "& .MuiInputBase-input::placeholder": {
            color: "#2d2d2d",
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "4px",
}));

const TableHeadTitle = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    color: "#455967",
    fontWeight: "500",
    lineHeight: "1.5rem",
}));

const TableDataTitle = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    color: "#455967",
    fontWeight: "500",
}));

const data = [
    {
        attribute: "Age Group",
        type: "Basic",
        totalRevenue: "$28,500",
        campaigns: 12,
        avgRevenuePerCampaign: "$2,375"
    },
    {
        attribute: "Shopping Preferences",
        type: "Premium",
        totalRevenue: "$32,400",
        campaigns: 15,
        avgRevenuePerCampaign: "$2,160"
    },
    {
        attribute: "Location Data",
        type: "Super Premium",
        totalRevenue: "$45,600",
        campaigns: 10,
        avgRevenuePerCampaign: "$4,560"
    }
];

const AttributePerformenceTable = () => {

    const classes = useStyles();
    const [campaign, setCampaign] = useState("");
    const [timePeriod, setTimePeriod] = useState("");

    const [loading, setLoading] = useState(false);

    return (
        <Box mt={5}>
            <Typography sx={{ fontSize: '26px', color: '#445A68', fontWeight: '500' }} mb={1}>
                Attribute Performence
            </Typography>

            <Box display={'flex'} justifyContent={'flex-end'} gap={2} mb={3}>
                <FormControl size='small'>
                    <Select value={campaign} onChange={(event) => { setCampaign(event.target.value) }} displayEmpty>
                        <MenuItem value="">Select campaign name</MenuItem>
                        <MenuItem value="Campaign 1">Campaign 1</MenuItem>
                        <MenuItem value="Campaign 2">Campaign 2</MenuItem>
                    </Select>
                </FormControl>

                <FormControl size='small'>
                    <Select value={timePeriod} onChange={(event) => { setTimePeriod(event.target.value) }} displayEmpty>
                        <MenuItem value="">Select time period</MenuItem>
                        <MenuItem value="Day">Day</MenuItem>
                        <MenuItem value="Week">Week</MenuItem>
                        <MenuItem value="Month">Month</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <TableContainer
                component={Paper}
                sx={{ mt: 2, border: "1px solid #D4D4D4", borderRadius: "12px" }}
            >
                {
                    loading ?
                        (
                            <Box display="flex" justifyContent="center" my={3}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Table aria-label="campaign table">
                                <TableHead sx={{ background: "#F1F1F1" }}>
                                    <TableRow>
                                        <TableCell>
                                            <BoxContainer>
                                                <TableHeadTitle>Attribute</TableHeadTitle>
                                            </BoxContainer>
                                        </TableCell>
                                        <TableCell>
                                            <BoxContainer>
                                                <TableHeadTitle>Type</TableHeadTitle>
                                            </BoxContainer>
                                        </TableCell>
                                        <TableCell>
                                            <BoxContainer>
                                                <TableHeadTitle>Total Revenue</TableHeadTitle>
                                            </BoxContainer>
                                        </TableCell>
                                        <TableCell>
                                            <BoxContainer>
                                                <TableHeadTitle>Campaigns</TableHeadTitle>
                                            </BoxContainer>
                                        </TableCell>
                                        <TableCell>
                                            <BoxContainer>
                                                <TableHeadTitle>Avg Revenue/Campaigns</TableHeadTitle>
                                            </BoxContainer>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.length > 0 ? (
                                            data.map((item, index) => {

                                                return (
                                                    <StyledTableRow key={index}>
                                                        <TableCell>
                                                            <TableDataTitle>{item?.attribute}</TableDataTitle>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableDataTitle>{item?.type}</TableDataTitle>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableDataTitle>{item?.totalRevenue}</TableDataTitle>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableDataTitle>{item?.campaigns}</TableDataTitle>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableDataTitle>{item?.avgRevenuePerCampaign}</TableDataTitle>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                )
                                            })
                                        ) : (<></>)
                                    }
                                </TableBody>
                            </Table>
                        )
                }
            </TableContainer>
        </Box>
    )
}

export default AttributePerformenceTable