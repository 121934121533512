import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { Box, Button, Grid2, TextField, Typography } from '@mui/material';
import { createBridgeSlice, fetchBridgeSlice } from '../../../redux/superAdmin/superAdmin.slice';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
    textarea: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
    selectField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            // border: "1px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
    },
    label: {
        color: "#7C7C7C",
        fontWeight: 500,
        marginBottom: "2px",
        fontSize: "18px !important",
    },
}));

const AddBridgeModal = ({ open, handleClose, t }) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const initialValues = {
        bridgeName: '',
        url: '',
        decryptionUrl:'',
        description: '',
    }

    const validationSchema = Yup.object().shape({
        bridgeName: Yup.string().required(t('validate.bridgeNameRequired')),
        url: Yup.string().required(t('validate.urlRequired')),
        decryptionUrl: Yup.string().required('Decryption Url is Required'),
        description: Yup.string().required(t('validate.descriptionRequired')),
    })

    const handleSubmit = async (values) => {

        const payload = {
            name: values.bridgeName,
            url: values.url,
            decryptionUrl:values.decryptionUrl,
            type: 'IP',
            description: values.description,
        }

        const result = await dispatch(createBridgeSlice(payload)).unwrap();

        toast.success("Bridge Added Successfully!");

        await dispatch(fetchBridgeSlice()).unwrap();

        handleClose();
        formik.resetForm();

        if (result) {
            console.log("result---", result);
        }
    }

    const formik = useFormik({

        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    return (
        <CustomModal
            open={open}
            onClose={() => {
                handleClose();
                formik.resetForm();
            }}
            heading={t('editConsumer.addBridge')}
            button={true}
            customWidth={"710px"}
        >
            <form onSubmit={formik.handleSubmit}>
                <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 12 }}>
                        <Typography className={classes.label}>
                            {t('editConsumer.name')}{" "}
                            <span style={{ color: "#FF0000" }}>*</span>
                        </Typography>

                        <TextField
                            name="bridgeName"
                            value={formik.values.bridgeName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={classes.textField}
                            error={formik.touched.bridgeName && Boolean(formik.errors.bridgeName)}
                            helperText={formik.touched.bridgeName && formik.errors.bridgeName}
                        />
                    </Grid2>

                    <Grid2 size={{ xs: 12 }}>
                        <Typography className={classes.label}>
                            Bridge Url{" "}
                            <span style={{ color: "#FF0000" }}>*</span>
                        </Typography>

                        <TextField
                            name="url"
                            value={formik.values.url}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={classes.textField}
                            error={formik.touched.url && Boolean(formik.errors.url)}
                            helperText={formik.touched.url && formik.errors.url}
                        />
                    </Grid2>

                    <Grid2 size={{ xs: 12 }}>
                        <Typography className={classes.label}>
                            Decryption Url{" "}
                            <span style={{ color: "#FF0000" }}>*</span>
                        </Typography>

                        <TextField
                            name="decryptionUrl"
                            value={formik.values.decryptionUrl}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={classes.textField}
                            error={formik.touched.decryptionUrl && Boolean(formik.errors.decryptionUrl)}
                            helperText={formik.touched.decryptionUrl && formik.errors.decryptionUrl}
                        />
                    </Grid2>

                    <Grid2 size={{ xs: 12 }}>
                        <Typography className={classes.label}>
                            {t('editConsumer.description')}{" "}
                            <span style={{ color: "#FF0000" }}>*</span>
                        </Typography>

                        <TextField
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={classes.textField}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                        />
                    </Grid2>
                </Grid2>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Button
                        sx={{
                            borderRadius: '10px',
                            color: '#FFFFFF !important',
                            fontSize: '18px !important',
                            background: 'linear-gradient(180deg, #13BECF 0%, #455869 100%)',
                            padding: '8px 16px !important',
                            textTransform: 'capitalize'
                        }}
                        type='submit'
                    >
                        {t('submit')}
                    </Button>
                </Box>
            </form>
        </CustomModal>
    )
}

export default AddBridgeModal
