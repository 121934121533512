

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid2,
  TextField,
  Typography,
} from "@mui/material";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAttributesByIdSlice,
  updateAttributesByIdSlice,
} from "../../../redux/superAdmin/superAdmin.slice";
import { useNavigate, useParams } from "react-router-dom";
import CustomSwitch from "../../../components/CustomSwitch/CustomSwitch";

const useStyles = makeStyles(() => ({
  textField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
      borderRadius: "10px",
      border: "0px solid #C5C5C5",
      height: "60px",
      background: "#FFF",
    },
  },
  label: {
    color: "#7C7C7C",
    fontWeight: 500,
    marginBottom: "2px",
    fontSize: "18px !important",
  },
}));

const UpdatePricing = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { attributeData } = useSelector((state) => state.superAdmin);
  const [loading, setLoading] = useState(true); // Loading state

  const breadcrumb = [
    { title: "Pricing", href: "/pricing" },
    { title: "Edit Price", href: "" },
  ];

  const validationSchema = Yup.object().shape({
    label: Yup.string().required("Attribute Category Label is required"),
    fixedValue: Yup.number()
      .typeError("Fixed Value must be a number")
      .required("Fixed Value is required"),
    percentageValue: Yup.number()
      .typeError("Percentage Value must be a number")
      .min(1, "Percentage Value must be at least 1")
      .max(100, "Percentage Value must not exceed 100")
      .required("Percentage Value is required"),
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(fetchAttributesByIdSlice(id));
      if (response?.payload) {
        formik.setValues({
          label: response.payload.label,
          fixedValue: response.payload.fixedValue,
          percentageValue: response.payload.percentageValue,
          active: response.payload.active,
        });
      }
      setLoading(false); // Data fetched, stop loading
    };

    fetchData();
  }, [id, dispatch]);

  const formik = useFormik({
    initialValues: {
      label: "",
      fixedValue: "",
      percentageValue: "",
      active: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        value: values.label,
        fixedValue: values.fixedValue,
        percentageValue: values.percentageValue,
        active: values.active,
      };

      await dispatch(updateAttributesByIdSlice({ id, ...payload }));
      navigate("/pricing");
    },
    enableReinitialize: true,
  });



  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Grid2 container mt={3} spacing={2}>
          <Grid2 item xs={12} md={8}>
            <Typography
              sx={{ color: "#445A68", fontSize: "29px", fontWeight: "500" }}
            >
              Edit Pricing
            </Typography>
          </Grid2>
        </Grid2>
        <Card
          sx={{
            mt: 5,
            p: 3,
            borderRadius: "15px",
            boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
          }}
        >
            {!loading ?
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 6 }}>
              <Typography className={classes.label}>
                Attribute Category Label
                <span style={{ color: "#FF0000" }}> *</span>
              </Typography>
              
              <TextField
                name="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={classes.textField}
                error={formik.touched.label && Boolean(formik.errors.label)}
                helperText={formik.touched.label && formik.errors.label}
              />
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <Typography className={classes.label}>Fixed Value  <span style={{ color: "#FF0000" }}> *</span></Typography>
              <TextField
                name="fixedValue"
                value={formik.values.fixedValue}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={classes.textField}
                error={
                  formik.touched.fixedValue && Boolean(formik.errors.fixedValue)
                }
                helperText={
                  formik.touched.fixedValue && formik.errors.fixedValue
                }
              />
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <Typography className={classes.label}>
                Percentage Value <span style={{ color: "#FF0000" }}> *</span>
              </Typography>
              <TextField
                name="percentageValue"
                value={formik.values.percentageValue}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={classes.textField}
                error={
                  formik.touched.percentageValue &&
                  Boolean(formik.errors.percentageValue)
                }
                helperText={
                  formik.touched.percentageValue &&
                  formik.errors.percentageValue
                }
              />
            </Grid2>

            <Grid2 size={{ xs: 6 }}>
              <Typography
                className={classes.label}
                sx={{ marginBottom: "10px !important" }}
              >
                Switch Button
              </Typography>
              <CustomSwitch
                checked={formik.values.active}
                onChange={(event) =>
                  formik.setFieldValue("active", event.target.checked)
                }
              />
            </Grid2>
          </Grid2>

          :   <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </Box>}
        </Card>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Button
                            sx={{
                                borderRadius: '10px',
                                marginRight:'20px',
                                color: '#FFFFFF !important',
                                fontSize: '18px !important',
                                background: 'linear-gradient(180deg, #13BECF 0%, #455869 100%)',
                                padding: '8px 16px !important',
                                textTransform: 'capitalize'
                            }}
                            type="button"
                          onClick={()=>navigate('/pricing')}
                        >
                         Back to list
                        </Button>

        <Button
          sx={{
            borderRadius: "10px",
            color: "#FFFFFF !important",
            fontSize: "18px !important",
            background: "linear-gradient(180deg, #13BECF 0%, #455869 100%)",
            padding: "8px 16px !important",
            textTransform: "capitalize",
          }}
          type="submit"
        >
          Update list
        </Button>
      </Box>
    </form>
  );
};

export default UpdatePricing;


