import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDataRequestStatusThunk, fetchDataRequestUpdateThunk, fetchDataRequestViewThunk, fetchDataRequestCountThunk, fetchCampaignPerformenceThunk } from "./dataRequest.thunk";


export const fetchDataRequestUpdateSlice = createAsyncThunk("fetchPrimarySelect", fetchDataRequestUpdateThunk);

export const fetchDataRequestViewSlice = createAsyncThunk("fetchDataRequestSlice", fetchDataRequestViewThunk);

export const fetchDataRequestStatusSlice = createAsyncThunk("fetchDataRequestStatusThunk", fetchDataRequestStatusThunk);
export const fetchDataRequestCountSlice = createAsyncThunk("fetchDataRequestCountThunk", fetchDataRequestCountThunk);
export const fetchCampaignPerformenceSlice = createAsyncThunk("fetchDataRequestCountThunk", fetchCampaignPerformenceThunk);


const initialState = {
  dataRequest: {},
  viewDataRequestData: {},
  dataRequestStatusData: [],
  campaignPerformence:[],
  status: "idle",
  secLoading: false,
  loading: false,
  error: null,
};


const dataRequestSlice = createSlice({

  name: "data",
  initialState,
  reducers: {
    clearDataRequestStatus: (state) => {
      state.dataRequestStatusData = [];
      console.log("Data Request Status Data Reset",state.dataRequestStatusData);
      state.totalItems = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataRequestUpdateSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataRequestUpdateSlice.fulfilled, (state, action) => {
        state.dataRequest = action.payload;
        state.loading = false;
      })
      .addCase(fetchDataRequestUpdateSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchDataRequestViewSlice.pending, (state) => {
        state.secLoading = true;
        state.error = null;
      })
      .addCase(fetchDataRequestViewSlice.fulfilled, (state, action) => {
        state.viewDataRequestData = action.payload;
        state.secLoading = false;
      })
      .addCase(fetchDataRequestViewSlice.rejected, (state, action) => {
        state.secLoading = false;
        state.error = action.error.message;
      })

      // View Data Request Status Data

      .addCase(fetchDataRequestStatusSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataRequestStatusSlice.fulfilled, (state, action) => {
        state.dataRequestStatusData = action.payload;
        console.log("Data Request Status Data",action.payload);
        state.totalItems = action?.payload?.totalElements;
        state.loading = false;
      })
      .addCase(fetchDataRequestStatusSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Campaign Performence

      .addCase(fetchCampaignPerformenceSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCampaignPerformenceSlice.fulfilled, (state, action) => {
        state.campaignPerformence = action.payload;
        state.totalItems = action?.payload?.totalElements;
        state.loading = false;
      })
      .addCase(fetchCampaignPerformenceSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
})

export const { clearDataRequestStatus } = dataRequestSlice.actions;
export default dataRequestSlice.reducer;
