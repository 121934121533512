import { Box, Button, Card, CardContent, CircularProgress, Grid2, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomBreadcrumb from '../../components/CustomBreadcrumb/CustomBreadcrumb'
import { useTranslation } from 'react-i18next'
import { LineChart } from '@mui/x-charts/LineChart';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEarningGraphSlice, fetchProviderEarningSlice } from '../../redux/teamSlice/team.slice';
import EarningTable from './Components/EarningTable';
import AttributePerformenceTable from './Components/AttributePerformenceTable';


const PriceTrends = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { earningInfo, earningGraph, loading } = useSelector((state) => state.teamsDetails);
    const [data, setData] = useState('weekly');

    const breadcrumb = [
        {
            title: t('dashboard'),
            href: '',
        }
    ];

    useEffect(() => {

        const fetchEarningsGraph = async () => {

            const result = await dispatch(fetchEarningGraphSlice(data)).unwrap();

            if (result) {
                console.log("Earning Graph", result);
            }
        }

        fetchEarningsGraph();

    }, [data])


    useEffect(() => {
        const fetchEarningsInfo = async () => {

            const result = await dispatch(fetchProviderEarningSlice()).unwrap();

            if (result) {
                console.log("Earning Info", result);
            }
        }
        fetchEarningsInfo();

    }, [])

    console.log("earningInfo", earningInfo);
    console.log("earningGraph", earningGraph);

    return (
        <Box>
            <CustomBreadcrumb breadcrumb={breadcrumb} />

            <Grid2 container mt={3}>
                <Grid2 size={{ xs: 12, md: 8 }}>
                    <Typography
                        sx={{ color: "#445A68", fontSize: "29px", fontWeight: "500" }}
                    >
                        {t('dashboard')}
                    </Typography>
                </Grid2>
            </Grid2>

            <Card sx={{ mt: 5, p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)' }}>

                <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 12, md: 4 }}>
                        <Card sx={{ borderRadius: '15px', boxShadow: '0px 4px 12.5px -6px rgba(0, 0, 0, 0.25)' }}>
                            <CardContent sx={{ height: '100%' }}>
                                <Typography sx={{ fontSize: '20px', color: '#445A68', fontWeight: '500' }} component="div">
                                    {t('totalEarnings')}
                                </Typography>
                                <Typography sx={{ color: '#7C7C7C', fontWeight: '500', fontSize: '16px' }}>
                                    {earningInfo?.totalEarnings ? earningInfo?.totalEarnings : 'No data Available'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 4 }}>
                        <Card sx={{ borderRadius: '15px', boxShadow: '0px 4px 12.5px -6px rgba(0, 0, 0, 0.25)' }}>
                            <CardContent sx={{ height: '100%' }}>
                                <Typography sx={{ fontSize: '20px', color: '#445A68', fontWeight: '500' }} component="div">
                                    {t('last7Days')}
                                </Typography>
                                <Typography sx={{ color: '#7C7C7C', fontWeight: '500', fontSize: '16px' }}>
                                    {earningInfo?.last7DaysEarnings ? earningInfo?.last7DaysEarnings : 'No data Available'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 4 }}>
                        <Card sx={{ borderRadius: '15px', boxShadow: '0px 4px 12.5px -6px rgba(0, 0, 0, 0.25)' }}>
                            <CardContent sx={{ height: '100%' }}>
                                <Typography sx={{ fontSize: '20px', color: '#445A68', fontWeight: '500' }} component="div">
                                    {t('totalCampaignsReceived')}
                                </Typography>
                                <Typography sx={{ color: '#7C7C7C', fontWeight: '500', fontSize: '16px' }}>
                                    {earningInfo?.totalCampaignsReceived ? earningInfo?.totalCampaignsReceived : 'No data Available'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 4 }}>
                        <Card sx={{ borderRadius: '15px', boxShadow: '0px 4px 12.5px -6px rgba(0, 0, 0, 0.25)' }}>
                            <CardContent sx={{ height: '100%' }}>
                                <Typography sx={{ fontSize: '20px', color: '#445A68', fontWeight: '500' }} component="div">
                                    {t('totalCampaignsApproved')}
                                </Typography>
                                <Typography sx={{ color: '#7C7C7C', fontWeight: '500', fontSize: '16px' }}>
                                    {earningInfo?.totalCampaignsApproved ? earningInfo?.totalCampaignsApproved : 'No data Available'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 4 }}>
                        <Card sx={{ borderRadius: '15px', boxShadow: '0px 4px 12.5px -6px rgba(0, 0, 0, 0.25)' }}>
                            <CardContent sx={{ height: '100%' }}>
                                <Typography sx={{ fontSize: '20px', color: '#445A68', fontWeight: '500' }} component="div">
                                    {t('totalCampaignsExecuted')}
                                </Typography>
                                <Typography sx={{ color: '#7C7C7C', fontWeight: '500', fontSize: '16px' }}>
                                    {earningInfo?.totalCampaignsExecuted ? earningInfo?.totalCampaignsExecuted : 'No data Available'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid2>
                    {/* <Grid2 size={{ xs: 12, md: 4 }}>
                        <Card sx={{ borderRadius: '15px', boxShadow: '0px 4px 12.5px -6px rgba(0, 0, 0, 0.25)' }}>
                            <CardContent sx={{ height: '100%' }}>
                                <Typography sx={{ fontSize: '20px', color: '#445A68', fontWeight: '500' }} component="div">
                                {t('averageApprovalTime')}
                                </Typography>
                                <Typography sx={{ color: '#7C7C7C', fontWeight: '500', fontSize: '16px' }}>
                                    {earningInfo?.averageApprovalTimeHours}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 4 }}>
                        <Card sx={{ borderRadius: '15px', boxShadow: '0px 4px 12.5px -6px rgba(0, 0, 0, 0.25)' }}>
                            <CardContent sx={{ height: '100%' }}>
                                <Typography sx={{ fontSize: '20px', color: '#445A68', fontWeight: '500' }} component="div">
                                {t('activeCampaigns')}
                                </Typography>
                                <Typography sx={{ color: '#7C7C7C', fontWeight: '500', fontSize: '16px' }}>
                                    {earningInfo?.activeCampaigns}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 8 }}>
                        <Card sx={{ borderRadius: '15px', boxShadow: '0px 4px 12.5px -6px rgba(0, 0, 0, 0.25)' }}>
                            <CardContent sx={{ height: '100%' }}>
                                <Typography sx={{ fontSize: '20px', color: '#445A68', fontWeight: '500' }} component="div">
                                {t('topPerformingAttributes')}
                                </Typography>
                                {earningInfo?.topPerformingAttributes?.length > 0 ? (
                                    earningInfo.topPerformingAttributes.map((attribute, index) => (
                                        <Typography
                                            key={index}
                                            sx={{ color: '#7C7C7C', fontWeight: '500', fontSize: '16px' }}
                                        >
                                            {attribute}
                                        </Typography>
                                    ))
                                ) : (
                                    <Typography sx={{ color: '#7C7C7C', fontWeight: '500', fontSize: '16px' }}>
                                        No attributes available
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid2> */}
                </Grid2>

                <Grid2 container item spacing={2} mt={5}>
                    <Grid2 size={{ xs: 12 }}>
                        <Typography sx={{ fontSize: '26px', color: '#445A68', fontWeight: '500' }}>{t('revenueTrend')}</Typography>
                    </Grid2>
                </Grid2>

                <Stack direction="row" spacing={2} mt={3}>
                    <Button
                        onClick={() => { setData('weekly') }}
                        variant={data === 'weekly' ? "contained" : "outlined"}
                        sx={{
                            color: data === 'weekly' ? "#fff" : "#445A68",
                            border: "1px solid #445A68",
                            backgroundColor: data === 'weekly' ? "#445A68" : "transparent",
                        }}
                    >
                        {t('weekly')}
                    </Button>
                    <Button
                        onClick={() => { setData('monthly') }}
                        variant={data === 'monthly' ? "contained" : "outlined"}
                        sx={{
                            color: data === 'monthly' ? "#fff" : "#445A68",
                            border: "1px solid #445A68",
                            backgroundColor: data === 'monthly' ? "#445A68" : "transparent",
                        }}
                    >
                        {t('monthly')}
                    </Button>
                </Stack>

                <Box mt={4}>
                    {loading ? (
                        <Box sx={{ height: '400px', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <LineChart
                            dataset={earningGraph || []}
                            xAxis={[{ scaleType: 'band', dataKey: data === 'weekly' ? 'week' : 'month' }]}
                            series={[
                                {
                                    dataKey: 'price',
                                    label: 'Price',
                                    valueFormatter: (value) => `${value}`,
                                }
                            ]}
                            height={400}
                            margin={{ left: 50, right: 20, top: 50, bottom: 50 }}
                            grid={{ vertical: true, horizontal: true }}
                        />
                    )}
                </Box>

                <EarningTable />

                {/* <AttributePerformenceTable/> */}
            </Card>
        </Box>
    )
}

export default PriceTrends