import React from 'react'
import CustomModal from '../CustomModal/CustomModal'
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({ open, handleClose, handleSubmit, confirmationMessage }) => {

    const { t } = useTranslation();
    return (
        <CustomModal
            open={open}
            onClose={() => {
                handleClose();
            }}
            button={true}
            customWidth={"455px"}
        >
            <Box sx={{ padding: '8px' }}>
                <Typography sx={{ fontSize: '20px', color: '#445A68', fontWeight: '400', textAlign: 'center' }}>
                    {confirmationMessage}
                </Typography>
            </Box>
            <Box
                sx={{ display: 'flex', gap: '10px', paddingTop: '20px' }}
            >
                <Button
                    sx={{
                        borderRadius: "8px",
                        width: '100%',
                        padding: "8px 12px",
                        background: '#445A68',
                        fontSize: "16px",
                        color: "#FFF",
                        fontWeight: "500",
                        border: "1px solid #445A68",
                        transition: "all 0.3s ease",
                    }}
                    onClick={() => {
                        handleClose();
                    }}
                >
                    {t('no')}
                </Button>

                <Button
                    sx={{
                        color: "#FFF",
                        width: '100%',
                        background: "linear-gradient(0deg, #13BECF 0%, #435D6B 100%)",
                        borderRadius: "8px",
                        padding: "8px 12px",
                        fontSize: "16px",
                        fontWeight: "500",
                        transition: "all 0.3s ease",
                    }}
                    onClick={handleSubmit}
                >
                    {t('yes')}
                </Button>
            </Box>
        </CustomModal>
    )
}

export default ConfirmationModal