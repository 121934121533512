import { Box, Button, CircularProgress, FormHelperText, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ListContentModal from "../Components/ListContentModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  CampaignListEditSlice,
  fetchCreateCampaignSlice,
  fetchSMSTemplateSlice,
  fetchTemplateListDataSlice,
  fetchWhatsAppTemplateSlice,
} from "../../../redux/stepperSlice/stepper.slice";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import TemplateDetailsModal from "../Components/TemplateDetailsModal";
import TemplateViewScreen from "../Components/TemplateViewScreen";
import CustomLoader from "../../../components/CustomLoader/CustomLoader";
import { useNavigate } from "react-router-dom";

const formatDate = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = d.getFullYear();
  const hours = String(d.getHours()).padStart(2, "0");
  const minutes = String(d.getMinutes()).padStart(2, "0");
  return `${day}-${month}-${year} ${hours}:${minutes}`;
};


const channelData = [
  {
    label: "SMS",
    value: "SMS",
  },
  {
    label: "WHATSAPP",
    value: "WHATSAPP",
  },
];

const CampaignDetails = ({
  formValues,
  classes,
  handleNext,
  setCampaignDetails,
  setFormValues,
  setCampaignId,
  previewMedia,
  setPreviewMedia,
  t,
  isEdit,
  prevCampaignId,
  campaignId
}) => {

  
  const initialValues = formValues;
  const [open, setOpen] = useState(false);
  const [templateOpen, setTemplateOpen] = useState(false);
  const [contentIds, setContentIds] = useState([]);
  const [templateId, setTemplateId] = useState(formValues?.templateId ||"");
  const [templateDetails, setTemplateDetails] = useState([]);
  const [templateData, setTemplateData] = useState(formValues?.templateViewData || "");
  const [medidaURL, setMedidaURL] = useState(null);
  const [medidaType, setMediType] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [alreadyCreated,setAlreadyCreated] =  useState(true)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { submitBtnLoader } = useSelector(
    (state) => state.stepper
  );

  const validationSchema = Yup.object({
    campaignName: Yup.string().required(t('validate.campaignTitleReq')),
    // description: Yup.string().required(t('validate.campaignDescriptionReq')),
    channel: Yup.string().required(t('validate.channelReq')),

    // totalBudget: Yup.string().required(t('validate.totalBudgetReq')),
    startDate: Yup.date()
      .min(new Date(), "Start date and time cannot be in the past")
      .required("Start date and time is required"),
    endDate: Yup.date()
      .min(
        Yup.ref("startDate"),
        "End date and time cannot be before start date and time"
      )
      .test(
        "max-37-days",
        "End date and time cannot be more than 37 days after start date and time",
        function (value) {
          const startDate = this.parent.startDate;
          if (startDate && value) {
            const maxEndDate = new Date(startDate);
            maxEndDate.setDate(maxEndDate.getDate() + 37);
            return value <= maxEndDate;
          }
          return true;
        }
      )
      .required("End date and time is required"),
    // media: Yup.string().required(t('validate.selectMediaContent')),
    templateDetails: Yup.string().required('Please Select Channel First')

  });
  useEffect(() => {
    const fetchTemplateData = async () => {

      if (isEdit) {
        setTemplateData(formValues.templateObj);
        setCampaignId(prevCampaignId)
        const payload = {
          data: formValues.channel,
          page: page,
          rowsPerPage: rowsPerPage
        };

        try {
          const result = await dispatch(fetchTemplateListDataSlice(payload)).unwrap();
          setTemplateDetails(result);

        } catch (error) {
          // Handle error if needed
          console.error('Error fetching template data:', error);
        }
      }
    };

    fetchTemplateData();
  }, [isEdit, formValues.channel, page, rowsPerPage, dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTemplateOpen = () => {
    if (formik.values.channel == "") {
      toast.error(t('validate.selectChannelFirst'));
      return;
    }

    setTemplateOpen(true);
  };

  const handleTemplateClose = () => {
    setTemplateOpen(false);
  };

  const handleTemplateList = async (e, value) => {
    if (!value) {
      formik.setFieldValue("channel", "");
      setTemplateDetails([]);
      formik.setFieldValue("templateDetails", "");
      return;
    }

    formik.setFieldValue("channel", value ? value.value : "");
    formik.setFieldValue("templateDetails", "");
    setTemplateId("");

    const payload = {
      data: value.value,
      page: page,
      rowsPerPage: rowsPerPage
    }

    const result = await dispatch(
      fetchTemplateListDataSlice(payload)
    ).unwrap();

    if (result) {

      setTemplateDetails(result);
    } else {
    }
  };



  const handleSubmit = async (values) => {
   
  
    if (isEdit) {
      var payload = {
        ...values,
        startDate: values.startDate
          ? formatDate(values.startDate)
          : null,
        endDate: values.endDate ? formatDate(values.endDate) : null,
        startDateEpoch: values.startDate
          ? new Date(values.startDate).getTime()
          : null,
        endDateEpoch: values.endDate
          ? new Date(values.endDate).getTime()
          : null,
        templateId: values.channel === "SMS"
          ? (templateId?.id || formValues.templateObj.id)
          : (templateId?.templateId || formValues.templateObj.id),
      }
      const result = await dispatch(CampaignListEditSlice({ data: payload, campaignId: prevCampaignId })).unwrap();


      if (result !== 400) {
        setCampaignId(result.campaignId);


        toast.success('Campaign Details Submitted Successfully.');
        handleNext();
      } else {

        toast.error("You Enter something wrong input");

      }
    } else {
      const payload = {
        templateId: values?.channel === "WHATSAPP" ? templateId?.templateId : templateId?.id,
        channel: values.channel,
        campaignName: values.campaignName,
        startDate: values.startDate
          ? formatDate(values.startDate)
          : null,
        endDate: values.endDate ? formatDate(values.endDate) : null,
        startDateEpoch: values.startDate
          ? new Date(values.startDate).getTime()
          : null,
        endDateEpoch: values.endDate
          ? new Date(values.endDate).getTime()
          : null,
      };

      // setFormValues(values);

      const updatedValues = {
        ...values,
        templateViewData: templateData,
       templateId:values?.channel === "WHATSAPP" ? templateId?.templateId : templateId?.id || templateId,
       startDateEpoch: values.startDate
       ? new Date(values.startDate).getTime()
       : null,
     endDateEpoch: values.endDate
       ? new Date(values.endDate).getTime()
       : null,
      };

      

      setFormValues(updatedValues);

      // const result = await dispatch(fetchCreateCampaignSlice(payload)).unwrap();

      // if (result) {
      //   setCampaignId(result.campaignId);
      //   toast.success(t("campaignDetailsSubmitted"));
      //   handleNext();
      // } else {
      //   console.log("Error");
      // }

      if (!campaignId) {
        try {
          const result = await dispatch(fetchCreateCampaignSlice(payload)).unwrap();
      
          if (result) {
            setCampaignId(result.campaignId);
            setAlreadyCreated(!!result.campaignId); // Converts to boolean
            toast.success(t("campaignDetailsSubmitted"));
            handleNext();
          } else {
            console.log("Error");
          }
        } catch (error) {
          console.error("Error while fetching campaign:", error);
        }
      }else{
        toast.success(t("campaignDetailsSubmitted"));

        handleNext();
      }
    }
  };


  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleDraft = async () => {
    const values = formik.values;
    if (isEdit) {
      var payload = {
        ...values,
        startDate: values.startDate
          ? formatDate(values.startDate)
          : null,
        endDate: values.endDate ? formatDate(values.endDate) : null,
        startDateEpoch: values.startDate
          ? new Date(values.startDate).getTime()
          : null,
        endDateEpoch: values.endDate
          ? new Date(values.endDate).getTime()
          : null,
        templateId: values.channel === "SMS"
          ? (templateId?.id || formValues.templateObj.id)
          : (templateId?.templateId || formValues.templateObj.id),
      }
      const result = await dispatch(CampaignListEditSlice({ data: payload, campaignId: prevCampaignId })).unwrap();


      if (result !== 400) {
        setCampaignId(result.campaignId);
        toast.success('Campaign Details Submitted Successfully.');
        navigate("/campaign-list")
      } else {

        toast.error("You Enter something wrong input");

      }
    } 
    
    else {
      const payload = {
        templateId: values?.channel === "WHATSAPP" ? templateId?.templateId : templateId?.id,
        channel: values.channel,
        campaignName: values.campaignName,
        startDate: values.startDate
          ? formatDate(values.startDate)
          : null,
        endDate: values.endDate ? formatDate(values.endDate) : null,
        startDateEpoch: values.startDate
          ? new Date(values.startDate).getTime()
          : null,
        endDateEpoch: values.endDate
          ? new Date(values.endDate).getTime()
          : null,
      };

      setFormValues(values);

      const updatedValues = {
        ...values,
        templateViewData: templateData,
      };

      setFormValues(updatedValues);

      // const result = await dispatch(fetchCreateCampaignSlice(payload)).unwrap();

      // if (result) {
      //   setCampaignId(result.campaignId);
      //   toast.success(t("campaignDetailsSubmitted"));
      //   navigate("/campaign-list")

      // } else {
      //   console.log("Error");
      // }

      if (!campaignId) {
        try {
          const result = await dispatch(fetchCreateCampaignSlice(payload)).unwrap();
      
          if (result) {
            setCampaignId(result.campaignId);
           
            toast.success(t("campaignDetailsSubmitted"));
            navigate("/campaign-list")
          } else {
            console.log("Error");
          }
        } catch (error) {
          console.error("Error while fetching campaign:", error);
        }
      }else{
        toast.success(t("Save as a draft"));

        navigate("/campaign-list")
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      ...formValues,
    },

    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {

    const fetchTemplateDetails = async () => {
      if (!templateId) {
        return;
      }

      const tempType = formik.values.channel;

      if (tempType === "SMS") {

        const result = await dispatch(fetchSMSTemplateSlice(templateId.id || templateId)).unwrap();
      

        if (result) {
          setTemplateData(result);
        }
        else {
          console.log("error");
        }
      }
      else {
        const result = await dispatch(fetchWhatsAppTemplateSlice(templateId.templateId || templateId)).unwrap();

        if (result) {
          setTemplateData(result);
        }
        else {
          console.log("error");
        }
      }
    }

    fetchTemplateDetails();

  }, [templateId, dispatch])



  useEffect(() => {
    formik.setFieldValue("media", contentIds.join(", "));
    formik.setFieldValue("mediaImage", medidaURL);
    formik.setFieldValue("mediaType", medidaType);

    setFormValues(formik.values);
  }, [contentIds, formik.values, setFormValues]);


  // templateId:values?.channel === "WHATSAPP" ? templateId?.templateId : templateId?.id

  return (
    <form onSubmit={formik.handleSubmit} style={{ marginTop: "20px" }}>
      <Accordion
        sx={{
          border: "1px solid #ccc",
          borderRadius: "10px !important",
          boxShadow: "none",
          overflow: "hidden",
          boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
          marginBottom: "25px !important",
        }}
        defaultExpanded
      >
        <AccordionSummary
          sx={{
            minHeight: "65px ",
            color: "#333333",
            fontWeight: "500",
            fontSize: "19px",
            backgroundColor: "#F9FAFB",
            borderTopLeftRadius: "15px !important",
            borderTopRightRadius: "15px !important",
            borderRadius: "10px",
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <RadioButtonUncheckedIcon
              sx={{
                fill: "#13BECF",
                borderRadius: "50%",
                width: "25px",
                height: "25px",
              }}
            />
            {t("stepper.campaignDetails.campaignDetails")}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={5} paddingY={2} paddingX={1} alignItems="flex-start">
            <Grid item container spacing={2} size={7}>
              <Grid size={12}>
                <Typography className={classes.label}>
                  {t("stepper.campaignDetails.campaignTitle")}{" "}
                  <span style={{ color: "#FF0000" }}>*</span>
                </Typography>

                <TextField
                  name="campaignName"
                  value={formik.values.campaignName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={classes.textField}
                  error={formik.touched.campaignName && Boolean(formik.errors.campaignName)}
                  helperText={formik.touched.campaignName && formik.errors.campaignName}
                />
              </Grid>

              

              <Grid size={{ md: 12 }}>
                <Typography className={classes.label}>
                  {t("stepper.campaignDetails.channel")}{" "}
                  <span style={{ color: "#FF0000" }}>*</span>
                </Typography>
                <Autocomplete
                  // disablePortal
                  name="channel"
                  getOptionLabel={(option) =>
                    option?.label ? option.label : ""
                  }
                  onChange={(e, value) => {
                    handleTemplateList(e, value);
                  }}
                  options={channelData}

                  value={channelData.find(option => option.label === formik.values.channel) || null}
                  onBlur={formik.handleBlur}
                  className={classes.selectField}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Template Channel"
                      error={
                        formik.touched.channel && Boolean(formik.errors.channel)
                      }
                      helperText={
                        formik.touched.channel && formik.errors.channel
                      }
                      className={classes.textField}
                    />
                  )}
                />
              </Grid>

              <Grid size={12}>
                <Typography className={classes.label}>
                  {t("stepper.campaignDetails.templateDetails")} {""}
                  <span style={{ color: "#FF0000" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  // disabled
                  name="templateDetails"
                  value={formik.values.templateDetails}
                  variant="outlined"
                  className={classes.textField}
                  error={
                    formik.touched.templateDetails &&
                    Boolean(formik.errors.templateDetails)
                  }
                  helperText={
                    formik.touched.templateDetails &&
                    formik.errors.templateDetails
                  }
                  onClick={handleTemplateOpen}
                  onBlur={formik.handleBlur}
                  InputProps={{
                    endAdornment: (
                      <>
                        <Typography
                          sx={{
                            color: "#445A68",
                            cursor: "pointer",
                            marginRight: "8px",
                          }}
                          onClick={handleTemplateOpen}
                        >
                          {t("stepper.campaignDetails.browse")}
                        </Typography>
                      </>
                    ),
                  }}
                />
              </Grid>
             

              <Grid size={{ xs: 12, md: 6 }}>
                <Typography className={classes.label}>
                  {t("stepper.campaignDetails.startDate")}:{" "}
                  <span style={{ color: "#FF0000" }}>*</span>
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDateTimePicker
                    name="startDate"
                    value={formik.values.startDateEpoch}
                    onChange={(value) => formik.setFieldValue("startDate", value)}
                    onBlur={formik.handleBlur}
                    className={classes.textField}
                    disablePast
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                        helperText={formik.touched.startDate && formik.errors.startDate}
                        className={classes.textField}
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText error>

                  {formik.touched.startDate && formik.errors.startDate}
                </FormHelperText>
              </Grid>


              <Grid size={{ xs: 12, md: 6 }}>
                <Typography className={classes.label}>
                  {t("stepper.campaignDetails.endDate")}:{" "}
                  <span style={{ color: "#FF0000" }}>*</span>
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDateTimePicker
                    name="endDate"
                    disablePast
                    value={formik.values.endDateEpoch}
                    className={classes.textField}
                    onBlur={formik.handleBlur}
                    onChange={(value) => formik.setFieldValue("endDate", value)}
                    minDateTime={formik.values.startDate}
                    maxDateTime={
                      formik.values.startDate
                        ? new Date(new Date(formik.values.startDate).setDate(new Date(formik.values.startDate).getDate() + 37))
                        : undefined
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                        helperText={formik.touched.endDate && formik.errors.endDate}
                        className={classes.textField}
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText error>

                  {formik.touched.endDate && formik.errors.endDate}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid size={5} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', }}>


              {(formik.values.channel === 'SMS' || formik.values.channel === 'WHATSAPP') && (templateId || formik.values.templateId) && (
                <TemplateViewScreen
                  tempType={formik.values.channel}
                  templateData={templateData || formik.values?.templateViewData}
                />
              )}

            </Grid>

          </Grid>


         
        </AccordionDetails>
      </Accordion>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "15px",
          paddingY: "10px",
        }}
      >
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          sx={{
            borderRadius: "40px",
            padding: "16px 24px",
            fontSize: "18px",
            backgroundColor: "#445A68",
            color: "#FFFFFF",
            fontWeight: "600",
            border: "1px solid rgba(68, 90, 104, 1)",
          }}
          onClick={handleDraft}
        >
          {t("stepper.saveForDraft")}
        </Button>
        <Button
          variant="outlined"
          type="submit"
          endIcon={<ArrowForwardIcon />}
          sx={{
            borderRadius: "40px",
            padding: "16px 24px",
            color: "#FFFFFF",
            background: "linear-gradient(0deg, #13BECF 0%, #435D6B 100%)",
            fontSize: "18px",
            fontWeight: "600",
            border: "1px solid #13BECF",
          }}
        >
          {t("stepper.saveAndNext")}
        </Button>
      </Box>

      <ListContentModal
        open={open}
        handleClose={handleClose}
        setContentIds={setContentIds}
        setMedidaURL={setMedidaURL}
        setMediType={setMediType}
        classes={classes}
        setPreviewMedia={setPreviewMedia}
        t={t}
      />
      <TemplateDetailsModal
        open={templateOpen}
        handleClose={handleTemplateClose}
        classes={classes}
        templateId={templateId}
        setTemplateId={setTemplateId}
        templateDetails={templateDetails}
        formik={formik}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        setTemplateDetails={setTemplateDetails}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        t={t}
      />

      <CustomLoader loading={submitBtnLoader} />
    </form>
  );
};

export default CampaignDetails;