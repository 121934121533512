import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  Box,
  Typography,
  CircularProgress,
  Breadcrumbs,
  Avatar,
  TablePagination,
  IconButton,
  Collapse,
  Grid2,
  Button,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchNotificationApproveSlice,
  fetchNotificationPreviewSlice,
} from "../../redux/teamSlice/team.slice";
import { NotificationApproveThunk } from "../../redux/teamSlice/team.thunk";
import { toast } from "react-toastify";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useMediaQuery } from "@mui/material";
import CustomBreadcrumb from "../../components/CustomBreadcrumb/CustomBreadcrumb";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { useTranslation } from "react-i18next";
import CustomPagination from "../../components/CustomPagination/CustomPagination";

function formatDate(dateString) {
  const date = new Date(dateString);

  // Options for formatting
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true, // Use 12-hour format
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "4px",
}));

const TableHeadTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#455967",
  fontWeight: "500",
  lineHeight: "1.5rem",
}));

const TableDataTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#455967",
  fontWeight: "500",
}));

const styles = {
  container: (isSmallScreen) => ({
    padding: 2,
    display: "flex",
    justifyContent: "space-between",
    flexWrap: isSmallScreen ? "wrap" : "nowrap",
    flexDirection: isSmallScreen ? "column" : "row",
  }),
  boxWidth: { width: "50%" },
  labelText: { color: "#455967", fontWeight: "600", fontSize: "16px" },
  valueText: { fontSize: "14px", fontWeight: "400", color: "#455967" },
  chip: {
    borderRadius: "18px",
    background: "#445A68E5",
    color: "#fff",
    "& .MuiChip-deleteIcon": { color: "white", fontSize: "16px" },
    "& .MuiChip-deleteIcon:hover": {
      backgroundColor: "transparent",
      color: "white",
    },
    margin: "2px",
  },
};

const NotificationPreview = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const breadcrumb = [
    {
      title: t('home'),
      href: '/dashboard',
    },
    {
      title: t('notificationPreview'),
      href: '',
    }
  ];


  const { notificationPreview, totalItems, loading } = useSelector(
    (state) => state.teamsDetails
  );
  const [pageNumber] = useState(0);
  const [pageSize] = useState(10);
  const [openRowIndex, setOpenRowIndex] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const [open, setOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const fetchNotificationData = () => {
    dispatch(fetchNotificationPreviewSlice({ page, rowsPerPage }));
  };

  useEffect(() => {
    fetchNotificationData();
  }, [dispatch, page, rowsPerPage]);

  // console.log("noti", notificationPreview?.content);

  // const handleApprove = (item) => {
  //   console.log("handleApproveitem", item);

  //   const data = {
  //     id: item.requestId,
  //   };

  //   const result = dispatch(fetchNotificationApproveSlice(data)).unwrap();
  //   console.log(result, "roshanresult");
  //   fetchNotificationData();

  //   if (result) {
  //   } else {
  //   }
  // };

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpenModal = (item) => {
    setSelectedNotification(item); // Set the selected row for approval
    setOpen(true); // Open the modal
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleApprove = async (item) => {
    if (!selectedNotification) return;

    const data = {
      id: selectedNotification.requestId,
    };


    try {
      // Dispatch the approval action and wait for it to finish
      const result = await dispatch(
        fetchNotificationApproveSlice(data)
      ).unwrap();

      // After the result is resolved, fetch new data
      fetchNotificationData();

      // Log success if needed
      console.log("Approval successful", result);
      // toast.success("Notification approved successfully!");
    } catch (error) {
      // Log the error if something goes wrong
      console.error("Approval failed", error);
      // toast.error("Failed to approve notification!");
    }
  };

  return (
    <Box>
      <CustomBreadcrumb breadcrumb={breadcrumb} />

      <Box sx={{ display: "flex", marginTop: "16px" }}>
        <Typography
          sx={{ color: "#445A68", fontSize: "29px", fontWeight: "500" }}
        >
          {t('notificationPreview')}
        </Typography>
      </Box>

      <TableContainer
        component={Paper}
        sx={{ mt: 3, border: "1px solid #D4D4D4", borderRadius: "12px" }}
      >
        {loading ? (
          <Box display="flex" justifyContent="center" my={3}>
            <CircularProgress />
          </Box>
        ) : (
          <Table aria-label="campaign table">
            <TableHead sx={{ background: "#F1F1F1" }}>
              <TableRow>
                <TableCell>
                  <TableHeadTitle>{t('editConsumer.description')}</TableHeadTitle>
                </TableCell>
                <TableCell>
                  <TableHeadTitle>{t('date')}</TableHeadTitle>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {notificationPreview?.content?.length > 0 ? (
                notificationPreview.content.map((row, index) => (
                  <React.Fragment key={row.id}>
                    <StyledTableRow hover onClick={() => setOpenRowIndex(openRowIndex === index ? null : index)}>
                      <TableCell>{row.message}</TableCell>
                      <TableCell>{row.createdOn ? formatDate(row.createdOn) : "N/A"}</TableCell>
                      <TableCell>
                        <Chip
                          // label={row.status}
                          label={row.status === "APPROVED" ? "Approved" : row.status === "EXECUTED" ? "Executed" : row.status === "REJECTED" ? "Rejected" : row.status === "PARTIALLYAPPROVED" ? "Partially Approved" : row.status}
                          color="primary"
                          sx={{
                            backgroundColor: row.status === "DRAFT" ? "transparent" : "#1976d2",
                            color: row.status === "DRAFT" ? "inherit" : "#fff",
                          }}
                          // onClick={(e) => {
                          //   e.stopPropagation();
                          //   if (row.status === "APPROVED") {
                          //     toast.info("Already Executed");
                          //   } else {
                          //     handleOpenModal(row);
                          //   }
                          // }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (row.status === "DRAFT" || row.status === 'EXECUTED') {
                              toast.info("Already Executed");
                            }
                            else if (row.status === "REJECTED") {
                              toast.info("Campaign Rejected");
                            }
                            else {
                              handleOpenModal(row);
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton size="small">
                          {openRowIndex === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                    </StyledTableRow>
                    {openRowIndex === index && (
                      <TableRow>
                        <TableCell colSpan={4} sx={{ p: 0, backgroundColor: "#D5F3F666" }}>
                          <Collapse in={openRowIndex === index} timeout="auto" unmountOnExit>
                            <Box sx={styles.container(isSmallScreen)}>
                              <Box sx={styles.boxWidth}>
                                <Typography>{t('campaignList.campaignName')}: {row.message}</Typography>
                              </Box>
                              <Box sx={styles.boxWidth}>
                                <Typography>{t('campaignList.startDate')}: {formatDate(row.createdOn)}</Typography>
                              </Box>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <Typography>{t('pricing.noDataAvailable')}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* Center CustomPagination */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <CustomPagination
            rowsPerPageOptions={[5, 10, 25]}
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => handlePageChange(newPage)}
            onRowsPerPageChange={(e) =>
              handleRowsPerPageChange(parseInt(e.target.value, 10))
            }
          />
        </Box>

        {/* Typography aligned to the end */}
        <Box
          sx={{
            flex: "0 0 auto",
          }}
        >
          <Typography sx={{
            fontSize: '19px',
            fontWeight: '600',
            background: 'linear-gradient(180deg, #13BECF 0%, #455967 100%)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
          }}>
            {page * rowsPerPage + 1}-{Math.min((page + 1) * rowsPerPage, totalItems)} <span style={{ color: '#455967' }}>of {totalItems}</span>
          </Typography>
        </Box>
      </Box>

      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        confirmationMessage={t('executeNotification')}
        handleSubmit={handleApprove}
      />
    </Box>
  );
};

export default NotificationPreview;