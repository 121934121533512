import React, { useEffect, useState } from "react";
import { fetchDataRequestDataSlice } from "../../../redux/teamSlice/team.slice";
import { fetchDiscoverIdSlice } from "../../../redux/stepperSlice/stepper.slice";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { clearDataRequestStatus, fetchDataRequestCountSlice } from "../../../redux/dataRequestSlice/dataRequest.slice";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Card,
    Box,
    Typography,
    Breadcrumbs,
    Avatar,
    TablePagination,
    IconButton,
    Collapse,
    Grid2,
    Switch,
    Button,
    Tabs,
    Tab
} from "@mui/material";
import {
    fetchDataRequestStatusSlice,
    fetchDataRequestUpdateSlice,
    fetchDataRequestViewSlice,
} from "../../../redux/dataRequestSlice/dataRequest.slice";
import Chip from "@mui/material/Chip";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";
import PersonIcon from "@mui/icons-material/Person";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import DescriptionIcon from "@mui/icons-material/Description";
import PieChartIcon from "@mui/icons-material/PieChart";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { toast } from "react-toastify";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useMediaQuery } from "@mui/material";
import SortIcon from '@mui/icons-material/Sort';
import ApprovedBtnSelected from '../../../assets/Approved 4.svg'
import ApprovedBtnSelectedIndo from '../../../assets/Approved 6 Indo.svg'
import PendingBtnSelected from '../../../assets/Pending 2.svg'
import PendingBtnSelectedIndo from '../../../assets/Pending 4 Indo.svg'
import RejectedBtnSelected from '../../../assets/Rejected 3.svg'
import RejectedBtnSelectedIndo from '../../../assets/Rejected 5 Indo.svg'
import ApproveBtnUnSelected from '../../../assets/Approved Unselected 1.svg'
import ApproveBtnUnSelectedIndo from '../../../assets/Unselected Approved 1 Indo.svg'
import PendingBtnUnSelected from '../../../assets/Pending Unselected.svg'
import PendingBtnUnSelectedIndo from '../../../assets/Unselected Pending 1 Indo.svg'
import RejectedBtnUnSelected from '../../../assets/Rejected Unselected.svg'
import RejectedBtnUnSelectedIndo from '../../../assets/Unselected Rejected 1 Indo.svg'
import CustomPagination from "../../../components/CustomPagination/CustomPagination";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb/CustomBreadcrumb";
import { useTranslation } from "react-i18next";

function convertToUpperSnakeCase(input) {
    return input.toUpperCase().replace(/ /g, "_");
}

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 49,
    height: 24,
    padding: 0,
    borderRadius: '12px',
    backgroundColor: '#13BECF',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 16,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translate(9px,2px);',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        transform: 'translate(3px,2px);',
        '&.Mui-checked': {
            transform: 'translate(27px,2px);',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#13BECF',
                ...theme.applyStyles('dark', {
                    backgroundColor: '#177ddc',
                }),
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 16,
        height: 16,
        borderRadius: 8,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
        ...theme.applyStyles('dark', {
            backgroundColor: 'rgba(255,255,255,.35)',
        }),
    },
}));


function formatDate(dateString) {
    const date = new Date(dateString);

    // Options for formatting
    const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        // hour: "numeric",
        // minute: "numeric",
        // hour12: true, // Use 12-hour format
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "4px",
}));

const TableHeadTitle = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    color: "#455967",
    fontWeight: "500",
    lineHeight: "1.5rem",
}));

const TableDataTitle = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    color: "#455967",
    fontWeight: "500",
}));

const styles = {
    container: (isSmallScreen) => ({
        padding: 2,
        display: "flex",
        justifyContent: "space-between",
        flexWrap: isSmallScreen ? "wrap" : "nowrap",
        flexDirection: isSmallScreen ? "column" : "row",
    }),
    boxWidth: { width: "25%" },
    labelText: { color: "#455967", fontWeight: "600", fontSize: "16px" },
    labelTextself: { color: "#13becf", fontWeight: "600", fontSize: "16px" },

    valueText: { fontSize: "14px", fontWeight: "400", color: "#455967" },
    chip: {
        borderRadius: "18px",
        background: "#FF8D5D",
        color: "#fff",
        "& .MuiChip-deleteIcon": { color: "white", fontSize: "16px" },
        "& .MuiChip-deleteIcon:hover": {
            backgroundColor: "transparent",
            color: "white",
        },
        margin: "2px",
    },
};

const ResponsiveTableRow = ({
    openRowIndex,
    index,
    viewDataRequestData,
    wsCount,
    showSpinner,
    row,
    HandleStatusButton,
    collapseSpinner,
    t,
    i18n
}) => {
    const dispatch = useDispatch();
    const isSmallScreen = useMediaQuery("(max-width:900px)");

    return (
        openRowIndex === index && (
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                    sx={{ backgroundColor: "#D5F3F666" }}
                >
                    <Collapse in={openRowIndex === index} timeout="auto" unmountOnExit>
                        {
                            collapseSpinner ?
                                <Box display="flex" justifyContent="center" my={3}>
                                    <CircularProgress />
                                </Box>
                                : <>
                                    <Box sx={styles.container(isSmallScreen)}>
                                        {/* Campaign Information */}
                                        <Box sx={styles.boxWidth}>
                                            <Typography sx={styles.labelText}>
                                                {t('campaignList.campaignName')}:{" "}
                                                <span style={styles.valueText}>
                                                    {viewDataRequestData?.campaign?.campaignName}
                                                </span>
                                            </Typography>
                                        </Box>
                                        {/* <Box sx={styles.boxWidth}>
                                            <Typography sx={styles.labelText}>
                                                {t('campaignList.websiteUrl')}:{" "}
                                                <span style={styles.valueText}>
                                                    {viewDataRequestData?.campaign?.description}
                                                </span>
                                            </Typography>
                                        </Box> */}


                                        <Box sx={styles.boxWidth}>
                                            <Typography sx={styles.labelText}>
                                                {t('campaignList.startDate')}:{" "}
                                                <span style={styles.valueText}>
                                                    {viewDataRequestData?.campaign?.startDate
                                                        ? formatDate(viewDataRequestData?.campaign?.startDate)
                                                        : "N/A"}
                                                </span>
                                            </Typography>
                                        </Box>
                                        <Box sx={styles.boxWidth}>
                                            <Typography sx={styles.labelText}>
                                                {t('campaignList.endDate')}:{" "}
                                                <span style={styles.valueText}>
                                                    {viewDataRequestData?.campaign?.endDate
                                                        ? formatDate(viewDataRequestData?.campaign?.endDate)
                                                        : "N/A"}
                                                </span>
                                            </Typography>
                                        </Box>
                                        <Box sx={styles.boxWidth}>
                                            <Typography sx={styles.labelText}>
                                                {t('dataRequest.updatedOn')}:{" "}
                                                <span style={styles.valueText}>
                                                    {viewDataRequestData?.campaign?.updatedOn
                                                        ? formatDate(viewDataRequestData?.campaign?.updatedOn)
                                                        : "N/A"}
                                                </span>
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Grid2 container sx={{ marginTop: "8px", paddingX: 2, paddingBottom: 1 }}>
                                        <Grid2 size={{ md: 3 }}>
                                            {viewDataRequestData?.selfAttributes?.length ? (
                                                viewDataRequestData?.selfAttributes?.map((item, index) => {
                                                    return (
                                                        <Box key={index} style={{ marginBottom: "8px" }}>
                                                            <Typography sx={styles.labelText}>
                                                                {item.name}:
                                                            </Typography>

                                                            {item.value.map((val, idx) => (
                                                                <Chip
                                                                    key={idx}
                                                                    label={val}
                                                                    size="small"
                                                                    style={{ margin: "2px" }}
                                                                    sx={{
                                                                        borderRadius: "18px",
                                                                        marginBottom: "4px",
                                                                        background: "#445A68E5",
                                                                        color: "#fff",
                                                                        "& .MuiChip-deleteIcon": {
                                                                            color: "white",
                                                                            fontSize: "16px",
                                                                        },
                                                                        "& .MuiChip-deleteIcon:hover": {
                                                                            backgroundColor: "transparent",
                                                                            color: "white",
                                                                        },
                                                                    }}
                                                                />
                                                            ))}
                                                        </Box>
                                                    );
                                                })
                                            ) : (
                                                <Typography sx={{ py: 2 }}>
                                                    {t('dataRequest.noSelfAttributeAvailable')}
                                                </Typography>
                                            )}
                                        </Grid2>
                                        <Grid2 size={{ md: 3 }}>
                                            {viewDataRequestData?.restAttributes?.length ? (
                                                viewDataRequestData?.restAttributes?.map((item, index) => {
                                                    return (
                                                        <Box key={index} style={{ marginBottom: "8px" }}>
                                                            <Typography sx={styles.labelText}>
                                                                {item.name}:
                                                            </Typography>

                                                            {item.value.map((val, idx) => (
                                                                <Chip
                                                                    key={idx}
                                                                    label={val}
                                                                    size="small"
                                                                    style={{ margin: "2px" }}
                                                                    sx={{
                                                                        borderRadius: "18px",
                                                                        marginBottom: "4px",
                                                                        background: "#445A68E5",
                                                                        color: "#fff",
                                                                        "& .MuiChip-deleteIcon": {
                                                                            color: "white",
                                                                            fontSize: "16px",
                                                                        },
                                                                        "& .MuiChip-deleteIcon:hover": {
                                                                            backgroundColor: "transparent",
                                                                            color: "white",
                                                                        },
                                                                    }}
                                                                />
                                                            ))}
                                                        </Box>
                                                    );
                                                })
                                            ) : (
                                                // <Typography sx={{ py: 2 }}>
                                                //     {t('dataRequest.noRestAttributeAvailable')}
                                                // </Typography>
                                                ""
                                            )}
                                        </Grid2>

                                        <Grid2 size={{ md: 3 }}>
                                            <Typography sx={{ fontWeight: "bold", color: "#333" }}>
                                                Count:{" "}
                                                {showSpinner ? <CircularProgress size={12} /> : wsCount}
                                            </Typography>
                                        </Grid2>

                                        <Grid2 size={{ md: 3 }}>
                                            {row?.status === "pending" ? (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        // flexDirection: 'column',
                                                        gap: "8px",
                                                        // justifyContent: 'center',
                                                        alignItems: "center",
                                                        minHeight: "56px", // Set minimum height to avoid uneven rows
                                                    }}
                                                >
                                                    <>
                                                        <Chip
                                                            label="Approve"
                                                            color="success"
                                                            variant="outlined"
                                                            onClick={() => HandleStatusButton(row, "approved")}
                                                        />
                                                        <Chip
                                                            label="Reject"
                                                            variant="outlined"
                                                            onClick={() => HandleStatusButton(row, "rejected")}
                                                            sx={{
                                                                borderColor: "red",
                                                                color: "red",
                                                            }}
                                                        />
                                                    </>
                                                </Box>
                                            ) : (
                                                <Chip
                                                    label={
                                                        row?.status?.charAt(0).toUpperCase() +
                                                        row?.status?.slice(1).toLowerCase()
                                                    }
                                                    color={row?.status === "approved" ? "success" : "error"}
                                                    variant="outlined"
                                                    deleteIcon={<DoneIcon />}
                                                />
                                            )}
                                        </Grid2>

                                        {/* <Grid2 size={{ md: 3 }} sx={{ margin: '20px 0px' }}>

                                            <Typography sx={styles.labelText}>
                                                {t('dataRequest.updatedOn')}:{" "}
                                                <span style={styles.valueText}>
                                                    {viewDataRequestData?.campaign?.updatedOn
                                                        ? formatDate(viewDataRequestData?.campaign?.updatedOn)
                                                        : "N/A"}
                                                </span>
                                            </Typography>

                                        </Grid2> */}
                                    </Grid2>


                                </>
                        }
                    </Collapse>
                </TableCell>
            </TableRow>
        )
    );
};

const DataRequest = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const breadcrumb = [

        {
            title: t('dataRequest.dataRequest'),
            href: '',
        }
    ];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { viewDataRequestData } = useSelector((state) => state.dataRequest);
    const [openRowIndex, setOpenRowIndex] = useState(null);
    const [selected, setSelected] = useState([]);
    const [wsCount, setWsCount] = useState(null);
    const isSelected = (id) => selected.indexOf(id) !== -1;
    const [showSpinner, setShowSpinner] = useState(true);
    const [collapseSpinner, setCollapseSpinner] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [dataCount, setDataCount] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue)
        setOpenRowIndex(null);
    };
    const { dataRequestStatusData, totalItems, loading } = useSelector((state) => state.dataRequest)
    const ws_url = process.env.REACT_APP_WS_QA_IP


    const handlePageChange = (newPage) => {
        setPage(newPage);
        setOpenRowIndex(null);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const HandleStatusButton = async (row, actionStatus) => {
        var data = {
            requestId: row?.dataRequest?.request_id,
            status: actionStatus,
        };

        const result = await dispatch(fetchDataRequestUpdateSlice(data)).unwrap();

        if (result) {
            const status = activeTab === 0 ? 'approved' : activeTab === 1 ? 'rejected' : activeTab === 2 ? 'pending' : null;

            const successResult = await dispatch(fetchDataRequestStatusSlice({ page, rowsPerPage, status })).unwrap();

            if (successResult) {
                setOpenRowIndex(null);
            }
            else {
                console.log("Error");
            }

            if (actionStatus === "approved") {
                toast.success("Successfully Approved.");
            } else {
                toast.error("Request Rejected.");
            }
        } else {
            console.log("Error");
        }
    };

    const wsRef = useRef(new Map());

    // This function handles WebSocket setup and clean-up
    const setupWebSocket = useCallback((socketId) => {
        if (wsRef.current.has(socketId)) {
            console.warn(`WebSocket for ${socketId} already exists.`);
            return;
        }

        const ws = new WebSocket(
            `${ws_url}${socketId}`
        );

        wsRef.current.set(socketId, ws);

        ws.onmessage = (event) => {
            //   const newValue = event.data;
            setWsCount(event.data);
            setShowSpinner(false);
            //   console.log('newValue count', newValue);
        };

        ws.onopen = () => {
            console.log(`WebSocket connection opened for ${socketId}.`);
        };

        ws.onclose = () => {
            console.log(`WebSocket connection closed for ${socketId}.`);
            wsRef.current.delete(socketId); // Remove closed WebSocket from Map
        };

        return ws;
    }, []);

    const handleViewDetails = async (row, index) => {
        if (openRowIndex === index) {
            setCollapseSpinner(false);
            setOpenRowIndex(null);
            return;
        }

        setOpenRowIndex(openRowIndex === index ? null : index);
        setCollapseSpinner(true);

        const payload = {
            requestId: row?.dataRequest?.request_id,
        };

        const result = await dispatch(fetchDataRequestViewSlice(payload)).unwrap();

        const newVal = { attributes: result?.selfAttributes };
        if (result) {
            setCollapseSpinner(false);

            // Only set up the WebSocket if it has not been set up already for the current row
            if (openRowIndex !== index) {
                const response = await dispatch(fetchDiscoverIdSlice(newVal)).unwrap();
                const newSocketId = response?.requestId;

                if (newSocketId) {
                    setupWebSocket(newSocketId); // Set up the WebSocket for the current row
                } else {
                    console.error("No socketId found in the response.");
                }
            }
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            setShowSpinner(true);
            try {
                const count = await dispatch(fetchDataRequestCountSlice()).unwrap();
                setDataCount(count)
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setShowSpinner(false);
            }
        };

        fetchData();
    }, [dataRequestStatusData]);


    useEffect(() => {

        const status = activeTab === 0 ? 'approved' : activeTab === 1 ? 'rejected' : activeTab === 2 ? 'pending' : null;
        dispatch(clearDataRequestStatus());

        const fetchDataRequest = async () => {
            setIsLoading(true);
            try {
                const result = await dispatch(fetchDataRequestStatusSlice({ page, rowsPerPage, status })).unwrap();
                console.log('Data fetched:', result);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        }

        fetchDataRequest();

    }, [dispatch, page, rowsPerPage, activeTab]);

    useEffect(() => {
        console.log("WebSocket count updated:", wsCount);
    }, [wsCount]);

    console.log('Data fetched: Loading', isLoading);

    return (
        <Box>
            <CustomBreadcrumb breadcrumb={breadcrumb} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '16px' }}>
                <Typography sx={{ color: '#445A68', fontSize: '29px', fontWeight: '600' }}>{t('dataRequest.dataRequest')}</Typography>
            </Box>

            <Box sx={{ paddingTop: '8px', width: '100%' }}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    TabIndicatorProps={{ style: { display: 'none' } }}
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            borderRadius: '8px',
                            padding: '4px',
                            gap: '8px',
                        },
                    }}
                >
                    <Tab
                        disabled={isLoading}
                        sx={{
                            padding: '0px',
                            borderRadius: '10px',
                            cursor: isLoading ? 'not-allowed !important' : 'pointer',
                            pointerEvents: isLoading ? 'none' : 'auto',
                        }}
                        label={
                            <Button disabled={isLoading}
                                sx={{

                                    padding: '0px !important',
                                    width: '100%',
                                    minHeight: '48px',
                                    borderRadius: '10px',
                                    cursor: isLoading ? 'not-allowed !important' : 'pointer',
                                    pointerEvents: isLoading ? 'none' : 'auto'
                                }}>
                                <img
                                    src={
                                        activeTab === 0
                                            ? i18n.language === 'id'
                                                ? ApprovedBtnSelectedIndo
                                                : ApprovedBtnSelected
                                            : i18n.language === 'id'
                                                ? ApproveBtnUnSelectedIndo
                                                : ApproveBtnUnSelected
                                    }
                                    alt="Approved"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '10px',
                                        boxShadow: activeTab === 0 ? '0px 4px 7px 2px rgba(0, 0, 0, 0.17)' : '',
                                        cursor: isLoading ? 'not-allowed !important' : 'pointer',
                                        pointerEvents: isLoading ? 'none' : 'auto'
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        zIndex: '999',
                                        fontSize: { xs: "30px", md: "20px" },
                                        right: '30px'
                                    }}
                                >
                                    {`(${dataCount?.approved ? dataCount?.approved : '0'})`}
                                </Box>
                            </Button>
                        }
                    />
                    <Tab
                        disabled={isLoading}

                        sx={{
                            padding: '0px',
                            borderRadius: '10px',
                            cursor: isLoading ? 'not-allowed !important' : 'pointer',
                            pointerEvents: isLoading ? 'none' : 'auto',
                        }}
                        label={
                            <Button disabled={isLoading}
                                sx={{
                                    padding: '0px !important',
                                    width: '100%',
                                    minHeight: '48px',
                                    borderRadius: '10px',
                                    cursor: isLoading ? 'not-allowed !important' : 'pointer',
                                    pointerEvents: isLoading ? 'none' : 'auto'
                                }}>
                                <img
                                    src={
                                        activeTab === 1
                                            ? i18n.language === 'id'
                                                ? RejectedBtnSelectedIndo
                                                : RejectedBtnSelected
                                            : i18n.language === 'id'
                                                ? RejectedBtnUnSelectedIndo
                                                : RejectedBtnUnSelected
                                    }
                                    alt="Rejected"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '10px',
                                        boxShadow: activeTab === 1 ? '0px 4px 7px 2px rgba(0, 0, 0, 0.17)' : '',
                                        cursor: isLoading ? 'not-allowed !important' : 'pointer',
                                        pointerEvents: isLoading ? 'none' : 'auto',
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        zIndex: '999',
                                        fontSize: { xs: "30px", md: "20px" },
                                        right: '30px'
                                    }}
                                >
                                    {`(${dataCount?.rejected ? dataCount?.rejected : '0'})`}
                                </Box>

                            </Button>
                        }
                    />
                    <Tab
                        disabled={isLoading}
                        sx={{
                            padding: '0px',
                            borderRadius: '10px',
                            cursor: isLoading ? 'not-allowed !important' : 'pointer',
                            pointerEvents: isLoading ? 'none' : 'auto',
                        }}
                        label={
                            <Button disabled={isLoading}
                                sx={{
                                    padding: '0px !important',
                                    width: '100%',
                                    minHeight: '48px',
                                    borderRadius: '10px',
                                    cursor: isLoading ? 'not-allowed !important' : 'pointer',
                                    pointerEvents: isLoading ? 'none' : 'auto',
                                }}>
                                <img
                                    src={
                                        activeTab === 2
                                            ? i18n.language === 'id'
                                                ? PendingBtnSelectedIndo
                                                : PendingBtnSelected
                                            : i18n.language === 'id'
                                                ? PendingBtnUnSelectedIndo
                                                : PendingBtnUnSelected
                                    }
                                    alt="Pending"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '10px',
                                        boxShadow: activeTab === 2 ? '0px 4px 7px 2px rgba(0, 0, 0, 0.17)' : '',
                                        cursor: isLoading ? 'not-allowed !important' : 'pointer',
                                        pointerEvents: isLoading ? 'none' : 'auto',
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        zIndex: '999',
                                        fontSize: { xs: "30px", md: "20px" },
                                        right: '30px'
                                    }}
                                >
                                    {`(${dataCount?.pending ? dataCount?.pending : '0'})`}
                                </Box>

                            </Button>
                        }
                    />
                </Tabs>

            </Box>

            <TableContainer
                component={Paper}
                sx={{ mt: 3, border: "1px solid #D4D4D4", borderRadius: "12px" }}
            >
                <Table aria-label="campaign table">
                    <TableHead sx={{ background: '#F1F1F1' }}>
                        <TableRow>
                            <TableCell sx={{ minWidth: '170px' }}>
                                <BoxContainer>
                                    <DescriptionIcon
                                        fontSize={"14px"}
                                        sx={{ color: "#455967" }}
                                    />
                                    <TableHeadTitle>{t('campaignList.campaignName')}</TableHeadTitle>
                                </BoxContainer>
                            </TableCell>
                            <TableCell sx={{ minWidth: '170px' }}>
                                <TableHeadTitle>
                                    <BoxContainer>
                                        <PieChartIcon
                                            fontSize={"14px"}
                                            sx={{ color: "#455967" }}
                                        />
                                        <TableHeadTitle>{t('dataRequest.discoverAttributes')}</TableHeadTitle>
                                    </BoxContainer>
                                </TableHeadTitle>
                            </TableCell>

                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>


                    <TableBody>
                        {(loading || isLoading) ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            dataRequestStatusData?.content?.length > 0 ? (
                                <>
                                    {dataRequestStatusData?.content?.map((row, index) => {
                                        return (
                                            <>
                                                <StyledTableRow
                                                    key={row.id}
                                                    onClick={() => {
                                                        setShowSpinner(true);
                                                        handleViewDetails(row, index);
                                                    }}
                                                    style={{
                                                        cursor: "pointer",
                                                        backgroundColor: openRowIndex === index ? "#D5F3F6" : "inherit", // Highlight selected row
                                                    }}
                                                >
                                                    <TableCell>
                                                        <BoxContainer
                                                            sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                gap: "8px",
                                                            }}
                                                        >
                                                            <TableDataTitle>
                                                                {row.dataRequest
                                                                    ? row.dataRequest?.campaignName
                                                                    : 'name not persent'}
                                                            </TableDataTitle>
                                                        </BoxContainer>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TableDataTitle
                                                        >
                                                            {row?.dataRequest
                                                                ? row?.dataRequest?.attributes
                                                                    .map(
                                                                        (attr) =>
                                                                            `${attr?.name}: ${attr?.possibleValues?.join(
                                                                                ", "
                                                                            )}`
                                                                    )
                                                                    .join("; ")
                                                                : row?.attributes
                                                                    .map(
                                                                        (attr) =>
                                                                            `${attr?.name}: ${attr?.possibleValues?.join(
                                                                                ", "
                                                                            )}`
                                                                    )
                                                                    .join("; ")}
                                                        </TableDataTitle>
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => {
                                                                handleViewDetails(row, index);
                                                            }}
                                                        >
                                                            {openRowIndex === index ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                        </IconButton>
                                                    </TableCell>
                                                </StyledTableRow>

                                                <ResponsiveTableRow
                                                    openRowIndex={openRowIndex}
                                                    index={index}
                                                    viewDataRequestData={viewDataRequestData}
                                                    row={row}
                                                    HandleStatusButton={HandleStatusButton}
                                                    wsCount={wsCount}
                                                    showSpinner={showSpinner}
                                                    collapseSpinner={collapseSpinner}
                                                    t={t}
                                                    i18n={i18n}
                                                />
                                            </>
                                        );
                                    })}
                                </>
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        <Typography variant="subtitle1" color="textSecondary">
                                            {t('attribute.noData')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                    <CustomPagination
                        rowsPerPageOptions={[5, 10, 25]}
                        count={totalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, newPage) => handlePageChange(newPage)}
                        onRowsPerPageChange={(e) =>
                            handleRowsPerPageChange(parseInt(e.target.value, 10))
                        }
                    />
                </Box>
                <Box sx={{ flex: '0 0 auto' }}>
                    <Typography sx={{
                        fontSize: '19px',
                        fontWeight: '600',
                        background: 'linear-gradient(180deg, #13BECF 0%, #455967 100%)',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                    }}>
                        {page * rowsPerPage + 1}-{Math.min((page + 1) * rowsPerPage, totalItems)} <span style={{ color: '#455967' }}>of {totalItems}</span>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default DataRequest;
